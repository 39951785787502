import propTypes from 'prop-types';
import moment from 'moment';

export const displayType = propTypes.oneOf([
    'block',
    'inline',
    'inline-block',
    'flex',
    'inline-flex',
    'grid',
]);

export const textInputType = propTypes.oneOf(['text', 'number', 'tel', 'email']);

export const buttonType = propTypes.oneOf(['submit', 'reset', 'button']);

export const colorType = propTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'black',
    'white',
    'grey',
    'success',
    'warning',
    'error',
    'backdrop',
    ...numberedColors('primary', 10),
    ...numberedColors('secondary', 10),
    ...numberedColors('tertiary', 10),
    ...numberedColors('grey', 10),
    ...numberedColors('accent', 10),
]);

function numberedColors(prefix, count) {
    return Array.from({length: count}, (_, i) => `${prefix}${10 * (i + 1)}`);
}

export const optionsType = propTypes.arrayOf(propTypes.shape({
    value: propTypes.string,
    text: propTypes.node,
}));

const commonFields = {
    ref: propTypes.shape({current: propTypes.any}),
    label: propTypes.string,
    helperText: propTypes.string,
    value: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
    disabled: propTypes.bool,
};

export function formFieldTypeWith(additionalPropTypes) {
    return propTypes.shape({
        ...commonFields,
        ...additionalPropTypes,
    });
}

export const textFieldType = formFieldTypeWith({
    maxLength: propTypes.number,
});

export const textAreaType = formFieldTypeWith({
    name: propTypes.string,
    rows: propTypes.number,
    maxLength: propTypes.number,
});

export const dateFieldType = formFieldTypeWith({
    value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(moment)]),
    minDate: propTypes.object,
    maxDate: propTypes.object,
});

export const selectFieldType = formFieldTypeWith({
    name: propTypes.string, // used by radio groups
    options: optionsType,
});
