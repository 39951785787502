import property from 'lodash/property';
import * as type from "./types";
import axios from "axios";
import {_sendLog} from "../../utils/utility";
import {_fetchAddressesFailed} from "../addresses/service";

export const _storeAgent = (agent) => {
    _sendLog(['HPK-React - Agent Service - _storeAgent: ', JSON.stringify(agent, null, 3)], 'info');
    return (dispatch, getState) => {
        dispatch({
            type: type.AGENT_STORE,
            data: agent
        })
    };
}

export const _fetchAgentFailed = () => {
    return {
        type: type.FETCH_FAILED
    };
}
export function _findStaffCode(agentNo,country,productCode){
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let path = process.env.REACT_APP_FIND_STAFF_CODE;
            let sessionId = _getSystem(getState()).sessionNameId;

            if (agentNo) {
                path = path + agentNo;
                if (country) {
                    path = path + "/country/" + country;
                }
                if (productCode) {
                    path = path + "/productCode/" + productCode;
                }
            }
            // @ts-ignore
            axios.get(server + path, {headers: {'Content-Type': 'application/json', 'Session-Name': sessionId}})
                .then(resp => {
                    const staffCode = resp.data;
                    resolve(staffCode);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['HPK-React - Agent Service - _findStaffCode: ', error]);
                    reject(error);
                });
        });
    };
}

export function _findAgentDetails(agentNo,campaign,country,campaignId,subAgentId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let path = process.env.REACT_APP_FIND_AGENT_DETAILS;
            let sessionId = _getSystem(getState()).sessionNameId;
            if(agentNo){
                path = path + agentNo;
                if(subAgentId){
                    path = path + "/sub/" + subAgentId;
                }
                if(campaign){
                    path = path + "/" + campaign;
                }
                if(country){
                    path = path + "/country/" + country;
                }
                if(campaignId){
                    path = path + "/campaignId/" + campaignId;
                }
            } else {
                path = path + " ";
                if(country){
                    path = path + "/country/" + country;
                }
            }

            // @ts-ignore
            axios.get(server + path, { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId} })
                .then(resp => {
                    let agent = resp.data;
                    console.log("agent------"+agent);
                    dispatch({
                        type: type.AGENT_STORE,
                        data: agent
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.error(error);
                    console.log("agent Error---------");
                    _sendLog(['HPK-React - Agent Service - _findAgentDetails: ', error]);
                    dispatch(_fetchAddressesFailed())
                    reject(error);
                });
        });
    };
}
export function _findCampaignImage(campaignId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let path = process.env.REACT_APP_FIND_CAMPAIGN_IMAGE + campaignId;
            let sessionId = _getSystem(getState()).sessionNameId;
            console.log("_findCampaignImage---------campaignId: " +campaignId);

            // @ts-ignore
            axios.get(server + path, { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId} })
                .then(resp => {
                    const imageUrl = resp.data;
                    resolve(imageUrl);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['HPK-React - Agent Service - _findCampaignImage: ', error]);
                    reject(error);
                });
        });
    };
}

export function _resetAgent() {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_AGENT,
        })
    };
}

export const _getAgent = property ( 'agnt');
export const _getSystem = property ( 'sys' );
