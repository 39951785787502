import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import GreenDone from "../../assets/GreenDone.svg";

const useStyles = makeStyles(theme => ({
    root: {
        width: '80%',
        // height: 300 + theme.spacing(3) * 2,
        // paddingLeft: ''
    },
    margin: {
        width: '100%'
        // height: theme.spacing(3)
    }
}));

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

const iOSBoxShadow =
    "4px 4px 12px 0 rgba(20,40,75,0.2)";

const marks = [
    {
        value: 0,
        label: "$5,000,000"
    },
    {
        value: 33,
        label: "$10,000,000"
    },
    {
        value: 66,
        label: "$20,000,000"
    },
    {
        value: 100,
        label: "$30,000,000"
    }
];

const IOSSlider = withStyles({
    root: {
        color: "#003DA5",
        height: 2,
        "&.Mui-disabled": {
            opacity: 0.6
        }
    },
    thumb: {
        height: 33,
        width: 33,
        backgroundColor: "#ffffff",
        boxShadow: iOSBoxShadow,
        marginTop: -14.5,
        background: `url(${GreenDone})  no-repeat center center`,
        marginLeft: -9.5,
        "&:focus, &:hover, &$active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow
            }
        },
        "&.Mui-disabled": {
            height: 33,
            width: 33,
            marginLeft: -9.5,
            marginTop: -14.5
        }
    },
    active: {},
    valueLabel: {
        display: 'none'
    },
    track: {
        height: 5
    },
    rail: {
        height: 5,
        opacity: 1,
        backgroundColor: "#003DA5"
    },
    mark: {
        backgroundColor: "#003DA5",
        height: 13,
        width: 13,
        borderRadius: "50%",
        marginTop: -3.5,
    },
    markActive: {
        opacity: 1,
        backgroundColor: "#003DA5"
    },
    markLabel:{
        marginTop: 10,
        color: "#0A1F44",
        fontFamily: "Inter-Regular",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight:'24px',
    },
    markLabelActive:{
        marginTop: 10,
        color: "#0A1F44",
        fontFamily: "Inter-Regular",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
        lineHeight:'24px',


    }
})(Slider);

function valuetext(value) {
    return `${value}`;
}

function valueLabelFormat(value) {
    return marks.findIndex(mark => mark.value === value) + 1;
}

const QBESlider = ({className,...props})=>{
    const classes = useStyles();
    return <div  className={classes.root + " "+className}>
        <IOSSlider
            aria-label="ios slider"
            defaultValue={props.defaultValue}
            ValueLabelComponent={props.ValueLabelComponent}
            step={props.step}
            valueLabelDisplay="on"
            onChangeCommitted={props.onChange}
            onChange={props.onChangeE}
            min={props.min}
            max={props.max}
            marks={props.marks}
        />
        {/*            orientation="vertical"*/}
    </div>

}
export default QBESlider

