import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import "./payment.css";
import {withRouter} from "react-router";
import Template from "../template";
import Toggle from "../ui/toggle";
import {_isNotDecimalChar, _sendLog, _validateForm, getPathNameFromUrl, getPaymentValueFromUrl} from "../../utils/utility";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {
    _getPolicy,
    _issueAndReceiptPolicy,
    _logPaymentLifecycle,
    _savePendingTransaction,
    _requestPaymentRedirect,
    _requestPaymentStatus,
    _storePolicy
} from "../../store/policy/service";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _clearError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import {_getCampaign} from "../../store/campaign/service";
import TagManager from "react-gtm-module";
import {ReactComponent as HeadingIcon1} from "../../assets/step3icon2.svg";
import QnectButton from "../qnect-ui/qnect-button";
import creditcard_2x from "../../assets/creaditcard_2x.png";
import {ReactComponent as Landlord} from '../../assets/tenant_icon.svg'


function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}


const Payment = (props) => {
    const cfg = useConfig(props.lang, props.country);

    const formId = 'payment';
    const formRef = useRef(null);
    const [envUse, setEnvUse] = useState('');
    const [paymentOrderNo, setPaymentOrderNo] = useState('');
    const [dispImportantNotes, setDispImportantNotes] = useState(true);
    const [checkStatusParam, setCheckStatusParam] = useState('');

    useEffect(() => {
        if(window.location.href){
            console.log("Payment locaTION---------------"+window.location.href);
            let initialURL = window.location.href;
            if (initialURL.indexOf('local') > -1) {
                setEnvUse('local');
                console.log('has LOcal-------');
            }else{
                console.log('not LOcal-------');
            }
        }
        let orderNo = '';
        let errorCode = '';
        const pathName = getPathNameFromUrl(window.location.href,props.location.search);
        if (pathName) {
            console.log("pathName---------------" + pathName);
            orderNo = getPaymentValueFromUrl(pathName, "order_no");
            errorCode = getPaymentValueFromUrl(pathName, "errorcode");
            console.log("orderNo---------------" + orderNo);
            console.log("errorcode---------------" + errorCode);
            if(orderNo && orderNo !== '' && errorCode && errorCode !== '') {
                console.log("orderCode and errorCode are not empty---------------");
                /*if orderNo is present (after payment) but errorCode is present as well,
                  need to check still for paymentCheckStatus for validating if there is PAYMENT_RECEIVED
                  in order to do Receipt for issuance to IF successfully, reason we will clear the errorCode.
                */
                errorCode = '';
                props.clearError();
                setPaymentOrderNo(orderNo);

                console.log('Payment Error ' + errorCode);
                _sendLog('Home-VNM React - OrderNo returned with Payment Error ' + errorCode, 'info');
            }else if (errorCode && errorCode !== '') {
                console.log("errorCode is not empty---------------");
                setPaymentOrderNo("");
                props.showError(['Payment Error '+errorCode]);
                console.log('Payment Error '+errorCode);
                _sendLog('Home-VNM React - Payment Error '+errorCode);
            }else{
                props.clearError();
                setPaymentOrderNo(orderNo);
            }
            if (orderNo && orderNo !== '' && errorCode === '') {
                console.log("PROCESS Check PAYMENT VNM--------");
                let paymentPolicyNumber = orderNo.substring(0, orderNo.lastIndexOf("-"));
                console.log("paymentPolicyNumber=" + paymentPolicyNumber);
                if (!isPolicyInLocalStorage(paymentPolicyNumber) && props.policy.policyNumber === paymentPolicyNumber) {
                    console.log("is Equal");
                    addToPolicyInLocalStorage(paymentPolicyNumber);
                    props.storePolicy(props.policy);
                    setCheckStatusParam("true");
                    paymentCheckStatus();
                }
            }
        }
        }, [props.policy]);

    const occupancyType = props.policy?.policyHome?.occupancyType;
    const totalPremium = numberWithCommas(parseFloat(props.policy?.policyHome?.totalDueAmount).toFixed(2));
    const policyPaymentSuccess= props.policy?.paymentSuccess;
    const inceptionDate = props.policy?.policyHome?.inceptionDate;
    const policyExpiryDate = props.policy?.policyHome?.expiryDate;
    const housingType = props.policy?.policyHome?.housingCode;
    const [payment, setPayment] = useState({
        cardHolderName: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        email: '',
        paymentSignature: {}
    });
    const [redirectLink, setRedirectLink] = useState('');
    const changeInputHandler = (changesObj) => {
        let newObj = {...payment};
        for (let key in changesObj) {
            newObj[key] = changesObj[key];
        }

        setPayment(newObj);
    };

    const onChangeCardNumberFormatHandler = (event) => {
        let cardNoRegex = new RegExp("^\\d{4}-\\d{4}-\\d{4}-\\d{4}$", "g");
        let newCardNo = '';
        let preventDefault = false;
        if (!cardNoRegex.test(event.target.value)) {
            let cardNo = event.target.value.replace(/-/g, '');
            if (cardNo.length > 16) {
                cardNo = cardNo.substring(0, 16);
            }

            if (!(/\D/g.test(cardNo))) {
                do {
                    if (cardNo.length > 4) {
                        newCardNo = newCardNo + cardNo.substring(0, 4) + '-';
                        cardNo = cardNo.substring(4, cardNo.length);
                    } else {
                        newCardNo = newCardNo + cardNo;
                        cardNo = '';
                    }
                } while (cardNo.length > 0);
            } else {
                preventDefault = true;
            }
        } else {
            newCardNo = event.target.value;
        }

    };


    const onChangeExpiryDate = (event) => {
        let newExpiryDate = event.target.value.trim();
        if (newExpiryDate) {
            if (newExpiryDate.length === 4 && newExpiryDate.indexOf("/") === -1) {
                newExpiryDate = newExpiryDate.substring(0, 2) + '/' + newExpiryDate.substring(2);
            }
        }

    };

    function addToPolicyInLocalStorage(policyNumber){
        let policies = window.localStorage.getItem('policyIssued');
        if(policies){
            policies = policies + ";" + policyNumber;
        } else {
            policies = policyNumber;
        }
        window.localStorage.setItem('policyIssued', policies);
        console.log("addToPolicyInLocalStorage----"+window.localStorage.getItem('policyIssued'));
    }

    function isPolicyInLocalStorage(policyNumber){
        let policies = window.localStorage.getItem('policyIssued') ? window.localStorage.getItem('policyIssued').split(";") : "";
        console.log("isPolicyInLocalStorage"+policies);
        if(policies){
            for (let policy of policies) {
                if(policy === policyNumber){
                    console.log("isPolicyInLocalStorage TRUE");
                    return true;
                }
            }
        }
        console.log("isPolicyInLocalStorage False");
        return false;
    }

    const continueToBackPage = () => {
        props.history.push('/confirm');
    }

    const continueToNextPage = () => {
        console.log("continueToNextPage---------");

        if (paymentOrderNo && paymentOrderNo !== '') {
            console.log("PROCESS Check PAYMENT VNM--------");
            let paymentPolicyNumber = paymentOrderNo.substring(0,paymentOrderNo.lastIndexOf("-"));
            console.log("paymentPolicyNumber="+paymentPolicyNumber);
            if(props.policy.policyNumber === paymentPolicyNumber){
                console.log("is Equal");
                props.storePolicy(props.policy);
                paymentCheckStatus();
            }
        } else {
            console.log("PROCESS PAYMENT VNM--------");
            props.storePolicy(props.policy);
            props.showSpinner();

            if(props.policy.policyNumber){
                console.log("Will not callSavePendingTransaction if exisitng policyNumer"+props.policy.policyNumber);
                props.requestPaymentRedirect(props.policy).then((result) => {
                    console.log("requestPaymentRedirect --------");
                    if (result?.policy?.errorMessage != null){
                        console.log("requestPaymentRedirect ERROR--------");
                        console.error(result?.policy?.errorMessage);
                        props.hideSpinner();
                        props.showError([result?.policy?.errorMessage]);
                        return;
                    }
                    console.log("REdirectLinkREturn----"+result);
                    props.hideSpinner();
                    let URL=result;
                    console.log("URL----"+URL);
                    if(URL){
                        window.open(URL, "_self");
                    }
                    else{
                        props.showError(['Request Payment URL Failed']);
                        console.log('Request Payment URL Failed');
                        _sendLog('Home-VNM React - Request Payment URL Failed '+ props.policy.policyNumber);
                    }

                }, () => {
                    props.hideSpinner();
                    props.showError(['Request Payment URL Failed']);
                    console.log('Request Payment URL Failed');
                    console.error('Request Payment URL Failed');
                    _sendLog('Home-React -VNM Request Payment URL Failed '+ props.policy.policyNumber);
                });
            } else {
                console.log("callSavePendingTransaction --------");
                props.callSavePendingTransaction(props.policy).then((result) => {
                    if (result?.policy?.errorMessage != null) {
                        console.log("callSavePendingTransaction ERROR--------");
                        console.error(result?.policy?.errorMessage);
                        props.hideSpinner();
                        props.showError([result?.policy?.errorMessage]);
                        return;
                    }
                    console.log("RESULT POLICY 1111" + result?.policy?.policyNumber);
                    if (result?.policy?.policyNumber != null) {
                        console.log("RESULT POLICY 222------------------" + result?.policy?.policyNumber);
                        props.policy.policyNumber = result?.policy?.policyNumber;
                        console.log("AFTER StorePOlicy --------");
                        props.requestPaymentRedirect(props.policy).then((result) => {
                            console.log("requestPaymentRedirect --------");
                            if (result?.policy?.errorMessage != null) {
                                console.log("requestPaymentRedirect ERROR--------");
                                console.error(result?.policy?.errorMessage);
                                props.hideSpinner();
                                props.showError([result?.policy?.errorMessage]);
                                return;
                            }
                            console.log("REdirectLinkREturn----" + result);
                            props.hideSpinner();
                            let URL = result;
                            console.log("URL----" + URL);
                            if (URL) {
                                window.open(URL, "_self");
                            } else {
                                props.showError(['Request Payment URL Failed']);
                                console.log('Request Payment URL Failed');
                                _sendLog('Home-VNM React - Request Payment URL Failed '+ props.policy.policyNumber);
                            }
                        }, () => {
                            props.hideSpinner();
                            props.showError(['Request Payment Redirect Failed']);
                            console.log('Request Payment Redirect Failed');
                            console.error('Request Payment Redirect Failed');
                            _sendLog('Home-VNM React - Request Payment Redirect Failed '+ props.policy.policyNumber);
                        });

                    }
                }, err => {
                    props.hideSpinner();
                    props.showError(['Error in SavePending Transaction policy']);
                    console.error('Policy issue error' + err);
                    _sendLog('Home-VNM React - Error in SavePending Transaction policy'+ props.policy.policyNumber);
                });
            }
        }
    };

    const paymentCheckStatus = () => {
        props.showSpinner();
        props.requestPaymentStatus(props.policy).then((result) => {
            console.log("requestPaymentStatus --------");
            if (result?.policy?.errorMessage != null) {
                console.log("requestPaymentStatus ERROR--------");
                console.error(result?.policy?.errorMessage);
                props.hideSpinner();
                props.showError([result?.policy?.errorMessage]);
                return;
            }
            const policyPaymentNumberResult = result?.policyHome?.vietnamPaymentStatus?.policyNumber;
            console.log("policyPaymentStatusResult----" + policyPaymentNumberResult);
            console.log("policyPaymentStatus----" + result?.policyHome?.vietnamPaymentStatus?.paymentStatus);
            let paymentStatus = result?.policyHome?.vietnamPaymentStatus?.paymentStatus;
            if (paymentStatus && paymentStatus === 'PAYMENT_RECEIVED') {
                props.policy.policyHome.vietnamPaymentStatus = result?.policyHome?.vietnamPaymentStatus;
                props.storePolicy(props.policy);
                console.log("PAYMENT_RECEIVED----");
                paymentGatewaySuccessReturn();

            }else{
                props.hideSpinner();
                if (checkStatusParam && checkStatusParam === '') {
                    props.showError(['No Existing Payment']);
                    console.log('No Existing Payment');
                }
            }
        }, () => {
            props.hideSpinner();
            if (checkStatusParam && checkStatusParam === '') {
                props.showError(['No Existing Payment Status']);
                console.log('No Existing Payment Status');
            }
        });
    };

    const paymentGatewaySuccessReturn = () => {
        console.log('paymentGatewaySuccessReturn------');
        props.callIssueAndReceiptPolicy(props.policy).then((resp) => {
            if (resp?.errorMessage != null){
                console.log("callIssueAndReceiptPolicy Campaign Promo ERROR--------");
                console.error(resp?.errorMessage);
            }

            props.hideSpinner();
            console.log('Policy issue success');
            _sendLog('Home-VNM React - paymentGatewaySuccessReturn - Policy issue success', 'info');
            props.history.push('/finish');
        }, err => {
            props.hideSpinner();
            props.showError(['Error in issuing policy']);
            console.error('Policy issue error'+err);
            _sendLog('Home-VNM React - Policy issue error on '+props.policy.policyNumber);
        });
    };

    const premiumUnit = "VND";
    const getHousingType = (type) => {
        switch (type) {
            case 'PU':
                return 'Apartment'
            case 'PR':
                return 'Private House'
            default:
                return 'Apartment'
        }
    }

    const housingTypeOrFloorAreaLabel = cfg('landingHousingTypeLabel.label');
    const housingTypeOrFloorAreaValue = cfg("ConfirmHousingName" + housingType + ".label");

    return (
        <Template step={3} mobileSpace  mobileBack={continueToBackPage} >
            <div className="Payment">
                <div className={"PageHeading TCard LargeH tertiary90"}>
                    <HeadingIcon1 className={"PaymentHeadingIcon"}/>
                    <span>{cfg('PaymentHeading.label')}</span>
                </div>
                <div className="WhiteCard Col BlueBorder PaymentBriefInfoCard">
                    <span className={"PaymentPlanName NormalSH InterB tertiary90"}>{cfg('PaymentPlanDescription.label')} - {cfg("PaymentPlanAnnualPremium.label")}</span>
                    <div  className="Row PaymentBriefInfoCardOuter">
                        <div className="Row PaymentBriefInfoCardOuter">
                            <div className={"KeyHeading"}>{cfg('occupancyType.label')}</div>
                            <div className="Row PaymentInsuredGroup">
                                <Landlord/>
                                &nbsp;
                                {/*<HomeIcons className={"PaymentHomeIcon"}/>&nbsp;*/}
                                <div>
                                    <div className={"OccupancyType"}>{cfg("ConfirmOccupancyName" + occupancyType + ".label").split('\n').map((word,index)=>{
                                        return (<li key={index}>
                                            {word}
                                        </li>)
                                    })}</div>
                                </div>
                            </div>
                        </div>
                        <div className="VLine"></div>
                        <div className="Row PaymentBriefInfoCardOuter">
                            <div className={"KeyHeading"}>{housingTypeOrFloorAreaLabel}</div>
                            <div className="Row PaymentInsuredGroup">
                                <div>
                                    <div className={"OccupancyType OTMobileCenter"}>{housingTypeOrFloorAreaValue}</div>
                                </div>
                            </div>
                        </div>
                        <div className="VLine"></div>
                        <div  className="Row PaymentBriefInfoCardOuter">
                            <div className={"KeyHeading"}>{cfg('PaymentInsurancePeriod.label')}</div>
                            <div className={"PaymentInsurancePeriod"}>{inceptionDate} - {policyExpiryDate} </div>
                        </div>
                    </div>
                </div>
                <div className="WhiteCard PaymentInfoContainer">
                    <div className={"PaymentAmountGroup"}>
                        <div className={"PaymentAmountHeading"}>{cfg('PaymentYouPay.label')}</div>
                        <div className={"PaymentAmountSGD"}>{premiumUnit}{totalPremium}</div>
                    </div>
                </div>
                <div className="ApplicantToggleGroup">
                    <Toggle heading={cfg("PaymentImportantNotesTitle.label")} isExpanded={true} onClick={()=>{
                        let eventNameNotes = 'expand-important-notes';
                        if (dispImportantNotes) {
                            eventNameNotes = 'collapse-important-notes';
                        }
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': eventNameNotes,
                            }
                        });
                        setDispImportantNotes(!dispImportantNotes);
                    }}>
                        <div className="PaymentRemarks">
                            <ul>
                                <li>
                                    {cfg("PaymentImportantNotesWording.label")}
                                </li>
                            </ul>
                        </div>
                    </Toggle>
                </div>
                <div className="Confirm">
                    <div className="ButtonGroup">
                        {paymentOrderNo ==="" &&
                        <QnectButton color="secondary" className={"mobileBack"} onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'back',
                                }
                            });
                            return continueToBackPage();
                        }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{cfg('PaymentBackButton.label')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</QnectButton>
                        }
                            <div className="space"></div>
                        <QnectButton color="primary" onClick={() =>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'pay',
                                }
                            });
                            return continueToNextPage();
                        }}>
                            {/*cfg('PaymentPayButton.label')*/}
                            {paymentOrderNo ? cfg('PaymentCheckStatusButton.label') : cfg('PaymentPayButton.label')}
                            </QnectButton>
                    </div>
                </div>
            </div>
        </Template>
    );

}


// get status from redux (subscription)
const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        campaign:_getCampaign(state),
        country: _getCountry(state)
    }
}

// send service request to redux
const mapDispatchToProps = {
    callSavePendingTransaction: _savePendingTransaction,
    storePolicy: _storePolicy,
    callLogPaymentLifecycle: _logPaymentLifecycle,
    callIssueAndReceiptPolicy: _issueAndReceiptPolicy,
    requestPaymentRedirect: _requestPaymentRedirect,
    requestPaymentStatus: _requestPaymentStatus,
    showError: _showError,
    clearError: _clearError,
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));



