import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';

import "./apply.css";
import {withRouter} from "react-router";
import Template from "../template";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import QnectDateField from "../qnect-ui/qnect-date-field";
import moment from "moment";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {_getPolicy, _ratePolicy, _singleRatePolicy, _storePolicy} from "../../store/policy/service";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {_validateForm, getNationalityDesc} from "../../utils/utility";
import useConfig from "../qnect-ui/qnect-config";
import _, {cloneDeep} from "lodash";
import TagManager from "react-gtm-module";
import {_getAgent} from "../../store/agent/service";
import {ReactComponent as Individual} from "../../assets/adultblue.svg";
import QnectButton from "../qnect-ui/qnect-button";
import Toggle from "../ui/toggle";
import {ReactComponent as HeadingIcon1} from "../../assets/stepicon3.svg";
import validateNRIC from "../../utils/nric-validation";
import {ReactComponent as AdjustPlus} from "../../assets/add_circle_white.svg";
import {ReactComponent as DeleteSpecifiedPersonalValuables} from "../../assets/Cross.svg";
import {ReactComponent as SpecifiedPersonalValuables} from "../../assets/personal_valuable.svg";
import {ReactComponent as ErrorIcon} from "../../assets/Warning.svg";

const Apply = (props) => {
    const cfg = useConfig(props.lang, props.country );
    const formId = "applyForm";
    const NRIC = "NRIC";
    const premiumUnit = "HK$" ;

    const [specifiedValuablesList, setSpecifiedValuablesList] = useState(()=>{
        const valueablesList = (props?.policy?.policyHome?.specifiedValuablesList.length) ?
        buildUiValuablesList(props?.policy?.policyHome?.specifiedValuablesList) : [{}];
        return valueablesList;
    });

    const [uiEmployeeList, setUiEmployeeList] = useState(()=>{
        const numberOfEmployees = props.policy?.policyHome?.numberOfEmployees;
        const employeeList = props.policy?.policyHome?.employeeList;
        const uiEmpList = buildUiEmployeeList(numberOfEmployees, employeeList);
        return uiEmpList;
    });
    const [sumInsuredError, setSumInsuredError] = useState(false);
    const [showImportantNotes, setShowImportantNotes] = useState(true);
    const applicantIdTypeRef = useRef(null);
    const applicantHKIdRef = useRef(null);
    const applyRef = useRef(null);
    const valueblesListRef = useRef(null);

    const totalSumInsured = props?.policy?.policyHome?.totalSpecifiedValuables;

    function numberWithCommas(x) {
        if(x) {
            var parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
    }

    function buildUiValuablesList(specifiedValuablesList) {
        let valueablesList = [];

        if (Array.isArray(specifiedValuablesList) && specifiedValuablesList.length > 0) {
            const newSpecifiedValuablesList = _.cloneDeep(specifiedValuablesList);
            valueablesList = newSpecifiedValuablesList.map((item) => {
                item.type = String(item.type);
                item.typeDesc = String(item.typeDesc);
                item.sumInsured = Number(item.sumInsured);
                return item;
            });
        }

        return valueablesList;
    }

    function validateSumInsured() {
        if (totalSumInsured) {
            const sumInsuredNodeList = valueblesListRef?.current?.querySelectorAll('div.ApplicantSumInsuredField input.MuiInputBase-input');
            let sumInsuredTotal = 0;

            if (sumInsuredNodeList && sumInsuredNodeList.length > 0) {
                Array.from(sumInsuredNodeList).forEach((inputData) => {
                    sumInsuredTotal += Number(inputData.value);
                })
            }

            if (Number(sumInsuredTotal) === Number(totalSumInsured)) {
                setSumInsuredError(false);
                return true;
            } else {
                setSumInsuredError(true);
                return false;
            }
        }
        return true;
    }

    function getValueablesList() {
        const sumInsuredNodeList = valueblesListRef?.current?.querySelectorAll('div.ApplicantSumInsuredField input.MuiInputBase-input');
        let sumInsuredTotal = 0;

        if (sumInsuredNodeList && sumInsuredNodeList.length > 0) {
            Array.from(sumInsuredNodeList).forEach((inputData)=>{
                sumInsuredTotal += Number(inputData.value);
            })
        }
        return Number(props?.policy?.policyHome?.totalSpecifiedValuables) > sumInsuredTotal;
    }

    function buildUiEmployeeList(numberOfEmployee, employeeList) {
        let uiEmpList = [];
        if (!isNaN(numberOfEmployee) && Number(numberOfEmployee) > 0) {
            if (Array.isArray(employeeList) && employeeList.length > 0) {
                const newEmployeeList = _.cloneDeep(employeeList);
                uiEmpList = newEmployeeList.map((employee) => {
                    const dob = employee.dateOfBirth;
                    if (dob && moment(dob, 'DD/MM/YYYY').isValid()) {
                        employee.dateOfBirth = moment(dob, 'DD/MM/YYYY');
                    }
                    if (!employee?.idType) {
                        employee.idType = NRIC;
                    }
                    if (!employee?.dateOfBirthErrorMessage) {
                        employee.dateOfBirthErrorMessage = '';
                    }
                    if (!employee?.hkIdErrorMessage) {
                        employee.hkIdErrorMessage = '';
                    }
                    return employee;
                });
            } else {
                const numberOfEmp = Number(numberOfEmployee);
                for (let i = 0; i < numberOfEmp; i++) {
                    uiEmpList.push({
                        "dateOfBirth": "",
                        "hkId": "",
                        "idType": "",
                        "nationality": "",
                        "nationalityDesc": "",
                        "fullName": ""
                    });
                }
            }
        }
        return uiEmpList;
    }

    function momentToString(dateMoment) {
        if (dateMoment && moment.isMoment(dateMoment) && dateMoment.isValid()) {
            return dateMoment.format('DD/MM/YYYY');
        }
        return '';
    }

    function resetInvalidFields() {
        if (Array.isArray(uiEmployeeList) && uiEmployeeList.length > 0) {
            const oldData = [...uiEmployeeList];
            if (!isEmployeeDobValid(uiEmployeeList)) {
                oldData.forEach(emp => {
                    if (!isValidEmployeeBirthday(emp.dateOfBirth)) {
                        emp['dateOfBirth'] = '';
                    }
                });
                setUiEmployeeList([...oldData]);
            }
            if (!isEmployeeHkidValid(uiEmployeeList)) {
                oldData.forEach((emp,index) => {
                    if (emp.idType === NRIC && !validateNRIC(emp.hkId)) {
                        emp['hkId'] = '';
                    }
                });
                setUiEmployeeList([...oldData]);
            }
        }
    }

    function isEmployeeDobValid (children) {
        if (Array.isArray(children) && children.length > 0) {
            const hasError = children.some((child) => {
                return !isValidEmployeeBirthday(child.dateOfBirth);
            });
            return !hasError;
        }
        return false;
    }

    function isEmployeeHkidValid (employeeList) {
        if (Array.isArray(employeeList) && employeeList.length > 0) {
            const hasError = employeeList.some((child, index) => child.idType === NRIC && !validateNRIC(child.hkId));
            return !hasError;
        }
        return false;
    }

    function validateApply(formId) {
        if (_validateForm(formId)) {
            return true;
        }
        return false;
    }

    function setValuablesField(field, value, index, label) {
        const handleValueableType = (type, valuable, index) => {
            if (isBlankInput(type)) {
                value.typeErrorMessage = "applyErrorMessage.itemTypeRequired";
            } else {
                // TODO find out why clearing the dropdown error message doesnt validate the field and nullifies the error message
                // value.typeErrorMessage = '';
            }
            valuable.type = type;
            valuable.description = label;
        }

        const handleSumInsured = (sumInsured, valuable, data, index) => {
            if (isBlankInput(sumInsured)) {
                valuable.sumInsuredErrorMessage = "applyErrorMessage.sumInsuredValueRequired";
            }

            valuable.sumInsured = sumInsured;
            validateSumInsured();
        }

        const handleDescription = (typeDesc, valuable, index) => {
            if (isBlankInput(typeDesc)) {
                valuable.typeDescErrorMessage = "applyErrorMessage.typeDescRequired";
            } else {
                valuable.typeDescErrorMessage = "";
            }
            valuable.typeDesc = typeDesc;
        }

        const oldData = [...specifiedValuablesList];

        const currentItem = oldData[index];

        if (currentItem) {
            switch(field) {
                case "type":
                    handleValueableType(value, currentItem, index);
                    break;
                case "sumInsured":
                    handleSumInsured(value, currentItem, oldData, index)
                    break;
                case "typeDesc":
                    handleDescription(value, currentItem, index)
                    break;
                default:
                    console.log("Field not found");
            }
        }

        setSpecifiedValuablesList([...oldData]);
    }


    function setEmployeeField(field, value, index) {
        const handleEmpDob = (dob, emp) => {
            if (isValidEmployeeBirthday(dob)) {
                emp.dateOfBirthErrorMessage = '';
            } else {
                if (isBlankInput(dob)) {
                    emp.dateOfBirthErrorMessage = "applyErrorMessage.birthDateRequired";
                } else {
                    emp.dateOfBirthErrorMessage = "applyErrorMessage.birthDateInValid";
                }
            }
            emp.dateOfBirth = dob;
        }

        const handleIdType = (idType, emp) => {
            emp.idType = idType;
            emp.hkId = '';
            emp.hkIdErrorMessage = '';
            // emp.idNoLabel = (idType === NRIC) ? nricNoLabel : passportNoLabel;
            // emp.idHelpText = (idType === NRIC) ? nricHelpText : '';
        }

        const handleHkId = (hkId, emp) => {
            if (emp.idType === NRIC && !validateNRIC(hkId)) {
                emp.hkIdErrorMessage = "applyErrorMessage.invalidNRIC";
            } else {
                emp.hkIdErrorMessage = '';
            }
            emp.hkId = hkId;
        }

        const handleNationality = (nationalityCode, emp) => {
            if (nationalityCode) {
                emp.nationality = nationalityCode;
                emp.nationalityDesc = getNationalityDesc(nationalityCode)
            }
        }

        const oldData = [...uiEmployeeList];
        const currentEmp = oldData[index];
        if (currentEmp) {
            switch(field) {
                case "fullName":
                    currentEmp.fullName = value;
                    break;
                case "dateOfBirth":
                    handleEmpDob(value, currentEmp);
                    break;
                case "hkId":
                    handleHkId(value, currentEmp)
                    break;
                case "idType":
                    handleIdType(value, currentEmp)
                    break;
                case "nationality":
                    handleNationality(value, currentEmp)
                    break;
                default:
                    console.log("Field not found");
            }
        }
        setUiEmployeeList([...oldData]);
    }

    function isAgeBelow18YearsOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(18, 'years').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isAfter(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isAgeOver70YearsOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(70, 'years').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isBefore(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isAgeBelow6MonthOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(6, 'months').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isAfter(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isAgeOver25YearsOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(25, 'years').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isBefore(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isBlankInput(inputData) {
        return !inputData;
    }

    function isValidDateFormat(inputData) {
        if (!isBlankInput(inputData)) {
            if (moment.isMoment(inputData) && inputData.isValid()) {
               return true;
            }
        }
        return false;
    }

    function isValidEmployeeBirthday(dob) {
        if (isValidDateFormat(dob)){
            // if (!isAgeBelow6MonthOld(dob) && !isAgeOver25YearsOld(dob)){
                return true;
            // }
        }
        return false;
    }

    function setValueToPropsPolicy(policyData) {
        if (Array.isArray(uiEmployeeList) && uiEmployeeList.length > 0) {
            const formattedEmpList = uiEmployeeList.map((emp)=>{
                emp.dateOfBirth = momentToString(emp.dateOfBirth);
                emp.fullName = String(emp.fullName).toUpperCase();
                emp.hkId = String(emp.hkId).toUpperCase();
                emp.nationality = String(emp.nationality).toUpperCase();

                delete emp['dateOfBirthErrorMessage'];
                delete emp['hkIdErrorMessage'];
                return emp;
            });
            policyData.policyHome.employeeList = formattedEmpList;
        }

        if (Array.isArray(specifiedValuablesList) && specifiedValuablesList.length > 0 && totalSumInsured) {
            const formattedValuablesList = specifiedValuablesList.map((item)=>{
                item.type = String(item.type);
                item.typeDesc = String(item.typeDesc);
                item.sumInsured = Number(item.sumInsured);
                item.description = String(item.description);
                return item;
            });
            policyData.policyHome.specifiedValuablesList = formattedValuablesList;
        }
    }

    function modifyValuablesList(policyData) {
        policyData.policyHome.specifiedValuablesList = specifiedValuablesList;
    }

    function backToPreviousPage () {
        const policyData = cloneDeep(props.policy);
        setValueToPropsPolicy(policyData);
        props.storePolicy(policyData);

        props.showSpinner();
        props.ratePolicy().then(resp => {
            console.log(resp)
            if (resp.errorMessage) {
                console.error(resp.errorMessage);
            } else {
                props.history.push('/quote');
            }
            props.hideSpinner();
        }, (error) => {
            console.error(error?.response?.data?.message);
            props.hideSpinner();

        })
    }

    function continueToNextPage () {
        if (validateSumInsured() && validateApply(formId)) {
            const policyData = cloneDeep(props.policy);
            setValueToPropsPolicy(policyData);
            props.storePolicy(policyData);

            props.showSpinner();
            props.singleRatePolicy().then ( resp => {
                console.log(resp)
                if(resp.errorMessage){
                    console.error(resp.errorMessage);
                }else{
                    props.history.push('/applicant');
                }
                props.hideSpinner();
            }, (error) => {
                console.error(error?.response?.data?.message);
                props.hideSpinner();
            })
        } else {
            resetInvalidFields();
        }
    }

    useEffect(()=>{

    },[uiEmployeeList])
    return (
        <Template title={"Personal Accident Protection Plus"} step={2} info={'price'} mobileSpace>
            <div className="Apply" ref={applyRef}>
                <div className={"PageHeading TCard LargeH tertiary90"}>
                    <HeadingIcon1 className={"ApplyTopIcon"}/>
                    <span>{cfg('optionalCoverTitle.label')}</span>
                </div>
                {totalSumInsured &&
                <div  className="WhiteCard ApplicantInfoContainer">
                    <div className="ApplicantTypeGroup HKGOptionalCover">
                        <div className="SpecifiedPersonalValuablesFlex">
                            <SpecifiedPersonalValuables/>
                            <div className="Content">
                                <div className="AgeGroup">{cfg("ApplySpecifiedPersonalValuablesHeading.label")}</div>
                                <div className="JobRole">{cfg("ApplySpecifiedPersonalValuablesHeadingDesc.label")}{premiumUnit}{numberWithCommas(totalSumInsured)}</div>
                            </div>
                        </div>
                        {sumInsuredError &&
                        <div className="SPVErrorMsgBox">
                            <div className="SPVErrorIcon">
                                <ErrorIcon/>
                            </div>
                            <div className="SPVErrorMsg">{cfg("ApplySpecifiedPersonalSumInsuredError.label")}
                            </div>
                        </div>
                        }
                    </div>
                    <div className="SpecifiedPersonalValuablesGroups" ref={valueblesListRef}>
                        {
                            specifiedValuablesList.map((value, index)=>{
                                return (
                                    <div className="ApplicantInfoGroup CapitalizedInput">
                                        <div className="ApplicantInputFields">
                                            <div className="ApplicantInputField Full SBHeading">
                                                <div className="EmployeeNo">{cfg('ApplySpecifiedPersonalValuablesItem.label')} {index+1}</div>
                                                {
                                                    index!=0&&<DeleteSpecifiedPersonalValuables onClick={()=>{
                                                        setSpecifiedValuablesList(specifiedValuablesList.filter((e,i,a)=>index!==i));
                                                        let valueablesLst = cloneDeep(props?.policy?.policyHome?.specifiedValuablesList);
                                                        modifyValuablesList(props?.policy);
                                                    }} className={"DeleteSPV"}/>
                                                }
                                            </div>
                                        </div>
                                        <div className="ApplicantInputFields">
                                            <div className="ApplicantInputField">
                                                <QnectSelectField
                                                    field="optionalCoverItemType"
                                                    name={"optionalCoverItemType"+(index)}
                                                    label={cfg('optionalCoverItemType.label')}
                                                    options={cfg('optionalCoverItemType.options')}
                                                    formId={formId}
                                                    value={value.type}
                                                    fixedLabel
                                                    errorMessage={value.typeErrorMessage}
                                                    reff ={applicantIdTypeRef}
                                                    onChange={(value, label) => setValuablesField("type", value, index, label)}>
                                                </QnectSelectField>
                                            </div>
                                            <div className="ApplicantInputField ApplicantSumInsuredField">
                                                <QnectTextField
                                                    field={'ApplySpecifiedPersonalValuablesSumInsured'}
                                                    name={"ApplySpecifiedPersonalValuablesSumInsured"+(index)}
                                                    formId={formId}
                                                    value={value.sumInsured}
                                                    fixedLabel
                                                    helperText={""}
													errorMessage={value.sumInsuredErrorMessage}
                                                    maxLength={50}
                                                    onBlur={(event) => setValuablesField("sumInsured", event.target.value, index)}>
                                                </QnectTextField>
                                            </div>
                                        </div>
                                        <div className="ApplicantInputFields">
                                            <div className="ApplicantInputField Full">
                                                <QnectTextField
                                                    field={'ApplySpecifiedPersonalValuablesDescription'}
                                                    name={"ApplySpecifiedPersonalValuablesDescription"+(index)}
                                                    formId={formId}
                                                    value={value.typeDesc}
                                                    fixedLabel
                                                    helperText={''}
                                                    errorMessage={value.typeDescErrorMessage}
                                                    maxLength={50}
                                                    onBlur={(event) => setValuablesField("typeDesc", event.target.value, index)}>
                                                </QnectTextField>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }).reduce((prev,curr)=>[prev,<div className={"ApplicantInfoGroup"}><div className="ApplicantInputFields ApplySeparatorContainer"> <div className={"ApplySeparator"}/> </div> </div>,curr])
                        }
                        <div className="ApplicantInfoGroup">
                            <div className="ApplicantInputFields">
                                <div className="ApplicantInputField Full AddSpecifiedPersonalValuables">
                                    <div className="AddSpecifiedPersonalValuablesBtn" onClick={()=>{setSpecifiedValuablesList([...specifiedValuablesList,{}])}}>
                                        <AdjustPlus className={"Icon AdjustPlus"}   alt="" />
                                        {cfg('ApplySpecifiedPersonalValuablesAddItem.label')}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                }
                <div  className="WhiteCard ApplicantInfoContainer">
                    <div className="ApplicantTypeGroup ApplyDMSOptionalCover">
                        <Individual className={"Individual"}/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg("optionalCoverDomesticWorkerTitle.label")}</div>
                            <div className="JobRole">{cfg("optionalCoverDomesticWorkerSubTitle.label") + uiEmployeeList.length}</div>
                        </div>
                    </div>
                    <div className="DomesticWorkerGroups">
                        {
                            uiEmployeeList.map((value, index)=>{
                                return (
                                    <div className="ApplicantInfoGroup CapitalizedInput">
                                        <div className="ApplicantInputFields">
                                            <div className="EmployeeNo">{cfg("ApplyEmployee.label")} {index+1}</div>
                                        </div>
                                        <div className="ApplicantInputFields">
                                            <div className="ApplicantInputField">
                                                <QnectTextField
                                                    field="optionalCoverEmployeeFullName"
                                                    name="optionalCoverEmployeeFullName"
                                                    formId={formId}
                                                    value={value.fullName}
                                                    fixedLabel
                                                    maxLength={50}
                                                    onBlur={(event) => setEmployeeField("fullName",event.target.value, index)}>
                                                </QnectTextField>
                                            </div>
                                            <div className="ApplicantInputField ">
                                                <div className="PassportSelect">
                                                    <QnectSelectField
                                                        field="optionalCoverEmployeeIdType"
                                                        name="optionalCoverEmployeeIdType"
                                                        formId={formId}
                                                        value={value.idType}
                                                        label={cfg('optionalCoverEmployeeIdType.label')}
                                                        options={cfg('optionalCoverEmployeeIdType.options')}
                                                        fixedLabel
                                                        reff ={applicantIdTypeRef}
                                                        onChange={(value) => setEmployeeField("idType",value, index)}>
                                                    </QnectSelectField>
                                                </div>
                                                <div className="PassportText" ref={applicantHKIdRef}>
                                                    <QnectTextField
                                                        field={value.idType==='Passport'?'optionalCoverEmployeePassport':'optionalCoverEmployeeHkId'}
                                                        name="optionalCoverEmployeeHkId"
                                                        formId={formId}
                                                        value={value.hkId}
                                                        label={cfg('optionalCoverEmployeeHkId.label')}
                                                        options={cfg('optionalCoverEmployeeHkId.options')}
                                                        fixedLabel
                                                        helperText={cfg("optionalCoverEmployeeHkIdHelperText.label")}
                                                        errorMessage={value.hkIdErrorMessage}
                                                        maxLength={50}
                                                        onBlur={(event) => setEmployeeField("hkId",event.target.value,index)}>
                                                    </QnectTextField>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ApplicantInputFields">
                                            <div className="ApplicantInputField">
                                                <QnectSelectField
                                                    field="optionalCoverEmployeeNationality"
                                                    name="optionalCoverEmployeeNationality"
                                                    formId={formId}
                                                    value={value.nationality}
                                                    fixedLabel
                                                    onChange={(value) => setEmployeeField("nationality",value, index)}>
                                                </QnectSelectField>
                                            </div>
                                            <div className="ApplicantInputField">
                                                <QnectDateField
                                                    field="optionalCoverEmployeeDateOfBirth"
                                                    name="optionalCoverEmployeeDateOfBirth"
                                                    formId={ formId }
                                                    value={value.dateOfBirth}
                                                    fixedLabel
                                                    helperText={cfg("applyHelperText.dateOfBirth")}
                                                    initialFocusedDate={moment('01/01/1980', 'DD/MM/YYYY')}
                                                    errorMessage={value.dateOfBirthErrorMessage}
                                                    onChange={(value) => setEmployeeField("dateOfBirth",value,index)}
                                                    maxDate={moment().subtract(18, 'years')}
                                                    minDate={moment().subtract(70, 'years')}>
                                                </QnectDateField>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }).reduce((prev,curr)=>[prev,<div className={"ApplicantInfoGroup"}><div className="ApplicantInputFields ApplySeparatorContainer"> <div className={"ApplySeparator"}/> </div> </div>,curr])
                        }
                    </div>
                </div>

                <div className="ApplicantToggleGroup">
                    <Toggle heading={cfg("ApplyImportantNotesTitle.label")} isExpanded={true} onClick={()=>{
                        let eventNameNotes = 'expand-important-notes';
                        if (showImportantNotes) {
                            eventNameNotes = 'collapse-important-notes';
                        }
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': eventNameNotes,
                            }
                        });
                        setShowImportantNotes(!showImportantNotes);
                    }}>
                        <div className="QuoteRemarks">
                            <ul className={"sgp-remarks"}>
                                {cfg('MajorExclusionsContent.label').split('\n').map(line=><li>{line}</li>)}
                            </ul>
                        </div>
                    </Toggle>
                </div>
                <div className={"ButtonGroup"}>
                    <QnectButton color={"secondary"} className={"mobileBack"} onClick={()=> {
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'back',
                            }
                        });
                        return backToPreviousPage();}}>
                        {cfg('ApplyBack.label')}
                    </QnectButton>
                    <div className="space"></div>
                    <QnectButton onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'continue',
                            }
                        });
                        return continueToNextPage();}}>
                        {cfg('ApplyContinue.label')}
                    </QnectButton>
                </div>

            </div>
        </Template>
    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        agent: _getAgent(state),
        country: _getCountry(state)
    }
}

// send service request to redux
const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    storePolicy: _storePolicy,
    singleRatePolicy: _singleRatePolicy,
    ratePolicy: _ratePolicy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Apply));


