import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {rgba} from 'polished';
import classnames from 'classnames';
import property from 'lodash/property';

import {ms} from '../../../common/browser-css';
import checkmark from './checkmark';

const StyledRadioButton = styled.input.attrs({
    type: 'radio',
})`
    appearance: none;
    box-sizing: border-box;
    display: inline-block;
    transition: box-shadow 100ms linear, border-color 100ms linear, background-color 100ms linear;
    margin: 0;
    border: 1px solid ${property('theme.color.tertiary10')};
    border-radius: 999px;
    background-color: ${property('theme.color.white')};
    background-position: center;
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;

    ${ms`
        background-position-x: -1px;
        background-position-y: -1px;
    `}

    &:focus,
    &:active {
        border: 1px solid ${property('theme.color.tertiary90')};
        box-shadow: 0 0 0 5px ${({theme}) => rgba(theme.color.tertiary, 0.1)};
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        border: 1px solid ${property('theme.color.grey50')};

        &::-ms-check {
            border: 1px solid ${property('theme.color.grey50')};
            background-color: ${property('theme.color.white')};
        }
    }

    &:checked {
        background-image: ${checkmark('primary90')};

        &::-ms-check {
            background-image: ${checkmark('primary90')};
            background-position: -1px -1px;
            color: transparent;

            &:disabled {
                background-image: ${checkmark('grey50')};
            }
        }

        &:disabled {
            background-image: ${checkmark('grey50')};

            &::-ms-check {
                background-color: ${property('theme.color.white')};
                background-image: ${checkmark('grey50')};
            }
        }
    }

    &:hover:not(:disabled) {
        border: 1px solid ${property('theme.color.tertiary90')};
        box-shadow: 0 0 0 7px ${({theme}) => rgba(theme.color.tertiary, 0.1)};

        &::-ms-check {
            border: 1px solid ${property('theme.color.tertiary90')};
        }
    }
`;

const RadioButton = React.forwardRef((
    {
        className,
        name,
        value,
        checked,
        onChange,
        ...rest
    },
    ref
) => (
    <StyledRadioButton
        {...rest}
        ref={ref}
        className={classnames('RadioButton', className)}
        {...{name, value, checked, onChange}}
    />
));

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    value: propTypes.string,
    checked: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    onChange: propTypes.func,
};

export default RadioButton;
