import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"
import Button from "../ui/button";

export const CarouselDot = (props) => {
    return (
        <div className="topDotContainer">
            {props.dotIcon}
            <span className="topControlsTitle">{props.dotTitle}</span>
        </div>
    )
};

function openInNewTab(url) {
    window.open(url, "_blank", "noreferrer");
}

export const CarouselContent = (props) => {

    return (
         <React.Fragment>
            <div className="sampleDetails">
                <div className="sampleDetailsTitle">
                    {props.carouselItemTitle}
                </div>
                <div className="sampleDetailsDescription">
                    <span>{props.carouselItemListItem1}</span><br/>
                </div>
            </div>
             {props.carouselItemListItem2 &&
                 <div className="sampleDetailsButton">
                     <span><Button className="readMoreBtn" onClick={() => {
                         openInNewTab(props.carouselItemListItem2)
                     }}>Read More</Button></span>
                 </div>
             }
        </React.Fragment>
    )
};

export default CarouselDot;
