import React, {useEffect} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import noop from 'lodash/noop';
import {qbeTheme, ConfigProvider} from './ui';

import Routes, {getHistory} from "./routes";
import ErrorModal from "./components/error-modal";
import Spinner from "./components/spinner";
import ModalPopup from "./components/modal-popup";

function App({
               inputDateFormat,
               onLoad = noop,
               onChangePath = noop,
             }) {
  useEffect(
      () => {
        onLoad();

        // Notify subscribers of history path changes, including the initial path.
        onChangePath(getHistory().location.pathname);

        return getHistory().listen(({pathname}) => {
          onChangePath(pathname);
        });

      },
      []
  );

  return (
      <ConfigProvider
          theme={qbeTheme}
          imageBaseUrl="/static/img"
          inputDateFormat="DD/MM/YYYY">
            <Router history={getHistory()} basename={process.env.PUBLIC_URL}>
              <Routes />
            </Router>
            <ModalPopup/>
            <ErrorModal/>
            <Spinner />
      </ConfigProvider>
  );
}

App.propTypes = {
  inputDateFormat: propTypes.string,
  onLoad: propTypes.func,
  onChangePath: propTypes.func,
};


export default App;
