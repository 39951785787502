import { connect } from 'react-redux';
import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import Landing from "../components/landing";
import {
    _setLang,
    _fetchOccupations,
    _getCountry,
    _setInitialURL,
    _setCountry,
    _checkSystemAvailable,
    _getLang
} from "../store/system/service";
import {_clearPolicy} from "../store/policy/service";
import {getParameterValueFromUrl, getQueryParamValueByName, getCountryFromUrl, getPathNameFromUrl} from "../utils/utility";
import TagManager from "react-gtm-module";
import useConfig from "../components/qnect-ui/qnect-config";

const MainPage = (props) => {
    const cfg = useConfig(props.lang);

    useEffect(() => {
        props.clearPolicy();

        let campaign = '';
        let agentNo = '';
        let lang =  props.lang ? props.lang : 'VN';
        let utm_source = '';
        let utm_medium = '';
        let utm_campaign = '';
        let country = 'VNM';
        const hostname = window.location.hostname;
        console.log("hostname---------------"+hostname);
        console.log("START pathname---------------"+props.location.pathname);
        const pathName = getPathNameFromUrl(props.location.pathname,props.location.search);
        if (pathName) {
            console.log("pathName---------------"+pathName);
            console.log("search---------------"+props.location.search);
            const initialURL = pathName;
            console.log("initialURL---------------"+initialURL);
            props.setInitialURL(initialURL);
            campaign = getParameterValueFromUrl(pathName, "campaign");
            agentNo = getParameterValueFromUrl(pathName, "partner");
            lang = getParameterValueFromUrl(pathName, "lang");
            country = getCountryFromUrl();
            utm_source = getQueryParamValueByName(props.location.search, 'utm_source');
            utm_medium = getQueryParamValueByName(props.location.search, 'utm_medium',);
            utm_campaign = getQueryParamValueByName(props.location.search, 'utm_campaign',);
            if (lang) {
                props.setLang(lang);
            } else {
                lang = props.lang ? props.lang : 'VN';
                props.setLang(lang);
            }

            console.log("country---------------"+country);
            props.setCountry(country);
        }

        //props.fetchOccupations(country);
        props.checkSystemAvailable(country);
        //Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        TagManager.dataLayer({
            dataLayer: {
                'pageStep': 'Home',
                'vPath': '/home',
                'event': 'vpageview',
                'campaigncode': campaign,
                'agentcode': agentNo,
                'language': lang.toUpperCase(),
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 1},	//Step1-Home
                    }
                }
            }
        });
    }, []);

    return (
        <Landing/>
    );
}

const mapStateToProps = (state) => {
    return {
        country: _getCountry(state),
        lang: _getLang(state)
    }
}

const mapDispatchToProps = {
    setLang: _setLang,
    setInitialURL: _setInitialURL,
    setCountry: _setCountry,
    clearPolicy:_clearPolicy,
    checkSystemAvailable: _checkSystemAvailable
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPage));
