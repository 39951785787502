import React from 'react';

import BaseIcon from '../common/base-icon';

export default function IconCalendar(props) {
    return (
        <BaseIcon {...props}>
            <path
                d="M8 3v2h8V3h2v2h3v16H3V5h3V3h2zm11 6H5v10h14V9zm-2 4v4h-4v-4h4z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </BaseIcon>
    );
}
