import {combineReducers} from 'redux';
import {systemReducer} from "./system/reducers";
import {addressReducer} from "./addresses/reducers";
import {policyReducer} from "./policy/reducers";
import {agentReducer} from "./agent/reducers";
import {campaignReducer} from "./campaign/reducers";

const reducer = combineReducers({
    sys: systemReducer,  // state in systemReducer will be visible through system.xxx
    addr: addressReducer, // state in addressReducer will be visible through address.xxx
    agnt: agentReducer, // state in addressReducer will be visible through agent.xxx
    pl: policyReducer,
    cm:campaignReducer
});

export default reducer;
