import React, {useEffect} from 'react';
import Applicant from "../components/applicant/applicant";
import TagManager from "react-gtm-module";
import {connect} from "react-redux";
import {_getPolicy} from "../store/policy/service";
import {withRouter} from "react-router";
import {getOptionalCoverForGA} from "../utils/utility";

const ApplicantPage = (props) => {
     useEffect(() => {
        const occupancyTypeDesc = props.policy.policyHome.occupancyTypeDesc;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'pageStep': occupancyTypeDesc + ' - Apply', //replace occupier with actual owner type,
          'vPath': '/' + occupancyTypeDesc.toLowerCase() + '-apply', //replace occupier with actual owner type,
          'event': 'vpageview',
          'ecommerce': {
              'checkout': {
                'actionField': {'step': 3,},	//Step3-Apply.
              }
          }
        });
    }, []);

    return (
        <Applicant>
        </Applicant>
    )
}
const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
    }
}
export default withRouter(connect(mapStateToProps, null)(ApplicantPage));
