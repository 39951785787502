import property from 'lodash/property';
import * as type from "./types";
import { Policy } from "../../model";
import axios from "axios";
import {_fetchAddressesFailed} from "../addresses/service";
import {ANZSIC_SGPPACK_LIST, ANZSIC_HKGPACK_LIST} from "./types";
import {_sendLog, updateObject} from '../../utils/utility';

export const _storePolicy = (policy) => {

    _sendLog(['HPK-React - Policy Service - _storePolicy: ', JSON.stringify(policy, null, 3)], 'info');
    return (dispatch, getState) => {
        dispatch({
            type: type.POLICY_STORE,
            data: policy
        })
    };
}

export const _fetchPolicyFailed = () => {
    return {
        type: type.POLICY_FETCH_FAILED
    };
}

export function _ratePolicy(policy) {

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let policy  = _getPolicy(getState());
            let sessionId = _getSystem(getState()).sessionNameId;
            console.log("sessionId" + sessionId);
            console.log("reactB2CProduct-----"+policy.reactB2CProduct);
            //let policy  = _ratePolicy(getState());
            let hasInsuredPerson = true;
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            axios.post(server + process.env.REACT_APP_POLICY_RATE_URL, JSON.stringify(policy), { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId }})
                .then(resp => {
                    let newPolicy = resp.data;
                    dispatch({
                        type: type.POLICY_STORE,
                        data: newPolicy
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['HPK-React - Policy Service - _ratePolicy: ', error]);
                    dispatch(_fetchAddressesFailed())
                    reject(error);
                });
        })
    }
}

export function _singleRatePolicy() {

    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            let policy  = _getPolicy(getState());
            let sessionId = _getSystem(getState()).sessionNameId;
            console.log("isReactB2CProduct-----"+policy.isReactB2CProduct);
            ///console.log("plantype-----"+policy.policyHome.planType);
            //console.log("cousingCode---"+policy.policyHome.housingCode);
            //console.log("occupancyType------"+policy.policyHome.occupancyType);
            //console.log("applicantAddress1------"+policy.policyHome.applicant.applicantAddress1);
            //console.log("applicantAddress2------"+policy.policyHome.applicant.applicantAddress2);
            //console.log("applicantPostCode------"+policy.policyHome.applicant.applicantPostCode);
            //console.log("sameAsInsuredAddress------"+policy.policyHome.applicant.sameAsInsuredAddress);
            console.log("employeeList------"+policy.policyHome.employeeList);
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            axios.post(server + process.env.REACT_APP_POLICY_SINGLE_RATE_URL, JSON.stringify(policy), { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId} })
                .then(resp => {
                    let newPolicy = resp.data;
                    dispatch({
                        type: type.POLICY_STORE,
                        data: newPolicy
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Policy Service - _singleRatePolicy: ', error]);
                    dispatch(_fetchAddressesFailed())
                    reject(error);
                });
        })
    }
}

export function _savePendingTransaction(policy) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(process.env.REACT_APP_UPDATE_TO_PENDING_URL, JSON.stringify(policy),
                {headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId}})
                .then(resp => {
                    console.log('Response', resp.data);

                    dispatch({
                        type: type.POLICY_STORE,
                        data: resp.data.policy
                    });
                    resolve(resp.data);

                })
                .catch(error => {
                    console.log(error);
                    _sendLog(['SME-React - Policy Service - _updatePolicyToPending: ', error])
                    reject(error);
                });
        })
    };
}

export function _requestPaymentRedirect(policy) {
    console.log('_requestPaymentRedirect--------');
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(server + process.env.REACT_APP_REDIRECT_PAYMENT, JSON.stringify(policy),
                {headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId}})
                .then(resp => {
                    console.log('Response--------', resp.data);
                    console.log('_requestPaymentRedirect - success return');
                    resolve(resp.data);
                })
                .catch(error => {
                    console.log('ERROR--------REACT_APP_REDIRECT_PAYMENT');
                    console.error(error);
                    _sendLog(['HPK-React - Policy Service - _requestPaymentRedirect: ', error]);
                    reject(new Error(error));
                });
        })
    };
}

export function _requestPaymentInquiry(policy) {
    console.log('_requestPaymentInquiry--------');
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(server + process.env.REACT_APP_PAYMENT_INQUIRY, JSON.stringify(policy),
                {headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId}})
                .then(resp => {
                    console.log('Response--------', resp.data);
                    console.log('_requestPaymentInquiry - success return');
                    resolve(resp.data);
                })
                .catch(error => {
                    console.log('ERROR--------REACT_APP_PAYMENT_INQUIRY');
                    console.error(error);
                    _sendLog(['HPK-React - Policy Service - _requestPaymentInquiry: ', error]);
                    reject(new Error(error));
                });
        })
    };
}

export function _requestPaymentStatus(policy) {
    console.log('_requestPaymentStatus--------');
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(server + process.env.REACT_APP_PAYMENT_STATUS, JSON.stringify(policy),
                {headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId}})
                .then(resp => {
                    console.log('Response--------', resp.data);
                    let newPolicy = resp.data;
                    dispatch({
                        type: type.POLICY_STORE,
                        data: newPolicy
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.log('ERROR--------REACT_APP_PAYMENT_STATUS');
                    console.error(error);
                    _sendLog(['HPK-React - Policy Service - _requestPaymentStatus: ', error]);
                    reject(new Error(error));
                });
        })
    };
}
export function _logPaymentLifecycle(policy) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(server + process.env.REACT_APP_LOG_PAYMENT_LIFECYCLE, JSON.stringify(policy), {headers: {'Content-Type': 'application/json'}})
                .then(resp => {
                    console.log('_logPaymentLifecycle - success return');
                    resolve('success');
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Policy Service - _logPaymentLifecycle: ', error]);
                    reject(new Error(error));
                });
        })
    };
}

export function _issueAndReceiptPolicy(policy) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            let sessionId = _getSystem(getState()).sessionNameId;
            axios.post(server + process.env.REACT_APP_ISSUE_AND_RECEIPT_POLICY, JSON.stringify(policy), {headers: {'Content-Type': 'application/json'}})
                .then(resp => {
                    let policy = resp.data;
                    dispatch({
                        type: type.POLICY_STORE,
                        data: policy
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Policy Service - _issueAndReceiptPolicy: ', error]);
                    reject(error);
                });
        })
    };
}

export const _clearPolicy = () => {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_POLICY,
        })
    };
}

export const _searchUen = async (uenNo) => {
    return new Promise((resolve, reject) => {
        // Search UEN business name in the background and update
        if (!!uenNo) {
            let config =  {};
            if (process.env.REACT_APP_ENV  !== 'dev') {
                config = {
                    params: {
                        handlerEnabled: false
                    }
                }
            }
            const uenSearchURL = process.env.REACT_APP_UEN_SEARCH_URL + uenNo?.trim();
            return axios.get(uenSearchURL, config).then(resp => {
                if (resp?.data?.result?.records) {
                    console.log("[policy/service] searchUen success result");
                    resolve(resp?.data?.result?.records.filter((record) => {
                        if(record.uen.indexOf(uenNo) != -1){
                            return true;
                        }
                        return false;
                    }));
                } else {
                    console.error("[policy/service] searchUen failed no result");
                    resolve(undefined);
                }
            }).catch(error => {
                reject(error);
            });
        } else {
            reject([]);
        }
    })
}


const isPackegeQuote = (id, country) => {
  let packageKeys = [];
  if(country === 'SGP'){
    packageKeys = ANZSIC_SGPPACK_LIST.map(keys =>  keys.substring(4, keys.length) );
  } else {
    packageKeys = ANZSIC_HKGPACK_LIST.map(keys =>  keys.substring(4, keys.length) );
  }

  return packageKeys.indexOf(id) > -1;
}


export const _findQuote = (quoteId) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            axios.get(server + process.env.REACT_APP_FIND_QUOTE + quoteId)
                .then(resp => {
                    if (resp.data.errorMessage) {
                        console.error('Encountered error: ' + resp.data.errorMessage);
                        _sendLog(['PAN-React - Policy Service - _findQuote - Encountered error: ', resp.data.errorMessage])
                        reject(resp.data.errorMessage);
                    } else {
                        let policyObj = JSON.parse(resp.data.policy);
                        policyObj.savedForLaterToken = quoteId;

                        console.log(JSON.stringify(policyObj));
                        dispatch({
                            type: type.POLICY_STORE,
                            data: policyObj
                        });
                        resolve(resp.data);
                    }
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Policy Service - _findQuote: ', error]);
                    reject(error);
                });
        });
    };
};


export const _saveQuote = (data) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            // @ts-ignore
            axios.post(server + process.env.REACT_APP_SAVE_QUOTE,
                data,{headers: {'Content-Type': 'apply/json'}} )
                .then(resp => {
                    if (resp.data.success){
                        let policyObj = {
                          savedForLaterToken: resp.data.token
                        };
                        dispatch({
                            type: type.POLICY_STORE,
                            data: policyObj
                        });
                        resolve();
                    }
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Policy Service - _saveQuote: ', error]);
                    reject(error);
                });
        });
    };
};

export function _setPolicyCampaign(campaign) {
    return (dispatch, getState) => {
        dispatch({
            type: type.POLICY_SET_CAMPAIGN,
            campaign: campaign
        });
    }
}

export function _setPolicyPromo(promo) {
    return (dispatch, getState) => {
        dispatch({
            type: type.POLICY_SET_PROMO,
            promo: promo
        });
    }
}

export function _setPolicyAgentCode(agentNo) {
    return (dispatch, getState) => {
        dispatch({
            type: type.POLICY_SET_AGENTCODE,
            agentCode: agentNo
        });
    }
}

export const _getPolicy = property ( 'pl' );
export const _getSaveForLaterToken = property('pl.savedForLaterToken');
export const _getSystem = property ( 'sys' );


