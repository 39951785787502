import propTypes from 'prop-types';
import React from 'react';

import {QnectValidator} from "./qnect-validator";
import SelectField from "../../ui/components/form-fields/select-field";
import useConfig from "./qnect-config";
import {_getCountry, _getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectSelectField = React.forwardRef((
    {
        lang,
        country,
        name,
        field,
        formId,
        label,
        value,
        options,
        placeHolder,
        errorMessage,
        helperText,
        disabled,
        onChange,
        onBlur,
        reff,
        overrideStyle,
        ...rest
    },
    ref
) => {

    const cfg = useConfig(lang,country);
        return (
            <QnectValidator
                onChangeCallback="onChange"
                formId={formId}
                lang={lang}
                errorMessage={errorMessage}
                validators={cfg(field, "validators")}
            >
                <SelectField
                    label={label || cfg(field, "label")}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={helperText}
                    errorMessage={cfg(errorMessage)}
                    disabled={disabled}
                    placeholder={placeHolder}
                    multiline={rest.multiline}
                    overrideStyle={overrideStyle}
                    options={options ? options : cfg(field, "options")}
                    ref={reff}
                >
                </SelectField>
            </QnectValidator>
        )
});

QnectSelectField.propTypes = {
    field: propTypes.string,
    name: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    helperText: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    options: propTypes.arrayOf(propTypes.shape({
        value: propTypes.string,
        text: propTypes.string,
    })),
    disabled: propTypes.bool,
    onBlur: propTypes.func,
    reff: propTypes.string,
    overrideStyle: propTypes.string
};

QnectSelectField.displayName = 'QnectSelectField';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state)
    }
};

export default connect(mapStateToProps)(QnectSelectField);
