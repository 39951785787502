/* eslint-disable indent */
import styled from 'styled-components';
import property from 'lodash/property';

import typographyStyles from '../../../common/typography-styles';

/* eslint-disable indent */
const SelectCardLabel = styled.span`
    flex-grow: 1;
    transition: color 100ms linear;
    margin-left: 14px;

    ${typographyStyles({
        type: 'text',
        defaultSize: 'normal',
        defaultWeight: 'regular',
        defaultColor: 'tertiary90',
    })}

    input:checked + & {
        color: ${property('theme.color.primary')};
        font-weight: ${property('theme.typography.text.fontWeight.medium')};
    }

    input:disabled + & {
        color: ${property('theme.color.grey50')};
    }
`;
/* eslint-enable indent */

export default SelectCardLabel;
