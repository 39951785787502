import React, {useRef, useState} from "react";
import styled from 'styled-components'
import {ReactComponent as InfoIcon} from "../../assets/info_darkblue.svg";
import {ReactComponent as OutlineInfoIcon} from "../../assets/info_outline_darkblue.svg";
import InfoWhite from "../../assets/info_white.png"
import './qbe-balloon-tips.css'
import {_scrollToComponent} from "../../utils/utility";
import uniqueId from 'lodash/uniqueId'



const QBEBalloonTips = ({className, heading, children, isSmall, onClick, mode, ...rest}) => {
    let [open,setOpen] = useState(false)
    let locationRef = useRef(null)
    let [offsetTop, setOffsetTop] = useState(0)
    const [uniqueID,setUniqueID] = useState(uniqueId('balloontips-'))
    const mouseClick = () => {
        if(open) {
            setOpen(false)
        }else{
            // _scrollToComponent(locationRef)
            setOpen(true);
            const icon = document.querySelector(`#${uniqueID}.balloontips .balloontips-icon`)
            const top = icon.getBoundingClientRect().top+18+12
            setOffsetTop(top)

        }
    };
    const mouseEnter = ()=>{
        if(!open) {
            setOpen(true)
            const icon = document.querySelector(`#${uniqueID}.balloontips .balloontips-icon`)
            const top = icon.getBoundingClientRect().top+18+12
            setOffsetTop(top)
        }
    }
    const mouseLeave = ()=>{
        if(open) {
            setOpen(false)
        }
    }
    return (<div id={uniqueID}  className={"balloontips "+className + ((open?" Opened":""))}>
        {open&&<div onClick={mouseClick}  className="balloontips-backdrop">

        </div>}

        <div onClick={mouseClick} onMouseEnter={mouseEnter} ref={locationRef} className="balloontips-icon">
            {mode==='dark'?
                open? <InfoIcon className={"dark"}/>:<img className={"whiteOutlineInfo"} src={InfoWhite} alt=""/>
                :
                open?<InfoIcon/>:<OutlineInfoIcon/>
            }
        </div>
        {
            open&&<>
                {/*<div className="balloontips-tri"></div>*/}
                <div   className="balloontips-content" onMouseLeave={mouseLeave}>
                    {children}
                </div>
                <div style={{top:offsetTop}} className="balloontips-content-mobile">
                    {children}
                </div>
            </>
        }
    </div>)
}

export default QBEBalloonTips
