import React from 'react';
import {connect} from 'react-redux';

import {
    _getErrors,
    _getShowError,
    _clearError
} from '../store/system/service';
import styled from "styled-components";
import typographyStyles from "../ui/common/typography-styles";
import AlertModal from "../ui/components/modals/alert-modal";
import Sad from "../assets/sad.png";
import UnhappyIcon from '../assets/unhappy_error.svg'
const List = styled.dl`
    margin: auto;
    padding: 20px 0;
    text-align: center;
    max-width: 70%;
`;

const Message = styled.dd`
    margin: 0;
    padding: 0;

  color: #000000;
  font-family: Inter-SemiBold, sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;

    &:not(:last-of-type) {
        padding-bottom: 24px;
    }
`;

const Image = styled.img`
    width: 55px;
    margin-top: 20px;
`;

const ReactionText = styled.div`
  color: #003DA5;
  font-family: Stag-Medium, sans-serif;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 36px;
  margin-left: 25px;
  text-align: left;

`;

function ErrorModal({
    errors,
    showError,
    onClose
}) {
    return (
        <AlertModal
            isOpen={showError}
            onClose={onClose}>
            {errors && errors.length ?
                (
                    <React.Fragment>
                        <ReactionText>Oops!</ReactionText>

                        <Image src={UnhappyIcon} alt=""/>

                        <List>
                            {errors.map((message) => (
                                <Message>
                                    {message}
                                </Message>
                            ))}
                        </List>
                    </React.Fragment>
                ) : ''
            }
        </AlertModal>
    );
}

function mapStateToProps(state) {
    return {
        errors: _getErrors(state),
        showError: _getShowError(state),
    };
}

const mapDispatchToProps = {
    onClose: _clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
