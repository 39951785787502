import {_getCountry, _getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import React from "react";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicy, _storePolicy} from "../../store/policy/service";

import './price-summary.css'



const PriceSummaryMini = (props) => {
    const cfg = useConfig(props.lang);
    const isForHK = props.country === "HKG";

    const totalPremium = props.policy?.policyHome?.totalDueAmount;
    const tax =  props.policy?.policyHome?.totalIALevy;
    const taxLabel = "iaLevy.label";
    const premiumUnit = "VND";

    const getPremium = (totalPremium, tax)=>{
        let premium = 0;
        if (totalPremium && !isNaN(totalPremium)) {
            if (tax && !isNaN(tax)) {
                const temp = Number(totalPremium) - Number(tax);
                premium = temp.toFixed(2);
            } else {
                premium = totalPremium;
            }
        }
        return premium;
    }

    return (
        <>
            <div className="MobileSmallPriceSummary">
                <div className="MobilePriceWording">{cfg('ApplicantNeedToPay.label')}</div>
                <div className="MobilePriceAmount">
                    {premiumUnit}{totalPremium.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}
                </div>
            </div>
        </>

    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        country: _getCountry(state)
    }
}

export default connect(mapStateToProps, null)(PriceSummaryMini);
