import React from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import TenantIcon from "../../assets/tenant_new.svg";
import LandlordIcon from "../../assets/landlord_new.svg";
import OwnerOccupiedIcon from "../../assets/owner_occpied.svg";
import {_getPolicy, _storePolicy} from "../../store/policy/service";
import {_getCountry, _getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";

const InfoBox = styled.div`
  min-height: 75px;
  min-width: 665px;
  background-color: #ffffff;
  border: 1px solid #EDEDED;
  border-bottom: 5px solid #003DA5;
  box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
  margin-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  @media (max-width: 1440px) {
  width: 740px;
    margin-right: 38px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    width: calc(100vw - 40px);
    margin-left: 20px!important;
    margin-right: 20px!important;
    padding-top: 20px;
    padding-bottom: 8px;
    min-width: 0;
    
  }
  .InfoItems{
      display: flex;
      justify-content: space-between;
      min-height: 75px;
      //min-width: 40%;
      //width: auto;
      flex-grow: 1;
      margin-left: 15px;
      margin-right: 15px;
      align-self: stretch;
      flex-direction: row;
    @media (max-width: 1024px) {
        margin-left: 14px;
        flex-direction: column;
    }
    @media (max-width: 768px){
        width: auto;
        min-height: 75px;
        height: auto;
    }
  }
  .JobName{
    max-width: 180px;
  }
  .InfoVLine{
    box-sizing: border-box;
    min-height: 26px;
    height: 26px;
    width: 1px;
    border: 1px solid #4EC2F6;
    background: #4EC2F6;
    margin-top: auto;
    margin-bottom: auto;
      @media (max-width: 1024px) {
        border: none;
        background-color: transparent;
        height: 25px;
      }
  }
  .InfoHeading{
      color: #009AE4;
      font-family: Inter-Bold, sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
  }
  .InfoHeadingShort{
      width:54px
  }
  .InfoContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 2;
        min-height: 42px;
        color: #282B3E;
        font-family: Inter-Medium, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        flex-wrap: nowrap;
        @media(max-width: 768px){
           height: auto;
        }
  }
  .InfoContent.price{
        color: #282B3E;
        font-family: Inter, sans-serif;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 23px;
  }
  .InfoContent.price1{
        color: #282B3E;
        font-family: Arial, Noto, sans-serif;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
  }
  .info{
    width: 20px;
    height: 20px;
    margin-left: 25px;
  }
  .InfoTextContent{
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    @media (max-width: 768px){
          margin-right: 10px;
    }
  }
  .mobileNoPadding{
      @media (max-width: 1024px){
          padding-left: 0!important;
          margin-left: 0!important;
      }
  }
  
  .InfoTextContentColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 5px;
  }
  .FamilyMember{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
  }
  .InfoTextContent .InsurerLabel{
    font-weight: bold;
    margin-right: 10px;
    min-width: 75px;
  }
  .InfoTextContent .OccupationLabel {  
    //white-space: nowrap;
    //text-overflow: br;
    overflow-wrap: break-word;
    // width: auto;
    // max-width: 230px;
    align-items: center;
    @media (max-width: 768px){
        width: auto;
    }
  }
`;

const InsurerSummary = (props) => {
    const currentCountry = props.country;
    const occupancyType = props.policy?.policyHome?.occupancyType;
    const housingCode = props.policy?.policyHome?.housingCode;
    const grossFloorArea = props.policy?.policyHome?.grossFloorArea;
    const cfg = useConfig(props.lang);

    function isHKG() {
        return !!(currentCountry && String(currentCountry).toLocaleUpperCase() === "HKG");
    }

    function isTenant() {
        return !!(occupancyType && String(occupancyType) === "0001");
    }

    function isOwnerOccupied() {
        return !!(occupancyType && String(occupancyType) === "0004");
    }

    function isLandlord() {
        return !!(occupancyType && String(occupancyType) === "0003");
    }

    function getHousingType() {
        if (housingCode && String(housingCode) === "PU") {
            return cfg("ConfirmHousingNamePU.label");
        } else if (housingCode && String(housingCode) === "PR") {
            return cfg("ConfirmHousingNamePR.label");
        }
        return "";
    }

    function getGrossFloorAreaDesc() {
        if (grossFloorArea) {
            if (String(grossFloorArea) === "G0500") {
                return "0-500 sq ft";
            } else if (String(grossFloorArea) === "G0700") {
                return "501-700 sq ft";
            } else if (String(grossFloorArea) === "G1000") {
                return "701-1000 sq ft";
            } else if (String(grossFloorArea) === "G1500") {
                return "1001-1500 sq ft";
            } else if (String(grossFloorArea) === "G2000") {
                return "1501-2000 sq ft";
            } else if (String(grossFloorArea) === "G2500") {
                return "2001-2500 sq ft";
            } else if (String(grossFloorArea) === "G3000") {
                return "2501-3000 sq ft";
            } else if (String(grossFloorArea) === "G3001") {
                return ">3000 sq ft";
            }
        }
        return "";
    }

    const OccupancyTypeTenant = () => {
        return (
            <React.Fragment>
                <img src={TenantIcon} className={"Family"} alt="" />
                <div className="InfoTextContent InfoTextContentColumn">
                    <div className="InsurerLabel">{cfg("landingOccupancyTenant.label")}</div>
                </div>
            </React.Fragment>
        );
    }

    const OccupancyTypeOwnerOccupied = () => {
        return (
            <React.Fragment>
                <img src={OwnerOccupiedIcon} className={"Family"} alt="" />
                <div className="InfoTextContent InfoTextContentColumn">
                    <div className="InsurerLabel">{cfg("landingOccupancyOccupier.label")}</div>
                </div>
            </React.Fragment>
        );
    }

    const OccupancyTypeLandlord = () => {
        return (
            <React.Fragment>
                <img src={LandlordIcon} className={"Family"} alt="" />
                <div className="InfoTextContent InfoTextContentColumn">
                    <div className="InsurerLabel">{cfg("landingOccupancyLandlord.label")}</div>
                </div>
            </React.Fragment>
        );
    }

    const HousingType = () => {
        return (
            <React.Fragment>
                <div className="InfoHeading">{cfg('landingHousingTypeLabel.label')}</div>
                <div className="InfoContent">
                    <div className="InfoTextContent InfoTextContentColumn mobileNoPadding">
                        <div className="InsurerLabel"> {getHousingType()}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const GrossFloorArea = () => {
        return (
            <React.Fragment>
                <div className="InfoHeading">{cfg('landingGrossFloorAreaLabel.label')}</div>
                <div className="InfoContent">
                    <div className="InfoTextContent InfoTextContentColumn mobileNoPadding">
                        <div className="InsurerLabel"> {getGrossFloorAreaDesc()}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <InfoBox  className={"QuoteOccupancySummary"}>
            <div className="InfoItems">
                <div className="InfoHeading">{ cfg("occupancyType.label") }</div>
                <div className="InfoContent">
                    {
                        isTenant() && <OccupancyTypeTenant/> ||
                        isOwnerOccupied() && <OccupancyTypeOwnerOccupied/> ||
                        isLandlord() && <OccupancyTypeLandlord/>
                    }
                </div>
            </div>
            <div className="InfoVLine"></div>
            <div className="InfoItems">
                <HousingType/>
            </div>
        </InfoBox>
    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        country: _getCountry(state)
    }
}

const mapDispatchToProps = {
    showModals: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
    storePolicy: _storePolicy
};

export default connect(mapStateToProps, mapDispatchToProps)(InsurerSummary);
