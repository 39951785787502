import React from "react";
import styled from 'styled-components'

const COLORS = {
    "primary": {
        background: "#FF7769",
        font:"#FFFFFF"
    },
    "secondary": {
        background:"#8E90A2",
        font:"#FFFFFF"
    },
    "info": {background:"#FFFFFF",
        font:"#282B3E"}
}

const ButtonStyle = styled.div`
    background-color: ${({color}) => COLORS[color]!=null?COLORS[color].background:COLORS.primary.background};
    width: ${({isSmall})=>isSmall!=null?188:287}px;
    height: ${({isSmall})=>isSmall!=null?48:70}px;
    
    
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${({color}) => COLORS[color]!=null?COLORS[color].font:COLORS.primary.font};
    font-family: Arial,"Noto-Bold";
    font-size: ${({isSmall})=>isSmall!=null?16:20}px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    cursor:pointer;
    :hover{
      //background-color: ;
    }
`;


const Button = ({className, color, children, isSmall, onClick, ...rest}) => {
    return (<ButtonStyle onClick={onClick} color={color} isSmall={isSmall} className={className}>
        {children}
    </ButtonStyle>)
}

export default Button
