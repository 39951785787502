import propTypes from 'prop-types';
import React from 'react';

import {QnectValidator} from "./qnect-validator";
import DateField from "../../ui/components/form-fields/date-field";
import useConfig from "./qnect-config";
import {_getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectDateField = React.forwardRef((
    {
        lang,
        field,
        name,
        formId,
        label,
        value,
        minDate,
        maxDate,
        helperText,
        disableToolbar,
        errorMessage,
        onChange,
        onBlur,
        fixedLabel,
        initialFocusedDate,
        ...rest
    },
    ref
) => {

    const cfg = useConfig(lang);

    return (
        <QnectValidator
            formId={formId}
            lang={lang}
            onChangeCallback="onChange"
            errorMessage={errorMessage}
            validators={cfg(field, "validators")}
        >
            <DateField
                name={name}
                label={label || cfg(field, "label")}
                disableToolbar={disableToolbar}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                errorMessage={cfg(errorMessage)}
                fixedLabel={fixedLabel}
                initialFocusedDate={initialFocusedDate}
            >
            </DateField>
        </QnectValidator>
    )
});

QnectDateField.propTypes = {
    field: propTypes.string,
    name: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    minDate: propTypes.object,
    maxDate: propTypes.object,
    value: propTypes.object,
    helperText: propTypes.string,
    disableToolbar: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
};

QnectDateField.displayName = 'QnectDateField';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state)
    }
};

export default connect(mapStateToProps)(QnectDateField);
