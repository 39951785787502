import React, {useReducer, useState} from 'react';
import propTypes from 'prop-types';
import {FormControl, FormHelperText, Input, InputLabel, Select} from '@material-ui/core';

import styled from 'styled-components';
import classnames from 'classnames';
import property from 'lodash/property';

import IconChevronDown from '../icons/legacy/chevron-down';
import Calendar from "../../../assets/calendar.png";
import {KeyboardDatePicker} from "@material-ui/pickers";

const FormControlStyled = styled(FormControl)`
    &.MuiFormControl-root {
        background-color: ${property('theme.color.grey30')};
        width: 100%;
        span::before{
            content:'${({placeholder})=>placeholder}';
            color: #000000;
            font-family: Inter-Medium, sans-serif;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            //margin-left: -12px;
            display: inline-block;
        }
        .MuiFormLabel-root {
            margin-top: -3px;
            padding: 0 0 0 12px;
            color: #0A1F44;
            font-family: Inter-Medium, sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
        }
        .MuiFormLabel-filled{
          color: ${property('theme.color.primary')};
        }
        
        .MuiInputLabel-shrink {
            transform: translate(3px, 12px) scale(0.75);
        }

        .Mui-focused {
            color: ${property('theme.color.primary')};
        }

        .MuiInputBase-root {
            margin-top: 0;
            font-family: ${property('theme.typography.text.fontFamily')};
        }

        .MuiInput-underline {
            border: ${property('theme.color.tertiary90')};
        }

        .MuiInput-underline::after {
            border-bottom-color: ${property('theme.color.secondary60')};
        }

        .MuiSelect-root {
            border: ${property('theme.color.tertiary90')};
            padding: 27px 16px 10px 12px;
            text-align: left;
            color: ${property('theme.color.black')};
        }
    }
`;

const StyledIconChevronDown = styled(IconChevronDown)`
    margin-right: 12px;
`;

const StyledFormHelperText = styled(FormHelperText)`
    &.MuiFormHelperText-root {
        margin-top: 0;
        padding: 8px 0 0 13px;
        color: ${property('theme.color.tertiary90')};
        font-family: ${property('theme.typography.text.fontFamily')};
    }

    &.MuiFormHelperText-root.Mui-error {
        color: ${property('theme.color.error')};
    }
`;

const IconComponent = (props) => (<StyledIconChevronDown color="black" size="24px" {...props} />);

const DropDown = React.forwardRef((
    {
        className,
        name,
        label,
        isEmptyLabel = false,
        value: defaultValue,
        onChange,
        onBlur,
        isDisabled,
        isError,
        helperText,
        isRequired,
        children,
        placeholder,
        fixedLabel,
        overrideStyle,
        ...rest
    },
    ref
) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        let dropDownLabel = event.nativeEvent.target.innerText;
        if (typeof (onChange) === 'function') {
            onChange(event.target.value, dropDownLabel, setSelectedValue);
        }
    };
    const MenuProps = {
        PaperProps: {
            style: {
                backgroundColor: overrideStyle ? '#28B5F5' : '#F7F8F9',
                boxShadow:' 2px 2px 8px 0 rgba(20,40,75,0.2)',
                borderRadius:0,
                marginTop:"60px",
                opacity: 1,
                maxHeight: "300px",
                ...overrideStyle && {fontFamily: "Inter, sans-serif"},
                ...overrideStyle && {fontSize: "16px"},
                ...overrideStyle && {letterSpacing: 0},
                ...overrideStyle && {lineHeight: "24px"},
                ...overrideStyle && {color: "#0A1F44"}
            },
        },
    };
    return (
        <div className={classnames('DropDown', className)}>
            <FormControlStyled
                disabled={isDisabled}
                error={isError}
                required={isRequired}
                placeholder={placeholder}
            >
                {!isEmptyLabel && (
                    <InputLabel className={"DropDownLabel"} htmlFor={name}>
                        {label}
                    </InputLabel>
                )}

                <Select
                    {...rest}
                    ref={ref}
                    input={<Input name={name} id={name} />}
                    value={selectedValue}
                    onChange={handleChange}
                    displayEmpty={isEmptyLabel}
                    IconComponent={IconComponent}
                    MenuProps={MenuProps}
                    onBlur={onBlur}
                >
                    {children}
                </Select>
            </FormControlStyled>

            {!!helperText ? (
                <StyledFormHelperText error={isError}>
                    {helperText}
                </StyledFormHelperText>
            ):<StyledFormHelperText error={isError}>
                &nbsp;
            </StyledFormHelperText> }
        </div>
    );
});

DropDown.propTypes = {
    className: propTypes.string,
    name: propTypes.string,
    label: propTypes.string,
    isEmptyLabel: propTypes.bool,
    onChange: propTypes.func,
    onBlur: propTypes.func,
    value: propTypes.string,
    isDisabled: propTypes.bool,
    isError: propTypes.bool,
    helperText: propTypes.string,
    isRequired: propTypes.bool,
    children: propTypes.node,
    overrideStyle: propTypes.string
};

DropDown.displayName = 'DropDown';

export default DropDown;
