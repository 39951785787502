import React, {useEffect} from 'react';
import Payment from "../components/payment/payment";
import TagManager from "react-gtm-module";
import {_getPolicy} from "../store/policy/service";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {getOptionalCoverForGA} from "../utils/utility";

const PaymentPage = (props) => {

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'pageStep': 'Pay', //replace occupier with actual owner type,
          'vPath': '/pay', //replace occupier with actual owner type,
          'event': 'vpageview',
          'ecommerce': {
              'checkout': {
                'actionField': {'step': 5,},	//Step5-Payment.
              }
          }
        });
    }, []);

    return (
        <Payment>
        </Payment>
    )
}

const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
    }
}

export default withRouter(connect(mapStateToProps, null)(PaymentPage));
