import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import './assets/fonts/Stag-Medium.otf';
import './index.css';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import {_sendLog} from './utils/utility'
import createAppStore from './store/app-store';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react';
import TagManager from 'react-gtm-module'
import App from "./App";

// Wraps App in a fresh Redux store
function ReactReduxApp() {
    if(process.env.REACT_APP_ENV && process.env.REACT_APP_ENV  !== 'dev'){
        let gtmID = 'GTM-W3WBWPZ'; //default is the SGP GA container id.

        const hostname = window.location.hostname;
        const countryParamValue = getQueryParamValueByName (window.location.search, "country");
        console.log('ga init country='+countryParamValue);
        console.log('ga init gtmID='+gtmID)
        const tagManagerArgs = {
            gtmId: gtmID
        }
        TagManager.initialize(tagManagerArgs);
        console.log('ga init start')
    }else{
        console.log('is dev')
    }

    const pStore = createAppStore();
    const persistor = persistStore(pStore);
    generateUuid();

    return (
        <Provider store={pStore}>
            <PersistGate loading={null} persistor={persistor}>
               <App/>
            </PersistGate>
        </Provider>
    );
}

function getQueryParamValueByName (urlStr, paramName)  {
    const nameStr = paramName.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + nameStr + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(urlStr);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    const decodedResult = decodeURIComponent(results[2].replace(/\+/g, ' '));
    if (decodedResult) {
        return decodedResult;
    }
    return '';
}

function generateUuid() {
    if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV  !== 'dev') {
        const sessionId = uuid();
        console.log('Generated UUID: ' + sessionId);
        _sendLog('SME-React - generateUuid - Generated UUID: ' + sessionId, 'info')
        axios.interceptors.request.use((request = {}) => {
            if (request?.params?.hasOwnProperty('handlerEnabled') && !request?.params?.handlerEnabled) {
                delete request.params.handlerEnabled;
                return request;
            }
            request.headers['Session-Name'] = sessionId;
            return request;
        });
        axios.interceptors.response.use(null, (error) => {
            const requestConfigData = error.config.data;
            const requestConfigHeaders = error.config.headers;
            const requestConfigUrl = error.config.url;
            const requestResponseUrl = error.request.responseURL;
            const requestResponseStatus = error.request.status;

            _sendLog(['Home React b2c-home-react-vnm - ' + requestConfigUrl,
                ' SessionID: ' + sessionId,
                ' Request Data: ' + requestConfigData,
                ' Request Header: ' +  JSON.stringify(requestConfigHeaders),
                ' Response URL: ' + requestResponseUrl,
                ' Status: ' + requestResponseStatus
            ]);
            return Promise.reject(error);
        });
    }
}

ReactDOM.render(
    (
        <ReactReduxApp/>
    ),
    document.getElementById('root')
);
