import React, {useEffect, useRef, useState} from 'react';

import styled from 'styled-components';
import Header from "../template/header";
import AppVersion from "../version";
import propTypes from 'prop-types';
import Stepper from "../../ui/components/progress/stepper";
import Footer from "../footer/footer";
import InsurerSummary from "./insurer-summary";
import PriceSummary from "./price-summary";
import CoverageSummary from "./coverage-summary";
import PersonSummary from "./person-summary";
import useConfig from "../qnect-ui/qnect-config";
import {_getCountry, _getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import {_scrollToTop} from "../../utils/utility";
import './template.css'
import MobileStepper from "../ui/mobile-stepper";
import PriceSummaryMini from "./price-summary-mini";

const TemplateStyle = styled.div`
    height: 100vh;
    width: 100vw;
    //width: 1440px;
    margin: auto;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: left;
    font-family: Arial, Noto, sans-serif;
    ${({quote})=>{
        // return quote?'background: red;':''
        return quote?'background: #FAFAFA;':''
}}
    ${({hideScrollBar})=>hideScrollBar?' &::-webkit-scrollbar{\n    display: none;\n    position: fixed;\n    right: 0;\n    }':''}
    //transition-duration: 0.3s;
    .TemplateBodyContainer{
        margin: 0 auto;
        box-sizing: border-box;
        padding: 0 45px;
        width: 1440px;
    }
    @media (max-width: 1440px) {
        .TemplateBodyContainer{
            margin: 0 auto;
            width: 100%;
            
        }
    }
`;

const BodyGroupStyle = styled.div`
    background-color: #FAFAFA;
    //padding-top: 95px;
        @media (max-width: 1024px) {
            //padding-top: 56px;
        }
`;

const BodyGroupBackgroundStyle = styled.div`
    top: 0px;
    height: 98%;
    width: 600px;
    position: absolute;
    left: 0px;
    box-shadow: rgba(20, 40, 75, 0.2) 4px 4px 12px 0px;
    margin: 0px;

`;


const BodyStyle = styled.div`
    height: auto;
    width: auto;
    font-family:  Arial, Noto, sans-serif;
    font-size: 32.0px;
    text-align: left;
    padding-left: 62px;
    padding-right: 56px;
    @media(max-width: 1440px){
        padding-left: 36px;
        padding-right: 27px;
    }
    @media(max-width: 1024px){
        padding-left: 0;
        padding-right: 0;
    }

`;

const StyledStepper = styled(Stepper)`
  margin-left: -56px;  
`;
const TopGroup = styled.div`
  display: flex;
  flex-direction: column;

`;
const TopRow = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: auto;
justify-content: space-between;
  @media (max-width: 1440px) {
  justify-content: flex-end;
  }
    @media (max-width: 1024px) {
flex-direction: column;

  }
`;




const Template = ({title, step, info, raw, children, hideTopGroup,mobileBack, mobileSpace, quote, hideScrollBar,...props}) => {
    useEffect( _scrollToTop,[])
    let [scrollbar, setScrollbar] = useState(0)
    const summaryEleRef = useRef(null)
    const [summaryBottomOffset,setSummaryBottomOffset] = useState(200)
    const [showMiniPrice,setShowMiniPrice] = useState(false)
    useEffect(()=>{
        const box = document.querySelector('#Template');
        console.log(box)
        const scrollbarWidth = box.offsetWidth - box .clientWidth;
        console.log("scrollbarWidth: " + scrollbarWidth)
        setScrollbar(scrollbarWidth)
        const htmlELe = document.querySelector("html")
        if(htmlELe.getAttribute("lang")!==props.lang){
            htmlELe.setAttribute("lang",props.lang)
        }

    },[])
    useEffect(()=>{
        if(String(info).toLowerCase() ==="price"&&summaryEleRef!==null){
            const page = document.querySelector('#Template')
            setSummaryBottomOffset(summaryEleRef.current.getBoundingClientRect().bottom)
            // const priceSummaryEle =

            function onScroll() {
                // console.log('scrolling',summaryEleRef.current.getBoundingClientRect().bottom)
                setSummaryBottomOffset(summaryEleRef.current.getBoundingClientRect().bottom)
                if(summaryBottomOffset<110){
                    // console.log('show component')
                    setShowMiniPrice(true)
                }else{
                    setShowMiniPrice(false)
                }
            }
            return page.addEventListener("scroll", onScroll);
        }
    },[summaryBottomOffset])
    const cfg = useConfig(props.lang,  props.country );
    const STEPS = [
        {"id": "0", "name": "Welcome"},
        {"id": "1", "name": cfg('stepQuote.label')},
        {"id": "2", "name": cfg('stepApply.label')},
        {"id": "3", "name": cfg('stepConfirm.label')},
        {"id": "4", "name": cfg('stepInsured.label')},
        {"id": "5", "name": cfg('stepInsured.label')}
        ]

    const getBanner = (type) => {
        switch(type) {
            case 'price':
                return <PriceSummary/>
            case 'date':
                return <CoverageSummary/>
            default:
                return <InsurerSummary/>
        }

    }

    return (
        <TemplateStyle hideScrollBar={hideScrollBar}  scrollbar={scrollbar} quote={quote} id="Template" className={"HKG-template"}>
            <Header scrollbar={scrollbar} mobileBack={mobileBack}></Header>
            {
                raw != null ?
                    <>
                        {children}
                    </> :
                    quote != null?
                        <React.Fragment>
                        <div className="TopTitleGroup">
                            <div className={"TopTitle"}>
                                {cfg('headerTitle.label')}&nbsp;
                                <span className={"ProductName"}>{cfg('headerProductHomePrestige.label')}</span>
                            </div>
                        </div>
                        <div className={"MobileStepperContainer"}>
                            {
                                step != null ? <StyledStepper
                                    className={"NormalStepper"}
                                    steps={STEPS}
                                    activeStep={step}/> : null
                            }
                        </div>
                        <div className={"MobileInfoContainer"}>
                            {
                                info && getBanner(String(info).toLowerCase())
                            }
                        </div>
                        <div className="QuoteHeaderTemplateBodyContainer">
                            <BodyGroupStyle>
                                <TopGroup className={"GlobalTop QuoteGlobalTop"}>
                                    <TopRow>
                                        {
                                            step != null ? <StyledStepper
                                                className={"NormalStepper"}
                                                steps={STEPS}
                                                activeStep={step}/> : null
                                        }
                                        {
                                            info && getBanner(String(info).toLowerCase())
                                        }
                                    </TopRow>
                                </TopGroup>
                            </BodyGroupStyle>
                        </div>
                            <div className={"PageHeading TCard LargeH tertiary90"}>
                                {/*<span>{cfg('applyHeading.label')}</span>*/}
                            </div>
                            {children}
                        </React.Fragment>:
                        <React.Fragment>
                            <div className="TopTitleGroup">
                                <div className={"TopTitle"}>
                                    {cfg('headerTitle.label')}&nbsp;
                                    <span className={"ProductName"}>{cfg('headerProduct.label')}</span>
                                </div>
                            </div>
                            <div className={"MobileStepperContainer"}>
                                {
                                    step != null ? <StyledStepper
                                        className={"NormalStepper"}
                                        steps={STEPS}
                                        activeStep={step}/> : null
                                }
                            </div>
                            {/*{*/}
                            {/*    (history.location.pathname === '/applicant'||history.location.pathname === '/apply') &&*/}
                            {/*    <div className="MobilePayAmount">*/}
                            {/*        <span className={"Label"}>{cfg("ApplyMobilePay.label")}</span>*/}
                            {/*        <span className={"Amount"}>{premiumUnit}{totalPremium}</span>*/}
                            {/*    </div>*/}
                            {/*}*/}
                            <div ref={summaryEleRef} className={"MobileInfoContainer"}>
                                {
                                    info && getBanner(String(info).toLowerCase())
                                }
                            </div>
                            {
                                String(info).toLowerCase() ==="price" && showMiniPrice && <PriceSummaryMini/>
                            }
                            <BodyGroupStyle raw={raw}>
                                <div className="TemplateBodyContainer">
                                    <div className={"StepperInfoContainer"}>
                                        {
                                            step != null ? <StyledStepper
                                                className={"NormalStepper"}
                                                steps={STEPS}
                                                activeStep={step}/> : null
                                        }
                                        {
                                            info && getBanner(String(info).toLowerCase())
                                        }
                                    </div>
                                    <div className={"Body"}>
                                        {children}
                                    </div>
                                </div>
                            </BodyGroupStyle>
                        </React.Fragment>

            }
            <Footer mobileSpace={mobileSpace}/>
            {/*<AppVersion/>*/}
        </TemplateStyle>
    );
}
const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state)
    };
}

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Template);
// export default Template;

Template.propTypes = {
    title: propTypes.string.isRequired,
    step: propTypes.string,
    children: propTypes.node
}
