import React from 'react';
import {connect} from 'react-redux';
import {CarouselProvider, Slider, Slide, DotGroup} from 'pure-react-carousel';
import {withRouter} from "react-router";
import 'pure-react-carousel/dist/react-carousel.es.css';
import "./carousel.css"
import {CarouselContent} from "./carousel-template";

const BannerCarousel = ({bannerList, isPlaying}) => {
    let bannerSize = bannerList.length;
    const slides = (banners)=>banners.map((item, i) => {
        return(
        <Slide index={i} key={item.id}>
                    <CarouselContent
                        carouselItemIndex={i}
                        carouselItemTitle={item.title}
                        carouselItemListItem1={item.content}
                        carouselItemListItem2={item.url}
                    />
        </Slide>);
    });

    return (
    <CarouselProvider
        visibleSlides={1}
        naturalSlideWidth={100}
        naturalSlideHeight={150}
        totalSlides={bannerSize}
        currentSlide={0}
        infinite={true}
        interval={5000}
        isPlaying={true}
        step={1}
        dragStep={1}
        isIntrinsicHeight={true}
        id="banner-carousel"
    >
        <div className="sliderContainerScroller">
            <Slider children={bannerSize}>
                {slides(bannerList)}
            </Slider>
        </div>
        <div className="SliderButtonContainer">
            <DotGroup
                disableActiveDots={false}
            >
            </DotGroup>
        </div>

    </CarouselProvider>

    )

};

const mapStateToProps = (state) => {
    return {
    }
};

export default withRouter(connect(mapStateToProps)(BannerCarousel));
