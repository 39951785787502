import React, {useEffect, useState} from 'react';

import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import {ReactComponent as QBELogo} from '../../assets/QBELogo.svg';
import AEGIS_T from '../../assets/AEGIS_T.png'
import {
    _getSystemAvailable,
    _getInitialURL,
    _getLang,
    _hideModal,
    _setLang,
    _showModal,
    _getValidAgent,
    _getCountry,
    _getBanners
} from "../../store/system/service";
import {connect} from "react-redux";
import {ReactComponent as AgentBlue} from '../../assets/agentblue.svg';
import {ReactComponent as ShareIcon} from "../../assets/shareblue.svg";
import {ReactComponent as Menu} from "../../assets/Side_Menu.svg";
import AgentDetail from "./agent-detail";
import {_getAgent} from "../../store/agent/service";
import {_clearPolicy, _getPolicy} from "../../store/policy/service";
import {_getCampaign} from "../../store/campaign/service";
import {ReactComponent as Cross} from "../../assets/Cross.svg";
import {ReactComponent as UnhappyError} from "../../assets/unhappyerror.svg";
import {ReactComponent as Back} from "../../assets/keyboard_arrow_down_24px.svg";
import useConfig from "../qnect-ui/qnect-config";
import TagManager from "react-gtm-module";
import {mapBr} from '../../utils/utility';
import './header.css'
import Carousel from "../carousel/carousel";


const HeaderStyle = styled.div`
    width: ${({scrollbar})=>scrollbar>0?`calc(100vw - ${scrollbar}px)`:'100vw'};
    //height: auto;
    min-height: 86px;
    position: fixed;
    z-index:10;
    //background-color: #FFFFFF;
    @media (max-width: 1024px) {
        //height: auto;
        min-height: 56px;
        width: 100vw;
    }

    .HeaderContainer{
        margin: auto auto;
        width: 1440px;
        height: 86px;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        &::-webkit-scrollbar {
          display: none;
        }
        
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        @media (max-width: 1440px) {
            width: calc(100vw - 24px);
            margin: 0 auto;
            overflow-x: hidden;
            padding-left: 24px;
        }
        @media (max-width: 1024px) {
            height: 56px;
            margin: 0;
            width: 100vw;
            padding-left: 20px;
            padding-right: 20px;
            //width: calc(100vw - 30px);
        }
    }
    .HeaderLogoGroup{
      margin-left: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
        @media (max-width: 1440px) {
            margin-left: 0px;
            //height: 34px;
            transform-origin: left;
            transform: scale(0.8947368421);

        }
        @media (max-width: 1024px) {
            margin-left: 0px;
            //height: 34px;
            transform-origin: left;
            //width: 205px;
        }
    }
    .agentboxContainer{
        background-color: #FFF;
        border-radius: 8px;
        box-sizing: border-box;
        height: 74px;
        width: auto;
        border: 1px solid #4EC2F6;
        position: relative!important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition-duration: 0.3s;
    }
  .agentboxContainer::after{
      transform: rotate(45deg) translateY(-50%);
      border-radius: 0 7px 0 0;
      display:inline-block;
      height: 15px;
      width:15px;
      border-top: 1px solid #4EC2F6;
      border-right: 1px solid #4EC2F6;
      content: ' ';
      position:absolute;
      background-color: #fff;
      right: -3px;
      top: 45%;
      transition-duration: 0.3s;
  }
    .HeaderRightGroup{
      margin-right: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
        @media (max-width: 1440px) {
          margin-right: 24px;
        }
        @media (max-width: 1024px) {
          display: none;
        }
    }
    .HeaderRightGroup .InfoGroup{
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .HeaderRightGroup .NavIcon {
        margin-left: 5px;
    }
    .HeaderRightGroup .NavIcon.Opened {
        transform: rotate(180deg);
    }

    .HeaderMenuIcon {
        position:absolute;
        margin-left: 30px;
        margin-top: -60px;
    }

    .bubble {
        position: relative;
        margin-left: -2px;
        margin-bottom: 30px;
        right: 94%;
    

      /*left*/
        &.left:after {
            transform: rotate(-135deg) translateY(-60%);
            border-radius: 0 7px 0 0;
            display:inline-block;
            height: 15px;
            width:15px;
            border-top: 1px solid #4EC2F6;
            border-right: 1px solid #4EC2F6;
            content: ' ';
            position:absolute;
            background-color: #fff;
            right: -3px;
            top: 45%;
            transition-duration: 0.3s;
        }

        &.left:before {
            background-color: #FFF;
            border-radius: 8px;
            box-sizing: border-box;
            // height: 120px;
            // min-width: 100px;
            width: auto;
            border: 1px solid #4EC2F6;
            position: relative!important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            transition-duration: 0.3s;
        }
    }

    .IconPadding {
        margin-right: 10px;
    }
    
    .HeaderRightGroup>.Icon{
        width: 30px;
        height: 30px;
        overflow: hidden;
        margin: 0 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .HeaderRightGroup>.Icon.Home{
        width: 24px;
        height: 24px;
        overflow: hidden;
        margin: 0 12px;
        fill: #282B3E;
        cursor: pointer;
        @media (max-width: 1440px) {
          margin: 0 2px 0 12px;
        }
        @media(max-width: 1024px){
            margin: 0 2px 0 0px;
            fill: #00A758!important;
        }
    }    
    .HeaderRightGroup>.Icon.Individual{
        width: 24px;
        height: 24px;
        overflow: hidden;
        margin: 0 12px;
        margin-left: 20px;
        @media (max-width: 1440px) {
          margin: 0 12px 0 2px;
        }
    }
    .HeaderRightGroup>.Icon.Home:hover{
        fill: #00A758;
    }
    .MobileRightGroup{

        display: none;
        @media(max-width: 1024px){
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    .MobileRightHeader .Icon.Home{
        @media(max-width: 1024px){
            fill: #00A758!important;
        }
    }  
    .MobileRightHeader{
        display: none;
        @media(max-width: 1024px){
            background-color: #FFF;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
           &.Open{
            background-color: rgba(255,255,255,0.95);
            z-index: 5;
            height: 65px;
            opacity: 1;
            transition-duration: 0.5s;
           }
           &.Close{
            height: 0;
            //max-height: 0;
            opacity: 0;
            display: none;
            overflow: hidden;
            transition-duration: 0.5s;
          }
        }


    }
    .MobileRightGroup .Icon{
        display: flex;
        flex-direction: row; 
        margin-right: 28px;
        cursor: pointer;
    }
    .MobileRightGroup .MenuIcon{
        display: flex;
        flex-direction: row;
        width: 22px; 
        height: 22px; 
        cursor: pointer;
    }
    
    .LangControl{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 32px;
        font-family: arial, sans-serif;
        font-size: 14px;
        cursor: pointer;
    }
    .overlay {
        height: 100%;
        width: 100%;
        display: none;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        display: block;
        height: 0;
        opacity: 0.95;
        top: 80px
      }
      .overlay-content {
        position: relative;
        // top: 25%;
        //width: 100%;
        width: ${({scrollbar})=>scrollbar>0?`calc(100vw - ${scrollbar}px)`:'100vw'};
        height: 179px;
        text-align: center;
        background: #f8f8ff;
        opacity: 2;
        display: flex;
        align-items: center;
        // margin-top: 30px;
      }
      // .overlay a {
      //   padding: 8px;
      //   text-decoration: none;
      //   font-size: 14px;
      //   color: #003DA5;
      //   font-weight: bold;
      //   display: block;
      //   transition: 0.3s;
      //   margin-left: 50px;
      //   margin-top: 50px;
      //   text-align: center;
      // }
      @media screen and (max-height: 450px) {
        .overlay a {font-size: 20px}
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
      li {
        float: left;
      }
      li a {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
      }
      a.MenuLink {
        margin-left: -15px;
      }
      a.MenuLink_HK {
        font-size: 14px;
      }


    .InfoGroupOverride {
        max-width: 250px;
        height: auto;
        margin-top: 10px;
        margin-left: 30px;
        padding-right:2px;
    }
    div.AgentInfoGroupOverride {
        min-width: 100px;
        height: 100px;
        border: 1px solid #003DA5;
    }

    div.AgentInfoBorderless {
        border: none;
    }


    .AgentContactInfoGroup{
        height: 43px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .AgentContactInfo{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        color: #282B3E;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 16px;
    }
    .AgentContactInfo .Icon{
      width: 19px;
      overflow: hidden;
    }
    .AgentContactInfo .Value{
      margin-left: 10px;
        color: #282B3E!important; /* blue colors for links too */
        text-decoration: none!important; /* no underline */
    }
    .MobileAgentBox a{
            color: #282B3E!important; /* blue colors for links too */
        text-decoration: none!important; /* no underline */
    }

    .MobileAgentBox{
        display: none;
        @media(max-width: 1024px){
            box-sizing: border-box;
            &.Open{
              min-height: 76px;
              height: auto;
              opacity: 1;
              transition-duration: .5s;
              background-color: #FFFFFF;
            }
            &.Close{
              height: 0;
              opacity: 0;
              overflow: hidden;
              transition-duration: .5s;
            }
            font-family: Arial, Noto, sans-serif;
            box-sizing: border-box;
            font-weight: bold;
            color: #282B3E;
            width: 100vw;
            //max-width:375px;
            position: absolute;
            //top: 56px;
            right: 0;
            display: flex;
            flex-direction: column;
            padding-left: 20px;
            padding-top: 10px;
            padding-right: 20px;
            padding-bottom: 8px;
            // background-color: #EDEDED;
            & .Tri{
                height: 20px;
                width: 20px;
                background-color: transparent;
                position: absolute;
                right: 57px;
                top: -20px;
                box-sizing: border-box;
                // border-bottom: 10px solid #EDEDED;
                border-bottom: 10px solid #00CCEF;
                border-top: 10px solid transparent;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
            }
            & .Dismiss{
                position: absolute;
                right: 29px;
                top: 22px;
                height: 12px;
                width: 12px;
            }
            & .AgentName{
                font-size: 16px;
                letter-spacing: 0;
                line-height: 18px;
                margin-bottom: 10px;
            }
            & .AgentEmailGroup{
                font-size: 14px;
                letter-spacing: 0;
                line-height: 16px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 8px;
                & img{
                    height: 14px;
                    width: 19px;
                    margin-right: 10px;
                }
            }
            & .AgentPhoneRegNoGroup{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                & .AgentPhoneGroup{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-grow: 1;
                    & img{
                        height: 19px;
                        width: 19px;
                        margin-right: 10px;
                    }
                }
                & .AgentRegNoGroup{
                    flex-grow: 1;
                    font-size: 10px;
                    font-weight: normal;
                    letter-spacing: 0;
                    line-height: 11px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-grow: 1;
                }
    
            }

    }
}
`;
const StyledOverlay = styled.div`
  @media(max-width: 1024px){
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    position: fixed;
    opacity: 0.3;
    background-color: #000000;
    z-index: 4;
    display: ${({showOverlay})=>showOverlay?"block":"none"};
  }
`;
const MobileBuffer = styled.div`
display: none;
@media(max-width: 1024px){
  height: 92px;
  width: 10px;
  display: ${({showAgent})=>showAgent?"block":"none"};
}

`;

const Header = ({scrollbar,mobileBack,...props}) => {
    const routeMap = {
        "/quote": "/",
        "/applicant": "/quote",
        "/apply": "/applicant",
        "/confirm": "/applicant",
        "/payment": "/confirm",
        "/finish": "/payment"
    }
    const [showNav, setShowNav] = useState(false);
    const [, setHeaderItemSelected] = useState("HOME_PRESTIGE");

    const history = useHistory();

    const setLanguage = (lang) => {
        props.setLang(lang);
    }
    const cfg = useConfig(props.lang);
    const backToHome = () => {
        const url = props.initialURL;
        console.log("url-----------------------"+url);
        props.clearPolicy();
        if(url){
            history.push('/');
            history.push(url);
        }else {
            history.push('/');
        }
    }

    const openSharer =() =>{
        const contextPath = (process.env.REACT_APP_ENV  === 'dev') ? '' : process.env.PUBLIC_URL;
        let entryUrlToShare = window.location.origin + contextPath + props.initialURL;
        entryUrlToShare += "?utm_source=sharebutton";
        entryUrlToShare = encodeURIComponent(entryUrlToShare);
        window.open('https://www.facebook.com/sharer/sharer.php?u='+entryUrlToShare);
    }

    const [mobileToggle,setMobileToggle] = useState(false)
    const [buttonHovering,setButtonHovering] = useState(false)
    const [mobileAgent,setMobileAgent] = useState(history.location.pathname === '/')
    const clickMenu = ()=>{
        setMobileToggle(!mobileToggle);
    };

    const toggleHeader = ()=> {
        setShowNav(!showNav);
        // restore default on toggle
        setHeaderItemSelected("HOME_PRESTIGE");
    }
    const jumpProduct = (productRoute, newTab)=>{
        const contextPath = (process.env.REACT_APP_ENV  === 'dev') ? '' : productRoute;
        let initialURLwithoutCampaign = props.initialURL.replace(/\/campaign\/[^\/]*/, "");
        if(newTab){
            var win = window.open(window.location.origin + contextPath + initialURLwithoutCampaign, '_blank');
            win.focus();
            return
        }
        window.location.href = window.location.origin + contextPath + props.initialURL
    }

    const headerMenuItemClick = (e, data)=> {
        console.log(e, data);
        setHeaderItemSelected(data);
    }

    const [scrollTop, setScrollTop] = useState(0);
    const [scrollingOffset, setScrollingOffset] = useState(0);
    const[bannerList, setBannerList] = useState(null);
    const activeRoute = window.location.pathname;
    let routesList = ["/home/quote", "/home/applicant", "/home/apply", "/home/confirm", "/home/payment", "/home/finish"];
    let isLandingPage = !routesList.includes(activeRoute);

    const updateOffset = (update)=>{
        console.log("update: ",window.pageYOffset,update)
        if(update)
            setScrollingOffset(window.pageYOffset)
    }
    useEffect(() => {
        function onScroll() {
            // let currentPosition = TableBody.current.scrollTop; // or use document.documentElement.scrollTop;
            let currentPosition = document.querySelector('#Template').scrollTop; // or use document.documentElement.scrollTop;
            // console.log(currentPosition,scrollingOffset, currentPosition)
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
            if(mobileAgent){

                if(Math.abs(currentPosition-scrollingOffset)>150){
                    setMobileAgent(false)
                }
            }

            if(isLandingPage) {
                if(bannerList) {
                    if(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                        if(currentPosition > 30) {
                            document.getElementById("QuoteHeader").style.position = "fixed";
                            document.getElementById("QuoteHeader").style.top = "0";
                        } else {
                            document.getElementById("QuoteHeader").style.top = "initial";
                        }
                    } else {
                       if(currentPosition > 80){
                            document.getElementById("QuoteHeader").style.position = "fixed";
                            document.getElementById("QuoteHeader").style.top = "0";
                        }else{
                            document.getElementById("QuoteHeader").style.top = "initial";
                        }
                    }
                }
            }
        }

        // TableBody.current.addEventListener("scroll", onScroll);
        // return TableBody.current.addEventListener("scroll", onScroll);
        return document.querySelector('#Template').addEventListener("scroll", onScroll);
    }, [scrollTop]);

    useEffect(() => {
       props.getBanners(props?.country, props?.policy?.productType).then(resp => {
            if (Object.keys(resp.data).length) {
                setBannerList(resp.data);
            }
       });
    },[]);

    const SystemUnavailableError = () => {
        return (
            <div className="TopErrorContainer">
                <UnhappyError className="ErrorImg"  alt=""/>
                <span className="ErrorText">{cfg("landingSystemUnavailableError.message")}</span>
            </div>
        )
    }

    const InvalidUrlError = () => {
        return (
            <div className="TopErrorContainer">
                <UnhappyError className="ErrorImg"  alt=""/>
                <span className="InvalidUrlErrorText">{mapBr(cfg("landingInvalidUrlError.message"))}</span>
            </div>
        )
        return "";
    }
    const goBackToPreviousPage = () => {
        // route sequence from start till end. Change routeMap if sequence changes in future
        // /->/quote->/applicant->/apply->/confirm->/payment->/finish
        const activeRoute = window.location.pathname;
        if (activeRoute === '/quote') {
            backToHome();
        } else {
            history.push(routeMap[activeRoute]);
        }
    }
    return (<>
            <StyledOverlay showOverlay={mobileToggle}  onClick={clickMenu}></StyledOverlay>
            { (bannerList && isLandingPage) && <Carousel bannerList={bannerList}/>}
            <HeaderStyle className={"Header "+(((window.innerWidth<=1024&&mobileToggle)||(window.innerWidth<=1024&&mobileAgent)||scrollTop>5||buttonHovering?"White":((history.location.pathname !== '/'&&!history.location.pathname.includes('/partner/'))?'White':"Transparent")))} id="QuoteHeader" scrollbar={scrollbar}>
                { !props.validAgent && <InvalidUrlError/> }
                { props.validAgent && !props.systemAvailable && <SystemUnavailableError /> }
            <div className="HeaderContainer">

                <div className="HeaderLeftGroup">
                    {
                        (history.location.pathname !== '/'&&history.location.pathname !== '/finish'&&!history.location.pathname.includes('/partner/'))&& <Back className={"MobileBack"} onClick={mobileBack}></Back>
                    }
                    <div className="HeaderLogoGroup HeaderBtn" onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}>

                        <QBELogo className={
                            history.location.pathname.includes('/partner/') && history.location.pathname.includes('/aegis')?'QBE_aegis':''
                        }
                                 onClick={backToHome}/>
                        {
                            history.location.pathname.includes('/partner/')&&history.location.pathname.includes('/aegis')&&
                                <>
                                    <div className="Co-brandSeparator"></div>
                                    {/*<img className={"AegisIcon"} src={AEGIS} alt=""/>*/}
                                    <img className={"AegisIcon"} src={AEGIS_T} alt=""/>
                                </>
                        }
                    </div>
                </div>
                <div className="HeaderRightGroup">
                    { props.validAgent && <AgentDetail className={"AgentDetail"}></AgentDetail>}
                    <div className={"Icon HeaderBtn"}  onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}>
                        <AgentBlue className="Icon Individual" onClick={()=>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'agent-contactcard',
                                }
                            });
                        }}/>
                    </div>
                    <div className={"Icon HeaderBtn"}  onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}>
                        <ShareIcon className="Icon Home" onClick={()=>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'Share',
                                }
                            });
                            return openSharer();
                        }}/>
                    </div>
                    <div id="languageSelector" className={"HeaderBtn"}   onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}>
                        <span className={"Lang"+(props.lang=='en'? " Picked ActiveLang":"")} onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'EN',
                                }
                            });
                            return setLanguage('en');
                        }}>
                            EN
                        </span>
                        <div className="LangVLine"></div>
                        <span className={"Lang"+(props.lang=='vn'? " Picked ActiveLang":"")} onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'vn',
                                }
                            });
                            return setLanguage('vn');
                        }}>
                            VN
                        </span>
                    </div>
                    {/* Language dropdown logic ends here */}
                </div>
                <div className={"MobileRightGroup"}>
                    <AgentBlue className="Icon Individual HeaderBtn" onClick={()=>{setMobileAgent(true);updateOffset(true)}} onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}/>
                    <ShareIcon className="Icon Home HeaderBtn" onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)} onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'Share',
                            }
                        });
                        return openSharer();
                    }}/>
                    {
                        !mobileToggle?<Menu className={"MenuIcon HeaderBtn"} onClick={clickMenu} onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}/>
                        :<Cross className={"MenuIcon HeaderBtn"} onClick={clickMenu} onMouseEnter={()=>setButtonHovering(true)} onMouseLeave={()=>setButtonHovering(false)}/>
                    }
                </div>
            </div>
                <div className={"MobileRightHeader" + (mobileToggle ? " Open" : " Close")}>
                    <div className="MobileLookingTitle">{cfg('headerDropdownLookingForTitle.label')}</div>
                    <div className="MobileLookingLanguage">
                        <div id="languageSelector" className={"HeaderBtn"} onMouseEnter={() => setButtonHovering(true)}
                             onMouseLeave={() => setButtonHovering(false)}>
                            <span className={"Lang" + (props.lang == 'en' ? " Picked ActiveLang" : "")} onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'EN',
                                    }
                                });
                                return setLanguage('en');
                            }}>
                                EN
                            </span>
                            <div className="LangVLine"></div>
                            <span className={"Lang" + (props.lang == 'vn' ? " Picked ActiveLang" : "")} onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'vn',
                                    }
                                });
                                return setLanguage('vn');
                            }}>
                                VN
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"MobileAgentBox"+(mobileAgent?" Open":" Close")}>
                    { props.validAgent && <AgentDetail className={"AgentDetail"}/>}
                <Cross className="Dismiss" onClick={()=>{setMobileAgent(false);}}/>
            </div>
        </HeaderStyle>
        </>

    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        agent: _getAgent(state),
        campaign: _getCampaign(state),
        initialURL: _getInitialURL(state),
        systemAvailable: _getSystemAvailable(state),
        validAgent: _getValidAgent(state),
        country: _getCountry(state),
        policy: _getPolicy(state),
    };
}

const mapDispatchToProps = {
    clearPolicy:_clearPolicy,
    showModals: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
    getBanners: _getBanners
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
