import * as type from './types';
import property from 'lodash/property';
import { _sendLog } from '../../utils/utility';
import axios from "axios";

export function _setSite(site) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_SITE,
            site: site
        });
    }
}

export function _setCountry(country) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_COUNTRY,
            country: country
        });
    }
}

export function _setLang(lang) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_LANG,
            lang: lang?.toLowerCase() === "tc" ? "zh" : lang?.toLowerCase()
        });
    }
}

export function _setStep(step) {
    return (dispatch, getState) => {
        const prevStep  = _getStep(getState())
        if (step > prevStep) {
            dispatch({
                type: type.SET_STEP,
                step: step
            });
        }
    }
}

export function _setCampaign(campaign) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_CAMPAIGN,
            campaign: campaign
        });
    }
}

export function _setCampaignImage(campaignImage) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_CAMPAIGN_IMAGE,
            campaignImage: campaignImage
        });
    }
}

export function _setAgentNo(agentNo) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_AGENT_NO,
            agentNo: agentNo
        });
    }
}

export function _showError(errors) {
    console.log(errors);
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_ERROR,
            errors: errors
        });
    }
}

export function _clearError() {
    return (dispatch, getState) => {
        dispatch({
            type: type.CLEAR_ERROR
        });
    }
}


export function _showModal(data) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_MODAL,
            data: data
        });
    }
}

export function _hideModal() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_MODAL,
        });
    }
}
export function _showSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.SHOW_SPINNER
        });
    }
}

export function _hideSpinner() {
    return (dispatch, getState) => {
        dispatch({
            type: type.HIDE_SPINNER
        });
    }
}

export function _setInitialURL(initialURL) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_INITIAL_URL,
            initialURL: initialURL
        });
    }
}

export function _setValidAgent(validAgent) {
    return (dispatch, getState) => {
        dispatch({
            type: type.SET_VALID_AGENT,
            validAgent: validAgent
        });
    }
}
export const _fetchOccupations = (country) => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');

            axios.get(server + '/b2c-server/rest/occupations/' + country)
                .then(resp => {
                    dispatch({
                        type: type.SET_OCCUPATIONS,
                        occupations: resp.data
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    _sendLog(['PAN-React - System Service - _fetchOccupations: ', error]);
                    reject(error);
                });
        });
    }
}

export const _checkSystemAvailable = (country) => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            const httpOptions = { headers: {'Content-Type': 'application/json'} };

            axios.post(server + process.env.REACT_APP_CHECK_SYSTEM_STATUS_URL, {countryCode: country}, httpOptions)
                .then(resp => {
                    const systemAvailable = resp.data?.toString() === "true";
                    dispatch({
                        type: type.SET_SYSTEM_AVAILABLE,
                        systemAvailable: systemAvailable
                    });
                    resolve(systemAvailable);
                })
                .catch(error => {
                    _sendLog(['PAN-React - System Service - _checkSystemAvailable: ', error]);
                    reject(error);
                });
        });
    }
}


export const _getBanners = (country, product) => {
    return async (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let sessionId = _getSystem(getState()).sessionNameId;
            let config = {
                params: {
                    country: country,
                    product: product
                },
                headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId},
            }
            axios.get(server + process.env.REACT_APP_GET_BANNERS_URL, config)
                .then(resp => {
                    if(resp) {
                       resolve(resp);
                    }
                })
                .catch(error => {
                    _sendLog(['PAN-React - System Service - _getBanners: ', error]);
                    reject(error);
                });
        });
    }
}

export const _getSite = property('sys.site');

export const _getStep = property('sys.step');

export const _getLang = property('sys.lang');

export const _getCountry = property('sys.country');

export const _getErrors = property('sys.errors');

export const _getShowError = property('sys.showError');

export const _getShowModal = property('sys.showModal');

export const _getModal = property('sys.modal');

export const _getShowSpinner = property('sys.spinner');

export const _getCampaign = property('sys.campaign');

export const _getCampaignImage = property('sys.campaignImage');

export const _getAgentNo = property('sys.agentNo');

export const _getOccupations = property('sys.occupations');

export const _getInitialURL = property('sys.initialURL');

export const _getSystemAvailable = property('sys.systemAvailable');

export const _getValidAgent = property('sys.validAgent');

export const _getSystem = property('sys');
