import React from 'react';
import config from "../../config/config"

const useConfig = (lang,country) => {
    const language = lang ? lang : 'en';

    const cfg = (fieldPath, item) => {

        function extractValue(configItem, key, language, country) {
            const selectedCountry =  country ? String(country).toLocaleLowerCase() :"";
            const selectedLang =  language ? String(language).toLocaleLowerCase() : "";

            let value = "";
            if (!configItem) {
                return value;
            }
            if (selectedLang && selectedLang !== "en") {
                value = configItem[key + "_" + selectedLang + "_" + selectedCountry];
                if (!value) {
                    value = configItem[key + "_" + selectedLang];
                }
            }
            else {
                value = configItem[key + "_"  + selectedCountry];
            }
            if (!value){
                value = configItem[key] ? configItem[key] : "";
            }
            return value;
        }

        function getValueFromConfig(config, fieldPath,item, language, country){
            if (fieldPath) {
                if (item) {   // for qnect-validator, cfg(field, "validators")
                    const pathItems = fieldPath.split(".");
                    let configItem = config;
                    pathItems.map(item => (
                        configItem = configItem ? configItem[item] : configItem
                    ));
                    return extractValue(configItem, item, language, country);
                }
                else {
                    if (fieldPath.indexOf(".") === -1) {
                        return extractValue(config, fieldPath, language, country);
                    } else {
                        const path = fieldPath.substring(0, fieldPath.lastIndexOf("."));
                        const key = fieldPath.substring(fieldPath.lastIndexOf(".") + 1);
                        const pathItems = path.split(".");
                        let configItem = config;
                        pathItems.map(item => (
                            configItem = configItem ? configItem[item] : configItem
                        ));
                        return extractValue(configItem, key, language, country);
                    }
                }
            }
        }

        let value = "";
        if (config) {
            value = getValueFromConfig(config,fieldPath, item, language, country);
        }
        return value ? value : fieldPath;
    }

    return cfg;
}

export default useConfig;
