import * as type from './types';
import { updateObject, _sendLog } from "../../utils/utility";
import {v4 as uuid} from 'uuid';

const INITIAL_STATE = {
    site: '',
    lang: 'vn',
    country: 'VNM',
    agentNo: '',
    campaign: '',
    step: 1,
    spinner: false,
    showModal: false,
    modal: {},
    showError: false,
    errors: [],
    campaignImage:'',
    occupations: {},
    sessionNameId: uuid(),
    initialURL: '',
    systemAvailable: true,
    validAgent: true
};

const setSite = (state, action) => {
    return updateObject(
        state,
        { site: action.site }
    )
}

const setStep = (state, action) => {
    return updateObject(
        state,
        { step: action.step }
    )
}

const setLang = (state, action) => {
    document.querySelector('html').setAttribute('lang',action.lang)
    return updateObject(
        state,
        { lang: action.lang }
    )
}

const setCampaign = (state, action) => {
    return updateObject(
        state,
        { campaign: action.campaign }
    )
}

const setCampaignImage = (state, action) => {
    return updateObject(
        state,
        { campaignImage: action.campaignImage }
    )
}

const setAgentNo = (state, action) => {
    return updateObject(
        state,
        { agentNo: action.agentNo }
    )
}

const setOccupations = (state, action) => {
    return updateObject(
        state,
        { occupations: action.occupations }
    )
}

const setCountry = (state, action) => {
    return updateObject(
        state,
        { country: action.country }
    )
}

const setInitialURL = (state, action) => {
    return updateObject(
        state,
        { initialURL: action.initialURL }
    )
}

const setSystemAvailable = (state, action) => {
    return updateObject(
        state,
        { systemAvailable: action.systemAvailable }
    )
}

const setValidAgent = (state, action) => {
    return updateObject(
        state,
        { validAgent: action.validAgent }
    )
}

const showModalError = (state, action) => {
    console.error('showModalError called');
    console.error(action);
    _sendLog(['System Reducer - showModalError called', ...action.errors]);
    return updateObject(
        state,
        {
            showError: true,
            errors: action.errors
        }
    )
}

const clearModalError = (state, action) => {
    return updateObject(
        state,
        {
            showError: false
        }
    )
}

const showModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: true,
            modal: action.data
        }
    )
}

const hideModal = (state, action) => {
    return updateObject(
        state,
        {
            showModal: false
        }
    )
}

const showSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: true
        }
    )
}

const hideSpinner = (state, action) => {
    return updateObject(
        state,
        {
            spinner: false
        }
    )
}
export function systemReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case type.SET_SITE:
            return setSite(state, action);
        case type.SET_STEP:
            return setStep(state, action);
        case type.SET_LANG:
            return setLang(state, action);
        case type.SET_COUNTRY:
            return setCountry(state, action);
        case type.SET_CAMPAIGN:
            return setCampaign(state, action);
        case type.SET_CAMPAIGN_IMAGE:
            return setCampaignImage(state, action);
        case type.SET_AGENT_NO:
            return setAgentNo(state, action);
        case type.SET_OCCUPATIONS:
            return setOccupations(state, action);
        case type.SHOW_ERROR:
            return showModalError(state, action);
        case type.CLEAR_ERROR:
            return clearModalError(state, action);
        case type.SHOW_MODAL:
            return showModal(state, action);
        case type.HIDE_MODAL:
            return hideModal(state, action);
        case type.SHOW_SPINNER:
            return showSpinner(state, action);
        case type.HIDE_SPINNER:
            return hideSpinner(state, action);
        case type.SET_INITIAL_URL:
            return setInitialURL(state, action);
        case type.SET_SYSTEM_AVAILABLE:
            return setSystemAvailable(state, action);
        case type.SET_VALID_AGENT:
            return setValidAgent(state, action);
        default:
            return state;
    }
}

