import PolicyWordingEn from '../assets/QBE Home Plus Protection Policy Wording-01102021-E.pdf'
import ProductBrochureEn from '../assets/QBE_Home_Plus_Protection_Brochure.pdf'
import PolicyWordingVn from '../assets/QUY TAC BAO HIEM NHA TOAN DIEN QBE-01102021.pdf'
import ProductBrochureVn from '../assets/TAI_LIEU_GIOI_THIEU_SAN_PHAM.pdf'
import axios from "axios";
const AssetType = {
    POLICY_WORDING_en:'POLICY_WORDING_en',
    PRODUCT_BROCHURE_en:'PRODUCT_BROCHURE_en',
    PRODUCT_BROCHURE_vn:'PRODUCT_BROCHURE_vn',
    POLICY_WORDING_vn:'POLICY_WORDING_vn',
}
const getAsset = (type) =>{
    console.log("getAsset---"+type);
    const Asset = {
        file: null,
        filename: null
    }
    switch (type) {
        case 'AssetType.POLICY_WORDING_en':
            Asset.file = PolicyWordingEn
            Asset.filename = PolicyWordingEn.toString().split('/').reverse()[0]
            break
        case 'AssetType.PRODUCT_BROCHURE_en':
            Asset.file = ProductBrochureEn
            Asset.filename = ProductBrochureEn.toString().split('/').reverse()[0]
            break
        case 'AssetType.POLICY_WORDING_vn':
            Asset.file = PolicyWordingVn
            Asset.filename = PolicyWordingVn.toString().split('/').reverse()[0]
            break
        case 'AssetType.PRODUCT_BROCHURE_vn':
            Asset.file = ProductBrochureVn
            Asset.filename = ProductBrochureVn.toString().split('/').reverse()[0]
            break
        default:
            return null
    }
    return Asset
}

const downloadPDF = (asset,filename=null)=>{
    if(!asset)
        return console.log('Asset is not define')
    filename = filename||asset.filename
    axios.get(asset.file, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then (response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        if(window.navigator&& window.navigator.msSaveBlob){
            window.navigator.msSaveBlob(blob,filename)
        }else{
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
            link.remove()
        }
    })
}

export {AssetType,getAsset,downloadPDF}
