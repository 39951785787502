import {createStore, applyMiddleware, compose} from 'redux';
import logger from 'redux-logger';     // redux-logger is a middleware that lets you log every state change
import thunk from 'redux-thunk';       // redux-thunk is a middleware that lets you dispatch async actions
import reducer from './reducer';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, reducer)
const middleware = applyMiddleware(thunk, logger);

export default function createAppStore() {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(persistedReducer, composeEnhancers(middleware));
}

