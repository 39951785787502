import propTypes from 'prop-types';
import React from 'react';

import config from "../../config/config"
import TextField from "../../ui/components/form-fields/text-field";
import {QnectValidator} from "./qnect-validator";
import useConfig from "./qnect-config";
import {_getCountry, _getLang} from "../../store/system/service";
import {connect} from "react-redux";

const QnectTextField = React.forwardRef((
    {
        lang,
        country,
        name,
        field,
        formId,
        label,
        value,
        placeholder,
        errorMessage,
        helperText,
        maxLength,
        onChange,
        onBlur,
        startAdornment,
        endAdornment,
        ...rest
    },
    ref
) => {

        const cfg = useConfig(lang, country);

        return (
            <QnectValidator
                formId={formId}
                lang={lang}
                onChangeCallback="onChange"
                onBlurCallback="onBlur"
                errorMessage={errorMessage}
                validators={cfg(field, "validators")}
            >
                <TextField
                    label={label || cfg(field, "label")}
                    value={value}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={helperText}
                    errorMessage={cfg(errorMessage)}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    {...rest}
                >
                </TextField>
            </QnectValidator>
        )
});

QnectTextField.propTypes = {
    field: propTypes.string,
    name: propTypes.string,
    formId: propTypes.string,
    label: propTypes.string,
    value: propTypes.string,
    className: propTypes.string,
    maxLength: propTypes.number,
    helperText: propTypes.string,
    placeholder: propTypes.string,
    errorMessage: propTypes.string,
    onChange: propTypes.func,
    onBlur: propTypes.func,
};

QnectTextField.displayName = 'QnectTextField';

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state)
    }
};

export default connect(mapStateToProps)(QnectTextField);
