import React from 'react';
export const PRODUCT_FEATURES = {
    "HOME_CONTENT": {
        "label": "HOME_CONTENT.label",
        "balloon": "HOME_CONTENT_BALLOON.label",
        "child": {
            "LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS": {
                "label": "HOME_CONTENT__LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS.label",
                "privateHouse": {
                    "silver": "300,000,000",
                    "gold": "500,000,000",
                    "platinum": "1,000,000,000"
                },
                "apartment": {
                    "silver": "300,000,000",
                    "gold": "500,000,000",
                    "platinum": "1,000,000,000"
                }
            },
            "HOME_CONTENTS_IN_THE_OPEN_AIR": {
                "label": "HOME_CONTENT__HOME_CONTENTS_IN_THE_OPEN_AIR.label",
                "privateHouse": {
                    "silver": "5%",
                    "gold": "5%",
                    "platinum": "5%"
                },
                "apartment": {
                    "silver": "5%",
                    "gold": "5%",
                    "platinum": "5%"
                }
            },
            "EMERGENCY_STORAGE": {
                "label": "HOME_CONTENT__EMERGENCY_STORAGE.label",
                "privateHouse": {
                    "silver": "threeMonthCoverage.label",
                    "gold": "threeMonthCoverage.label",
                    "platinum": "threeMonthCoverage.label"
                },
                "apartment": {
                    "silver": "threeMonthCoverage.label",
                    "gold": "threeMonthCoverage.label",
                    "platinum": "threeMonthCoverage.label"
                }
            },
            "OCCUPIER_ADDITIONAL_BENEFITS": {
                "label": "HOME_CONTENT__OCCUPIER_ADDITIONAL_BENEFITS.label",
                "privateHouse": {
                    "silver": "",
                    "gold": "",
                    "platinum": ""
                },
                "apartment": {
                    "silver": "",
                    "gold": "",
                    "platinum": ""
                }
            },
            "HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX": {
                "label": "HOME_CONTENT__HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX.label",
                "privateHouse": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "HOME_CONTENTS_TEMPORARY_REMOVED": {
                "label": "HOME_CONTENT__HOME_CONTENTS_TEMPORARY_REMOVED.label",
                "privateHouse": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "HOME_CONTENTS_DURING_TRANSIT": {
                "label": "HOME_CONTENT__HOME_CONTENTS_DURING_TRANSIT.label",
                "privateHouse": {
                    "silver": "45,000,000",
                    "gold": "75,000,000",
                    "platinum": "120,000,000"
                },
                "apartment": {
                    "silver": "45,000,000",
                    "gold": "75,000,000",
                    "platinum": "120,000,000"
                }
            },
            "HOME_CONTENTS_AT_A_NEW_SITUATION": {
                "label": "HOME_CONTENT__HOME_CONTENTS_AT_A_NEW_SITUATION.label",
                "privateHouse": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "HOME_CONTENTS_AT_YOUR_WORKPLACE": {
                "label": "HOME_CONTENT__HOME_CONTENTS_AT_YOUR_WORKPLACE.label",
                "privateHouse": {
                    "silver": "5,000,000",
                    "gold": "7,000,000",
                    "platinum": "10,000,000"
                },
                "apartment": {
                    "silver": "5,000,000",
                    "gold": "7,000,000",
                    "platinum": "10,000,000"
                }
            },
            "COMPENSATION_FOR_MEDICAL": {
                "label": "HOME_CONTENT__COMPENSATION_FOR_MEDICAL.label",
                "privateHouse": {
                    "silver": "10,000,000",
                    "gold": "20,000,000",
                    "platinum": "30,000,000"
                },
                "apartment": {
                    "silver": "10,000,000",
                    "gold": "20,000,000",
                    "platinum": "30,000,000"
                }
            },
            "DOMESTIC_HELPERS_PERSONAL": {
                "label": "HOME_CONTENT__DOMESTIC_HELPERS_PERSONAL.label",
                "privateHouse": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                },
                "apartment": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                }
            },
            "FUNERAL_EXPENSES": {
                "label": "HOME_CONTENT__FUNERAL_EXPENSES.label",
                "privateHouse": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                },
                "apartment": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                }
            },
            "GUESTS_AND_VISITORS": {
                "label": "HOME_CONTENT__GUESTS_AND_VISITORS.label",
                "privateHouse": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                },
                "apartment": {
                    "silver": "20,000,000",
                    "gold": "30,000,000",
                    "platinum": "30,000,000"
                }
            },
            "KEYS_LOCK_REPLACEMENT": {
                "label": "HOME_CONTENT__KEYS_LOCK_REPLACEMENT.label",
                "privateHouse": {
                    "silver": "10,000,000",
                    "gold": "20,000,000",
                    "platinum": "20,000,000"
                },
                "apartment": {
                    "silver": "10,000,000",
                    "gold": "20,000,000",
                    "platinum": "20,000,000"
                }
            },
            "TEMPORARY_ACCOMMODATION": {
                "label": "HOME_CONTENT__TEMPORARY_ACCOMMODATION.label",
                "privateHouse": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            }
        }
    },
    "BUILDINGS": {
        "label": "BUILDINGS.label",
        "balloon": "BUILDINGS_BALLOON.label",
        "child": {
            "LOSS_OR_DAMAGE_TO_BUILD": {
                "label": "BUILDINGS__LOSS_OR_DAMAGE_TO_BUILD.label",
                "privateHouse": {
                    "silver": "1,000,000,000",
                    "gold": "1,500,000,000",
                    "platinum": "3,000,000,000"
                },
                "apartment": {
                    "silver": "300,000,000",
                    "gold": "500,000,000",
                    "platinum": "1,000,000,000"
                }
            },
            "CONTRACT_WORK_ALTERATION": {
                "label": "BUILDINGS__CONTRACT_WORK_ALTERATION.label",
                "privateHouse": {
                    "silver": "100,000,000",
                    "gold": "150,000,000",
                    "platinum": "300,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "ELECTRIC_GAS_WATER": {
                "label": "BUILDINGS__ELECTRIC_GAS_WATER.label",
                "privateHouse": {
                    "silver": "5,000,000",
                    "gold": "7,500,000",
                    "platinum": "10,000,000"
                },
                "apartment": {
                    "silver": "3,000,000",
                    "gold": "5,000,000",
                    "platinum": "5,000,000"
                }
            },
            "FIRE_EXTINGUISHING": {
                "label": "BUILDINGS__FIRE_EXTINGUISHING.label",
                "privateHouse": {
                    "silver": "100,000,000",
                    "gold": "150,000,000",
                    "platinum": "300,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "MODIFICATION_COST": {
                "label": "BUILDINGS__MODIFICATION_COST.label",
                "privateHouse": {
                    "silver": "100,000,000",
                    "gold": "150,000,000",
                    "platinum": "300,000,000"
                },
                "apartment": {
                    "silver": "30,000,000",
                    "gold": "50,000,000",
                    "platinum": "100,000,000"
                }
            },
            "RENT_LOSS_UP_TO_6_MONTHS": {
                "label": "BUILDINGS__RENT_LOSS_UP_TO_6_MONTHS.label",
                "privateHouse": {
                    "silver": "75,000,000",
                    "gold": "150,000,000",
                    "platinum": "300,000,000"
                },
                "apartment": {
                    "silver": "75,000,000",
                    "gold": "100,000,000",
                    "platinum": "250,000,000"
                }
            },
            "LANDLORD_ADDITIONAL_BENEFITS": {
                "label": "BUILDINGS__LANDLORD_ADDITIONAL_BENEFITS.label",
                "privateHouse": {
                    "silver": "",
                    "gold": "",
                    "platinum": ""
                },
                "apartment": {
                    "silver": "",
                    "gold": "",
                    "platinum": ""
                }
            },
        }
    },
    "LIABILITY_TO_OTHERS": {
        "label": "LIABILITY_TO_OTHERS.label",
        "balloon": "LIABILITY_TO_OTHERS_BALLOON.label",
        "child": {
            "LIMIT_OF_LIABILITY_ANY_ONE_PERIOD": {
                "raw": true,
                "label": "LIABILITY_TO_OTHERS__LIMIT_OF_LIABILITY_ANY_ONE_PERIOD.label",
                "privateHouse": {
                    "silver": "2,000,000,000",
                    "gold": "3,000,000,000",
                    "platinum": "5,000,000,000"
                },
                "apartment": {
                    "silver": "1,000,000,000",
                    "gold": "2,000,000,000",
                    "platinum": "3,000,000,000"
                }
            }
        }
    }
}
