import React, {useEffect} from 'react';
import {connect} from 'react-redux';

import "./finish.css";
import {withRouter} from "react-router";
import Button from "../ui/button";
import Template from "../template";
import {_getCountry, _getInitialURL, _getLang, _hideModal, _showError, _showModal} from "../../store/system/service";

import Smile from "../../assets/smile.png";
import Sad from "../../assets/sad.png";
import {ReactComponent as Star} from "../../assets/Star.svg";
import styled from 'styled-components';
import TextField from "../../ui/components/form-fields/text-field";
import {_clearPolicy, _getPolicy} from "../../store/policy/service";
import useConfig from "../qnect-ui/qnect-config";
import {_getAgent, _resetAgent} from "../../store/agent/service";
import {_getCampaign, _resetCampaign} from "../../store/campaign/service";

import TagManager from "react-gtm-module";
import {ReactComponent as HeadingIcon1} from "../../assets/step4icon.svg";
import QnectButton from "../qnect-ui/qnect-button";
import {AssetType, downloadPDF, getAsset} from "../../utils/pdf-asset";
import {ReactComponent as Coupon} from "../../assets/couponwhite.svg"


const StyledStar = styled(Star)`

`;


const RatingTitle = (
    <>
        <div className="RatingTitleImgGroup">
            <img className="RatingTitleImg" src={Smile} alt=""/>
            <img className="RatingTitleImg" src={Sad} alt=""/>
        </div>
        How would you rate this online insurance <br/>
        enrollment experience?
    </>
)

const getAssetTypeDocDownload = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            return downloadPDF(getAsset(assetTypePB));
            break
        case 'PolicyWording':
            return downloadPDF(getAsset(assetTypePW));
            break
        default:
            return downloadPDF(getAsset(assetTypePB));
    }
}
const getAssetTypeDocOpen = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            window.open(getAsset(assetTypePB).file,  '_blank');
            break
        case 'PolicyWording':
            window.open(getAsset(assetTypePW).file,  '_blank');
            break
        default:
            window.open(getAsset(assetTypePB).file,  '_blank');
    }
}

const RatingPopup = (props)=>{
    props.showModal(
        {
            title: RatingTitle,
            className:'RatingContainer small',
            content:
                <div className="Rating">
                    <div className="RatingText">Bad</div>
                    <div className="StarGroup">
                        {/*the order is reversed*/}
                        <Star className="five"/>
                        <Star className="four"/>
                        <Star className="three"/>
                        <Star className="two"/>
                        <Star className="one Picked"/>
                    </div>
                    <div className="WhatWrong">
                        What went wrong?
                    </div>
                    <div className="WrongGroup">
                        <div className="Problem">Information Unclear</div>
                        <div className="Problem">Poor Interface</div>
                        <div className="Problem">Complicated Process</div>
                        <div className="Problem Large">Too Much Information Required</div>
                        <div className="Problem Small Picked">Others</div>
                    </div>
                    <div className="InputGroup">
                        <div className="Label">Please tell us more</div>
                        <TextField></TextField>
                    </div>
                    <div className="ButtonGroup">
                        <Button isSmall>Submit</Button>
                    </div>
                </div>,
        });
}
const RatingConfirmPopup = (props)=>{
    props.showModal(
        {
            title: '',
            className:'RatingConfirmContainer small',
            content:
                <div className="RatingConfirm">
                    <img className="RatingImg" src={Smile} alt=""/>
                    <div className="Title">Thank you for your feedback.</div>
                    <div className="Content">We strive to improve your <br/>experience online.</div>
                    <div className="ButtonGroup">
                        <Button isSmall>CONTINUE</Button>
                    </div>
                </div>,
        });
}

function getAgentEmail(agentEmail){
    if(agentEmail){
        return agentEmail.toUpperCase();
    }
}

const Finish = (props) => {

    const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
    const continueToBackPage = () => {
        const url = props.initialURL;
        props.clearPolicy();
        props.resetAgent();
        props.resetCampaign();

        if(url){
            props.history.push('/');
            props.history.push(url);
        }else {
            props.history.push('/');
        }

    }
    const currentCountry = props.country;
    const continueToPAPage = () => {
        const initUrl = props.initialURL;
    }
    const cfg = useConfig(props.lang, props.country);

    useEffect(() => {
        localStorage.clear();
        window._sva = {};
        window._sva.traits = {
            "policyNumber": props.policy?.policyNumber,
            "language": props.lang,
            "product" : `HKHome_${props.policy?.productType}`,
            "product_country" : props.country,
        };
        let s = document.createElement('script');
        s.src = '//survey.survicate.com/workspaces/2976a64dece8b7c78d2b796a4cc21460/web_surveys.js';
        s.async = true;
        let e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
    }, []);

    return (
        <Template step={5}>
            {/*<div onClick={()=>RatingPopup(props)}>Rating Group</div>*/}
            {/*<div onClick={()=>RatingConfirmPopup(props)}>Rating Confirm Group</div>*/}
            <div className="finish">
                <HeadingIcon1 id="HeadingIcon1"/>
                <div className="FinishHeading">
                    {cfg('finishSuccessfulTitle1.label')}<br/>
                    {cfg('finishSuccessfulTitle2.label')}
                </div>
                <div className="FinishSubHeading NormalSH tertiary90 InterSB center">
                    {cfg('finishSuccessfulSubTitleA1.label')}
                    <span className="link">{props.policy?.policyHome?.applicant?.email}</span>
                        <>
                            {currentCountry==="SGP"&&<>
                                {cfg('finishSuccessfulSubTitleAnd.label')}<br/>
                                <span className="link">{getAgentEmail(props.agent?.email)}</span>
                            </>}
                            {cfg('finishSuccessfulSubTitleB.label')}<br/><br/>
                        </>

                    {cfg('finishSuccessfulSubTitleC1.label')}<br className="brEng"/>
                    {cfg('finishSuccessfulSubTitleC2.label')}<br className="brEng"/>
                    {cfg('finishSuccessfulSubTitleC3.label')}<br className="brEng"/>
                </div>
                <div className="FinishInfoCard InfoCard">
                    <div className="ItemRow">
                        <div className="Key KeyHeading">{cfg('finishPolicyNumber.label')}</div>
                        <div className="Value">{props.policy?.policyNumber}</div>
                    </div>
                    <div id="vline" className="VLine"></div>
                    <div  className="ItemRow">
                        <div className="Key KeyHeading">{cfg('finishReceiptNumber.label')}</div>
                        <div className="Value">{props?.policy?.policyHome?.vietnamPaymentStatus?.transactionRef}</div>
                    </div>

                </div>
                <div className="FinishWording">
                    {cfg('finishDetailRemarkA.label')}
                    <span
                        className="link"
                        onClick={()=>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'Download-Product-brochure',
                                }
                            });
                            return getAssetTypeDocDownload("Brochure", props.lang);
                        }}>
                        { cfg('finishDetailProductBrochure.label') }
                    </span>
                    {cfg('finishDetailRemarkB.label')}<br/>
                    {props.lang === "en" ? cfg('finishDetailRemarkC.label') : ""}
                    <span
                        className="link"
                        onClick={()=>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'policywording',
                                }
                            });
                            getAssetTypeDocOpen("PolicyWording", props.lang);
                        }}>
                            {cfg('productHighlightRemarkPolicyWording.label')}
                    </span>
                    {cfg('finishDetailRemarkD.label')}
                </div>
                <QnectButton color={'secondary'} className="FinishBackToHomeButton" onClick={()=>{
                    TagManager.dataLayer({
                        dataLayer: {
                            'event': 'back-to-home',
                        }
                    });
                    return continueToBackPage();
                }}>
                    {cfg('finishBackToHome.label')}
                </QnectButton>
                {/*<div className="FinishPromote">*/}
                {/*    <img className="Icon" src={Discount} alt=""/>*/}
                {/*    <div className="Desc">Enjoy 20% off and HK$100 Watsons outlet cash e-voucher for HOME Policy</div>*/}
                {/*    <Button color="info" isSmall>LEARN MORE</Button>*/}
                {/*</div>*/}
                <div className="FinishSpace"><br/></div>
            </div>

        </Template>
    );

}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        agent: _getAgent(state),
        campaign: _getCampaign(state),
        initialURL: _getInitialURL(state),
        country: _getCountry(state)
    }
}

// send service request to redux
const mapDispatchToProps = {
    clearPolicy:_clearPolicy,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    resetAgent:_resetAgent,
    resetCampaign: _resetCampaign
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Finish));


