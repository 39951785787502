import React, {useRef, useState} from 'react';
import {connect} from 'react-redux';

import "./applicant.css";
import {withRouter} from "react-router";
import Template from "../template";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import QnectDateField from "../qnect-ui/qnect-date-field";
import moment from "moment";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {_getPolicy, _ratePolicy, _singleRatePolicy, _storePolicy} from "../../store/policy/service";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {_validateForm} from "../../utils/utility";
import useConfig from "../qnect-ui/qnect-config";
import TagManager from "react-gtm-module";
import {_getAgent} from "../../store/agent/service";
import QnectButton from "../qnect-ui/qnect-button";
import {ReactComponent as HeadingIcon1} from "../../assets/step2icon2.svg";
import {ReactComponent as HeadingIcon2} from "../../assets/step2icon.svg";
import {ReactComponent as Individual} from "../../assets/adultblue.svg";
import {ReactComponent as Property} from "../../assets/owner_occpied.svg";
import {ReactComponent as Smile} from "../../assets/sentiment_satisfied_24px.svg";
import validateNRIC from "../../utils/nric-validation";
import {cloneDeep} from "lodash";


const Applicant = (props) => {
    const formId = "applicationForm";
    const defaultBirthDay = '';

    const applicant = props.policy?.policyHome?.applicant;
    const [applicantFullName, setApplicantFullName] = useState(applicant?.fullName?applicant?.fullName:'');
    const [applicantIdType, setApplicantIdType] = useState(applicant.idType ? applicant.idType: 'passport');
    const [applicantHKId, setApplicantHKId] = useState(applicant?.hkId?applicant?.hkId:'');
    const [applicantHKIdErrorMessage, setApplicantHKIdErrorMessage] = useState('');
    const [applicantArea, setApplicantArea] = useState(applicant?.applicantAreaDesc ? applicant?.applicantAreaDesc : '');
    const [applicantDistrict, setApplicantDistrict] = useState(applicant.applicantDistrictDesc ? applicant.applicantDistrictDesc : '');
    const [propertyArea, setPropertyArea] = useState(props?.policy?.policyHome?.propertyAreaDesc ? props?.policy?.policyHome?.propertyAreaDesc : '');
    const [propertyDistrict, setPropertyDistrict] = useState(props.policy?.policyHome?.propertyDistrictDesc ? props.policy?.policyHome?.propertyDistrictDesc : '');
    const [applicantBuildingType, setApplicantBuildingType] = useState(props?.policy?.policyHome?.buildingType ? props?.policy?.policyHome?.buildingType : '');
    const [applicantBuildingTypeDesc, setApplicantBuildingTypeDesc] = useState(props?.policy?.policyHome?.buildingType === "MS" ? "Multi Storey" : '');
    const [propertyProvince, setPropertyProvince] = useState(props?.policy?.policyHome?.propertyProvince ? props?.policy?.policyHome?.propertyProvince : '2');
    const [propertyProvinceDesc, setPropertyProvinceDesc] = useState(props?.policy?.policyHome?.propertyProvinceDesc ? props?.policy?.policyHome?.propertyProvinceDesc : "Ho Chi Minh");
    const [propertyProvinceErrorMessage, setPropertyProvinceErrorMessage] = useState('');
    const [applicantProvince, setApplicantProvince] =  useState(applicant?.applicantProvince ? applicant?.applicantProvince : '2');
    const [applicantProvinceDesc, setApplicantProvinceDesc] = useState(applicant?.applicantProvinceDesc ? applicant?.applicantProvinceDesc : "Ho Chi Minh");
    const [applicantProvinceErrorMessage, setApplicantProvinceErrorMessage] = useState('');

    const [applicantDateOfBirth, setApplicantDateOfBirth] = useState(()=>{
        const dob = applicant?.dateOfBirth;
        if (dob && moment(dob,'DD/MM/YYYY').isValid()) {
            return moment(dob, 'DD/MM/YYYY');
        }
        return defaultBirthDay;
    });
    const [applicantDateOfBirthErrorMessage, setApplicantDateOfBirthErrorMessage] = useState('');
    const [applicantStreetNameErrorMessage, setApplicantStreetNameErrorMessage] = useState('');
    const [propertyStreetNameErrorMessage, setPropertyStreetNameErrorMessage] = useState('');
    const [applicantMobileNoCountryCode, setApplicantMobileNoCountryCode] = useState(applicant?.mobileNoCountryCode && applicant?.mobileNoCountryCode );
    const [applicantMobileNumber, setApplicantMobileNumber] = useState(applicant?.mobileNo?applicant?.mobileNo:'');
    const [applicantEmailAddress, setApplicantEmailAddress] = useState(applicant?.email?applicant?.email:'');
    const [postCode, setPostCode] = useState(props.policy?.policyHome?.applicant?.applicantPostCode);
    const [applicantStreetName, setApplicantStreetName] = useState(props.policy?.policyHome?.applicant?.applicantStreetName ? props.policy?.policyHome?.applicant?.applicantStreetName :'');
    const [applicantBuildingName, setApplicantBuildingName] = useState(props.policy?.policyHome?.applicant?.applicantBuildingName ? props.policy?.policyHome?.applicant?.applicantBuildingName :'');
    const [applicantStreetNo, setApplicantStreetNo] = useState(props.policy?.policyHome?.applicant?.applicantBuildingStreetNo ? props.policy?.policyHome?.applicant?.applicantBuildingStreetNo :'');
    const [applicantFloorNo, setApplicantFloorNo] = useState(props.policy?.policyHome?.applicant?.applicantFloorNo ? props.policy?.policyHome?.applicant?.applicantFloorNo :'');
    const [applicantUnitNo, setApplicantUnitNo] = useState(props.policy?.policyHome?.applicant?.applicantUnitNo ? props.policy?.policyHome?.applicant?.applicantUnitNo :'');
    const [buildingStreetNo, setBuildingStreetNo] = useState(props.policy?.policyHome?.buildingStreetNo ? props.policy?.policyHome?.buildingStreetNo :'');

    const initInceptionDate = moment(props.policy?.policyHome?.inceptionDate, 'DD/MM/YYYY');
    const initExpiryDate = moment(props.policy?.policyHome?.expiryDate, 'DD/MM/YYYY');
    const occupancyType = props.policy?.policyHome?.occupancyType;
    const housingType = props.policy?.policyHome?.housingCode;
    const [effectiveDate, setEffectiveDate] = useState(initInceptionDate);
    const [effectiveDateErrorMessage, setEffectiveDateErrorMessage] = useState('');
    const [endDate, setEndDate] = useState(initExpiryDate);
    const [propertyPostCode, setPropertyPostCode] = useState(props.policy?.policyHome?.propertyPostCode.trim() ? props.policy?.policyHome?.propertyPostCode :'');
    const [propertyStreetName, setPropertyStreetName] = useState(props.policy?.policyHome?.propertyStreetName ? props.policy?.policyHome?.propertyStreetName :'');
    const [propertyBuildingName, setPropertyBuildingName] = useState(props.policy?.policyHome?.propertyBuildingName ? props.policy?.policyHome?.propertyBuildingName :'');
    const [propertyStreetNo, setPropertyStreetNo] = useState(props.policy?.policyHome?.propertyStreetNumber ? props.policy?.policyHome?.propertyStreetNumber :'');
    const [propertyFloorNo, setPropertyFloorNo] = useState(props.policy?.policyHome?.propertyFloorNumber ? props.policy?.policyHome?.propertyFloorNumber :'');
    const [propertyUnitNo, setPropertyUnitNo] = useState(props.policy?.policyHome?.propertyUnitNumber ? props.policy?.policyHome?.propertyUnitNumber :'');
    const [propertyAccumulationReg, setPropertyAccumulationReg] = useState(props.policy?.policyHome?.propertyAccumulationReg ? props.policy?.policyHome?.propertyAccumulationReg :'');
    const applicantIdTypeRef = useRef(null);
    const applicantAreaRef = useRef(null);
    const applicantDistrictRef = useRef(null);
    const propertyAreaRef = useRef(null);
    const propertyDistrictRef = useRef(null);
    const applicantHKIdRef = useRef(null);
    const propertyProvinceRef = useRef(null);
    const applicantProvinceRef = useRef(null);
    const applyRef = useRef(null);
    const cfg = useConfig(props.lang,  props.country );
    const propertyProvinceObject = (cfg('propertyProvince'));

    function isDomesticOptionalCoverSelected() {
        const optionalCoversList = props.policy?.policyHome?.optionalCoversList;
        if (Array.isArray(optionalCoversList) && optionalCoversList.length > 0){
            if (optionalCoversList.some(option => option.label === "quick_quote.optionalCovers.domestic_employers_liability")) {
                return true;
            }
        }
        return false;
    }

    function getPreviousPageLocation(){
        let prevPageLocation = "/quote";
        if (isDomesticOptionalCoverSelected()) {
            prevPageLocation = "/apply";
        }
        return prevPageLocation;
    }

    function backToPreviousPage () {
        const policyData = cloneDeep(props.policy);
        setValueToPropsPolicy(policyData,"false");
        props.storePolicy(policyData);

        const previousPageLocation = getPreviousPageLocation();
        {/* Call multirate for HKSI Home to refresh the plans */}
        if (previousPageLocation === '/quote' ) {
            props.showSpinner();
            props.ratePolicy().then(resp => {
                console.log(resp)
                if (resp.errorMessage) {
                    console.error(resp.errorMessage);
                } else {
                    props.history.push('/quote');
                }
                props.hideSpinner();
            }, (error) => {
                console.error(error?.response?.data?.message);
                props.hideSpinner();

            })
        } else {
            props.history.push(previousPageLocation);
        }
    }

    function continueToNextPage () {
        if (validateApply(formId)) {
            const policyData = cloneDeep(props.policy);
            setValueToPropsPolicy(policyData, "true");
            props.storePolicy(policyData);

            props.showSpinner();
            props.singleRatePolicy().then ( resp => {
                console.log(resp)
                if(resp.errorMessage){
                    if(resp.errorMessage.trim()==="Invalid address format") {
                        props.showError([cfg("applicantInvalidAddressError.message")]);
                    } else {
                        props.showError(['Policy Rate Error '+resp.errorMessage]);
                    }
                    console.error(resp.errorMessage);
                }else{
                    props.history.push('/confirm');
                }
                props.hideSpinner();
            }, (error) => {
                console.error(error?.response?.data?.message);
                props.hideSpinner();
            })
		}
    }

    function setValueToPropsPolicy(policyData, isApplicantEntered) {
        if (policyData.errorMessage) {
            policyData.errorMessage = null;
        }
        policyData.policyHome.inceptionDate = momentToString(effectiveDate);
        policyData.policyHome.effectiveDate = momentToString(effectiveDate);
        policyData.policyHome.expiryDate = momentToString(endDate);

        //policyData.policyPAN.applicantPAN.insuredLink  = insuredLink;
        policyData.policyHome.applicant.fullName = String(applicantFullName).toUpperCase();
        policyData.policyHome.applicant.dateOfBirth = momentToString(applicantDateOfBirth);
        policyData.policyHome.applicant.idType = applicantIdType;
        policyData.policyHome.applicant.hkId = String(applicantHKId).toUpperCase();
        policyData.policyHome.applicant.email = String(applicantEmailAddress).toUpperCase();
        policyData.policyHome.applicant.mobileNoCountryCode = applicantMobileNoCountryCode;
        policyData.policyHome.applicant.mobileNo = applicantMobileNumber;
        policyData.policyHome.applicant.sameAsInsuredAddress = sameAsInsuredProperty;
        policyData.policyHome.applicant.applicantPostCode = postCode;
        policyData.policyHome.applicant.applicantStreetName = applicantStreetName;
        policyData.policyHome.applicant.applicantBuildingName = applicantBuildingName;
        policyData.policyHome.applicant.applicantBuildingStreetNo = applicantStreetNo.trim();
        policyData.policyHome.applicant.applicantFloorNo = applicantFloorNo.trim();
        policyData.policyHome.applicant.applicantUnitNo = applicantUnitNo.trim();
        policyData.policyHome.applicant.applicantAreaDesc = applicantArea.trim();
        let tempAD = applicantDistrict.trim()
        if (tempAD.length === 1 && isNumeric(tempAD)) {
            setApplicantDistrictHandler(`0${tempAD}`)
            tempAD = `0${tempAD}`
        }
        policyData.policyHome.applicant.applicantDistrictDesc = tempAD;
        policyData.policyHome.applicant.applicantProvince = applicantProvince;
        policyData.policyHome.applicant.applicantProvinceDesc = applicantProvinceDesc;
        let tempPD = propertyDistrict.trim()
        if (tempPD.length === 1 && isNumeric(tempPD)) {
            setPropertyDistrictHandler(`0${tempPD}`)
            tempPD = `0${tempPD}`
        }
        if(sameAsInsuredProperty){
            policyData.policyHome.applicant.applicantPostCode = propertyPostCode;
            policyData.policyHome.applicant.applicantStreetName = propertyStreetName;
            policyData.policyHome.applicant.applicantBuildingName = propertyBuildingName;
            policyData.policyHome.applicant.applicantBuildingStreetNo = propertyStreetNo.trim();
            policyData.policyHome.applicant.applicantFloorNo = propertyFloorNo.trim();
            policyData.policyHome.applicant.applicantUnitNo = propertyUnitNo.trim();
            policyData.policyHome.applicant.applicantAreaDesc = propertyArea.trim();
            policyData.policyHome.applicant.applicantDistrictDesc = tempPD;
            policyData.policyHome.applicant.applicantProvince = propertyProvince;
            policyData.policyHome.applicant.applicantProvinceDesc = propertyProvinceDesc;
        }

        policyData.policyHome.propertyAddress1 = policyData.policyHome.propertyStreetName = propertyStreetName;
        policyData.policyHome.propertyAddress2 = policyData.policyHome.propertyStreetNumber = propertyStreetNo;
        policyData.policyHome.propertyDistrictDesc = tempPD;
        policyData.policyHome.propertyAreaDesc = propertyArea.trim();
        policyData.policyHome.buildingType = applicantBuildingType;
        policyData.policyHome.buildingTypeDesc = applicantBuildingTypeDesc;
        policyData.policyHome.propertyProvince = propertyProvince;
        policyData.policyHome.propertyProvinceDesc = propertyProvinceDesc;
        policyData.policyHome.buildingStreetNo = buildingStreetNo;
    }

    function momentToString(dateMoment) {
        if (dateMoment && moment.isMoment(dateMoment) && dateMoment.isValid()) {
            return dateMoment.format('DD/MM/YYYY');
        }
        return '';
    }
    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    function validateApply(formId) {

        if (_validateForm(formId)) {
            if (!isValidEffectiveDate(effectiveDate)) {
                console.log('herelasdkfjlaskdfj asdfasdklfasldkf 1 ')
                setTimeout(()=>{
                    setEffectiveDateErrorMessage('applyErrorMessage.effectiveDateRequired')
                })
            }
            if (!isValidAdultBirthday(applicantDateOfBirth)) {
                return false;
            }
            if(applicantStreetName.length===1 && !sameAsInsuredProperty){
                setApplicantStreetNameErrorMessage("applyErrorMessage.invalidApplicantStreetName");
                return false;
            }
            if(propertyStreetName.length===1 && sameAsInsuredProperty){
                setPropertyStreetNameErrorMessage("applyErrorMessage.invalidPropertyStreetName");
                return false;
            }
            if(isProvinceExempted(propertyProvince)){
                setPropertyProvinceErrorMessage("applyErrorMessage.exemptedProvinceError");
                return false;
            }
            if(isApplicantProvinceExempted(applicantProvince) && !sameAsInsuredProperty){
                setApplicantProvinceErrorMessage("applyErrorMessage.exemptedProvinceError");
                return false;
            }
            return true;
        }
        return false;
    }

    function setApplicantDateOfBirthHandler (dob)  {
        if (isValidAdultBirthday(dob)) {
            setApplicantDateOfBirthErrorMessage('');
        } else {
            if (isBlankInput(dob)) {
                setApplicantDateOfBirthErrorMessage("applyErrorMessage.birthDateRequired");
            } else if (isAgeOver70YearsOld(dob)) {
                setApplicantDateOfBirthErrorMessage("applyErrorMessage.birthDateOver70YearsOld");
            } else {
                setApplicantDateOfBirthErrorMessage("applyErrorMessage.birthDateInValid");
            }
        }
        setApplicantDateOfBirth(dob);
    }

    function setApplicantAreaHandler(area){
        setApplicantArea(area);
    }
    function setPropertyAreaHandler(area){
        setPropertyArea(area);
    }

    function setApplicantProvinceHandler(province, provinceDesc) {
        let option = propertyProvinceObject.options
        let item = option.find(x=>x.optionValue == province)
        setApplicantProvince(province);
        setApplicantProvinceDesc(item.optionLabel);
        if(!isApplicantProvinceExempted(province)){
            setApplicantProvinceErrorMessage(' ');
        }
    }

    function setPropertyProvinceHandler(province, provinceDesc) {
        let option = propertyProvinceObject.options
        let item = option.find(x=>x.optionValue == province)
        setPropertyProvince(province);
        setPropertyProvinceDesc(item.optionLabel);
        if(!isProvinceExempted(province)){
            setPropertyProvinceErrorMessage(' ');
        }
    }
    function isProvinceExempted(province){
        const exemptedProvinces = ['27','28','29','30','31','32','33','34','35','36','37','38'];
        if(exemptedProvinces.includes(province)){
            setPropertyProvinceErrorMessage("applyErrorMessage.exemptedProvinceError");
            return true;
        } else {
            setPropertyProvinceErrorMessage(' ');
            return false;
        }
    }
    function isApplicantProvinceExempted(province){
        const exemptedProvinces = ['27','28','29','30','31','32','33','34','35','36','37','38'];
        if(exemptedProvinces.includes(province)){
            setApplicantProvinceErrorMessage("applyErrorMessage.exemptedProvinceError");
            return true;
        } else {
            setApplicantProvinceErrorMessage(' ');
            return false;
        }
    }
    function setPropertyDistrictHandler(district){
        setPropertyDistrict(district);
    }
    function setApplicantDistrictHandler(district){
        setApplicantDistrict(district);
    }

    function setApplicantHkIdHandler (hkId) {
        setApplicantHKId(hkId);
    }

    function setPropertyStreetNameHandler(streetName){
        setPropertyStreetName(streetName);
        setBuildingStreetNo("");
    }

    function isValidAdultBirthday(dob) {
        if (isValidDateFormat(dob)){
            if (!isAgeBelow18YearsOld(dob) && !isAgeOver70YearsOld(dob)) {
                return true;
            }
        }
        return false;
    }

    function isAgeBelow18YearsOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(18, 'years').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isAfter(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isAgeOver70YearsOld (dob) {
        if (isValidDateFormat(dob)) {
            const dobStr = dob.format('DD/MM/YYYY');
            const minYearsStr = moment().subtract(70, 'years').format('DD/MM/YYYY');
            if (moment(dobStr, 'DD/MM/YYYY').isBefore(moment(minYearsStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isBlankInput(inputData) {
        return !inputData;
    }

    function isValidDateFormat(inputData) {
        if (!isBlankInput(inputData)) {
            if (moment.isMoment(inputData) && inputData.isValid()) {
                return true;
            }
        }
        return false;
    }

    function setEffectiveDateHandler (startDate)  {
        if (isValidEffectiveDate(startDate)) {
            const endDate = buildEndDate(startDate);
            setEffectiveDate(startDate);
            setEndDate(endDate);
            setEffectiveDateErrorMessage('');
        } else {
            setEffectiveDate(startDate);
            setEndDate(endDate);
            if (!isValidDateFormat(startDate)) {
                setEffectiveDateErrorMessage("applyErrorMessage.effectiveDateRequired");
            } else if (isEffectiveDateBelowToday(startDate)) {
                setEffectiveDateErrorMessage("applyErrorMessage.effectiveDateBelowToday");
            } else if (isEffectiveDateOver90Days(startDate)) {
                setEffectiveDateErrorMessage("applyErrorMessage.effectiveDateOver90Days");
            }
        }
    }

    function isValidEffectiveDate(effectiveDate) {
        if (isValidDateFormat(effectiveDate)){
            const effectDateStr = effectiveDate.format('DD/MM/YYYY');
            const todayStr = moment().format('DD/MM/YYYY');
            const maxDateStr = moment().add(90,'day').format('DD/MM/YYYY');
            if (moment(effectDateStr,'DD/MM/YYYY').isSameOrAfter(moment(todayStr,'DD/MM/YYYY')) &&
                moment(effectDateStr,'DD/MM/YYYY').isSameOrBefore(moment(maxDateStr,'DD/MM/YYYY'))){
                return true;
            }
        }
        return false;
    }

    function buildEndDate (startDate) {
        return moment(moment(startDate).add(1,'year').subtract(1,'day'),'DD/MM/YYYY')
    }

    function isEffectiveDateOver90Days(effectiveDate) {
        if (isValidDateFormat(effectiveDate)) {
            const effectDateStr = effectiveDate.format('DD/MM/YYYY');
            const maxDateStr = moment().add(90, 'day').format('DD/MM/YYYY');
            if (moment(effectDateStr, 'DD/MM/YYYY').isAfter(moment(maxDateStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    function isEffectiveDateBelowToday(effectiveDate) {
        if (isValidDateFormat(effectiveDate)) {
            const effectDateStr = effectiveDate.format('DD/MM/YYYY');
            const todayStr = moment().format('DD/MM/YYYY');
            if (moment(effectDateStr, 'DD/MM/YYYY').isBefore(moment(todayStr, 'DD/MM/YYYY'))) {
                return true;
            }
        }
        return false;
    }

    const [sameAsInsuredProperty, setSameAsInsuredProperty] = useState(props.policy.policyHome.applicant.sameAsInsuredAddress);
    function toggleSame() {
        let checkBox = document.getElementById("sameAsInsured");

        if (checkBox.checked){
            setSameAsInsuredProperty(true);

            setPostCode(propertyPostCode);
            setApplicantStreetName(propertyStreetName);
            setApplicantBuildingName(propertyBuildingName);
            setApplicantStreetNo(propertyStreetNo);
            setApplicantFloorNo(propertyFloorNo);
            setApplicantUnitNo(propertyUnitNo);
            setApplicantArea(propertyArea);
            setApplicantDistrict(propertyDistrict);

        } else {
            setSameAsInsuredProperty(false);
            setPostCode("");
            setApplicantStreetName("");
            setApplicantBuildingName("");
            setApplicantStreetNo("");
            setApplicantFloorNo("");
            setApplicantUnitNo("");
            setApplicantArea("");
            setApplicantDistrict("");
        }
    }

    const ApplicantAddress = () => {
        if (!sameAsInsuredProperty ) {
            return (
                <React.Fragment>
                    <div className="ApplicantInputFields">
                        <div className="ApplicantInputField  Full">
                            <div className="RowItem ">
                                <span3>{cfg('ApplicantCorrespondingAddress.label')}</span3>
                            </div>
                        </div>
                    </div>
                    <div className="ApplicantInputField">
                        <QnectSelectField
                            field="applicantProvince"
                            name="applicantProvince"
                            formId={formId}
                            value={applicantProvince}
                            label={ cfg('propertyProvince.label')}
                            options={ cfg('propertyProvince.options')}
                            fixedLabel
                            onChange={(value, label) => setApplicantProvinceHandler(value, label)}
                            reff={applicantProvinceRef}
                            errorMessage={applicantProvinceErrorMessage}>
                        </QnectSelectField>
                    </div>
                    <div className="ApplicantInputFields">
                        <div className="ApplicantInputField Full">
                            <QnectTextField
                                field="applicantFlatFloorBuilding"
                                name="applicantFlatFloorBuilding"
                                formId={formId}
                                value={applicantStreetName}
                                onBlur={(event) => setApplicantStreetName(event.target.value)}
                                maxLength={60}>
                            </QnectTextField>
                        </div>

                    </div>
                    <div className="ApplicantInputFields">
                        <div className="ApplicantInputField Full">
                            <QnectTextField
                                field="applicantStreetNoAndStreet"
                                name="applicantStreetNoAndStreet"
                                formId={formId}
                                value={applicantStreetNo}
                                onBlur={(event) => setApplicantStreetNo(event.target.value)}
                                maxLength={30}>
                            </QnectTextField>
                        </div>

                    </div>
                    <div className="ApplicantInputFields">
                        <div className="ApplicantInputField">
                            <QnectTextField
                                field="applyCity"
                                name="applyCity"
                                formId={formId}
                                value={applicantArea}
                                reff ={applicantAreaRef}
                                onBlur={(event) => setApplicantAreaHandler(event.target.value)}>
                            </QnectTextField>
                        </div>
                        <div className="ApplicantInputField">
                            <QnectTextField
                                field="applyDistricts"
                                name="applyDistricts"
                                formId={formId}
                                value={applicantDistrict}
                                reff ={applicantDistrictRef}
                                onBlur={(event) => setApplicantDistrictHandler(event.target.value)}>
                            </QnectTextField>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    return (
        <Template title={"Personal Accident Protection Plus"} step={2} info={'price'}  mobileBack={backToPreviousPage}  mobileSpace>
            <div className="Apply Applicant" ref={applyRef}>
                <div className={"PageHeading TCard LargeH tertiary90"}>
                    <HeadingIcon2 className={"PaymentHeadingIcon"}/>
                    <span>{cfg('PolicyEffectiveHeading.label')}</span>
                </div>

                <div className="WhiteCard  InterSB primary InsurancePeriod">
                    <span className={'NormalSH PolicyEffectiveInputHints1'}>
                              {cfg('PolicyEffectiveInputHints1.label')}&nbsp;
                    </span>
                    <QnectDateField
                        field="applyEffectiveDate"
                        name="applyEffectiveDate"
                        formId={formId}
                        value={effectiveDate}
                        fixedLabel
                        errorMessage={effectiveDateErrorMessage}
                        minDate={moment()}
                        maxDate={moment().add(90, 'days')}
                        onChange={(value) => setEffectiveDateHandler(value)}
                    ></QnectDateField>
                    <span className={'NormalSH'}>&nbsp;
                        {cfg('PolicyEffectiveInputHints2.label')}&nbsp;
                    </span>
                    <div className={"MobileBr"}/>
                    <span className={'InterB NormalSH black'}>{endDate && endDate.isValid() ? endDate.format('DD/MM/YYYY'): null}</span>
                </div>

                <div className={"PageHeading TCard LargeH tertiary90"}>
                    <HeadingIcon1 className={"ChatIcon"}/>
                    <span>{cfg('ApplicantHeading.label')}</span>
                </div>

                <div className="WhiteCard ApplicantInfoContainer">
                    <div className="ApplicantTypeGroup">
                        <Individual className={"Individual"}/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg("ApplyApplicant.label")}</div>
                            <div className="JobRole">{/*applicantOccupation*/}</div>
                        </div>
                    </div>
                    <div className="ApplicantInfoGroup CapitalizedInput">

                        <div className="ApplicantInputFields">
                            <div className="ApplicantInputField">
                                <QnectTextField
                                    field="applyFullName"
                                    name="applyFullName"
                                    formId={formId}
                                    value={applicantFullName}
                                    fixedLabel
                                    maxLength={50}
                                    onBlur={(event) => setApplicantFullName(event.target.value)}>
                                </QnectTextField>
                            </div>
                            <div className="ApplicantInputField" ref={applicantHKIdRef}>
                                <QnectTextField
                                    field={'applyPassportNo'}
                                    name={'applyPassportNo'}
                                    formId={formId}
                                    value={applicantHKId}
                                    fixedLabel
                                    helperText={cfg("applyHelperText.PassportNumber")}
                                    errorMessage={applicantHKIdErrorMessage}
                                    maxLength={50}
                                    onBlur={(event) => setApplicantHkIdHandler(event.target.value)}>
                                </QnectTextField>
                            </div>
                        </div>
                        <div className="ApplicantInputFields">
                            <div className="ApplicantInputField">
                                <QnectDateField
                                    field="applyDateOfBirth"
                                    name="applyDateOfBirth"
                                    formId={ formId }
                                    value={applicantDateOfBirth}
                                    fixedLabel
                                    helperText={cfg("applyHelperText.dateOfBirth")}
                                    initialFocusedDate={moment('01/01/1980', 'DD/MM/YYYY')}
                                    errorMessage={applicantDateOfBirthErrorMessage}
                                    onChange={(value) => setApplicantDateOfBirthHandler(value)}
                                    maxDate={moment().subtract(18, 'years')}
                                    minDate={moment().subtract(70, 'years')}>
                                </QnectDateField>
                            </div>

                            <div className="ApplicantInputField DesktopOnly">
                                <div className="CountryCode SmallField">
                                    <QnectTextField
                                        field="applyMobilePrefix"
                                        name="applyMobilePrefix"
                                        formId={formId}
                                        value={applicantMobileNoCountryCode}
                                        fixedLabel
                                        autoComplete="tel-country-code"
                                        startAdornment="+"
                                        onBlur={(event)=>setApplicantMobileNoCountryCode(event.target.value)}>
                                    </QnectTextField>
                                </div>
                                <div className="BigField MobileNumber">
                                    <QnectTextField
                                        field="applyMobileNumber"
                                        name="applyMobileNumber"
                                        formId={formId}
                                        value={applicantMobileNumber}
                                        fixedLabel
                                        maxLength={12}
                                        onBlur={(event)=>setApplicantMobileNumber(event.target.value)}>
                                    </QnectTextField>
                                </div>
                            </div>
                        </div>
                        <div className="ApplicantInputFields">
                            <div className="ApplicantInputField">
                                <QnectTextField
                                    field="applyEmailAddress"
                                    name="applyEmailAddress"
                                    formId={formId}
                                    value={applicantEmailAddress}
                                    fixedLabel
                                    maxLength={50}
                                    onBlur={(event) => setApplicantEmailAddress(event.target.value)}>
                                </QnectTextField>
                            </div>
                            <div className="ApplicantInputField EmailReminder">
                                <Smile className={"Smile"}/>&nbsp;&nbsp;
                                <span2>{cfg('ApplicantEmailAddMessage.label')}</span2>
                            </div>
                        </div>
                        <div className="ApplicantInputField TelField MobileOnly">
                            <div className="CountryCode SmallField">
                                <QnectTextField
                                    autocomplete="off"
                                    field="applyMobilePrefix"
                                    name="applyMobilePrefix"
                                    formId={formId}
                                    autoComplete="tel-country-code"
                                    value={applicantMobileNoCountryCode}
                                    fixedLabel
                                    startAdornment="+"
                                    onBlur={(event)=>setApplicantMobileNoCountryCode(event.target.value)}>
                                </QnectTextField>
                            </div>
                            <div className=" BigField MobileNumber">
                                <QnectTextField
                                    field="applyMobileNumber"
                                    name="applyMobileNumber"
                                    formId={formId}
                                    value={applicantMobileNumber}
                                    fixedLabel
                                    maxLength={12}
                                    onBlur={(event)=>setApplicantMobileNumber(event.target.value)}>
                                </QnectTextField>
                            </div>
                        </div>
                        <div className="ApplicantInputFields">
                            <div className="Item Checkbox">
                                <label className="container">
                                    <input type="checkbox" id="sameAsInsured" defaultChecked={sameAsInsuredProperty} onClick={() => toggleSame()} />
                                    <span className="checkmark"></span>
                                    <div className="Wording"><span>{cfg('ConfirmSameasInsured.label')}</span></div>
                                </label>
                            </div>
                        </div>
                        <ApplicantAddress/>
                    </div>
                </div>
                <div className="WhiteCard ApplicantInfoContainer">
                    <div className="ApplicantTypeGroup">
                        <Property className={"Property"}/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg('ConfirmInsuredProperty.label')}</div>
                            <div className="JobRole">{cfg("ConfirmOccupancyName" + occupancyType + ".label")}</div>
                        </div>
                    </div>
                    <div className="ApplicantInfoGroup CapitalizedInput">
                        <div className="ApplicantInputFields">
                            <div className="ApplicantInputField">
                                <div className="RowItem ">
                                    <div className="AgeGroup">{cfg('landingHousingTypeLabel.label')}</div>
                                    <div className="Value">{cfg("ConfirmHousingName" + housingType + ".label")}</div>
                                </div>
                            </div>
                            <div className="ApplicantInputField">
                                <QnectSelectField
                                    field="propertyProvince"
                                    name="propertyProvince"
                                    formId={formId}
                                    value={propertyProvince}
                                    label={ cfg('propertyProvince.label')}
                                    options={ cfg('propertyProvince.options')}
                                    fixedLabel
                                    onChange={(value, label) => setPropertyProvinceHandler(value, label)}
                                    reff={propertyProvinceRef}
                                    errorMessage={propertyProvinceErrorMessage}>
                                </QnectSelectField>
                            </div>
                        </div>

                            <div className="ApplicantInputFields">
                                <div className="ApplicantInputField Full">
                                    <QnectTextField
                                        field="applicantFlatFloorBuilding"
                                        name="applicantFlatFloorBuilding"
                                        formId={formId}
                                        value={propertyStreetName}
                                        onBlur={(event) => setPropertyStreetNameHandler(event.target.value)}
                                        maxLength={60}>
                                    </QnectTextField>
                                </div>

                            </div>
                            <div className="ApplicantInputFields">
                                <div className="ApplicantInputField Full">
                                    <QnectTextField
                                        field="applicantStreetNoAndStreet"
                                        name="applicantStreetNoAndStreet"
                                        formId={formId}
                                        value={propertyStreetNo}
                                        onBlur={(event) => setPropertyStreetNo(event.target.value)}
                                        maxLength={30}>
                                    </QnectTextField>
                                </div>

                            </div>
                            <div className="ApplicantInputFields">
                                <div className="ApplicantInputField">
                                    <QnectTextField
                                        field="applyCity"
                                        name="applyCity"
                                        formId={formId}
                                        value={propertyArea}
                                        reff ={propertyAreaRef}
                                        onBlur={(event) => setPropertyAreaHandler(event.target.value)}>
                                    </QnectTextField>
                                </div>
                                <div className="ApplicantInputField">
                                    <QnectTextField
                                        field="applyDistricts"
                                        name="applyDistricts"
                                        formId={formId}
                                        value={propertyDistrict}
                                        reff ={propertyDistrictRef}
                                        onBlur={(event) => setPropertyDistrictHandler(event.target.value)}>
                                    </QnectTextField>
                                </div>
                            </div>

                    </div>
                </div>
                <div className={"ButtonGroup"}>
                    <QnectButton color={"secondary"} className={"mobileBack"} onClick={()=> {
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'back',
                            }
                        });
                        return backToPreviousPage();}}>
                        {cfg('ApplyBack.label')}
                    </QnectButton>
                    <div className="space"></div>
                    <QnectButton onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'continue',
                            }
                        });
                        return continueToNextPage();}}>
                        {cfg('ApplyContinue.label')}
                    </QnectButton>
                </div>
            </div>
        </Template>
    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        agent: _getAgent(state),
        country: _getCountry(state)
    }
}

// send service request to redux
const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    storePolicy: _storePolicy,
    singleRatePolicy: _singleRatePolicy,
    ratePolicy: _ratePolicy,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Applicant));
