import React, {useState} from 'react';
import {connect} from 'react-redux';
import "./confirm.css";
import {withRouter} from "react-router";
import Template from "../template";
import Individual from '../../assets/adultblue.svg'
import {_getPolicy, _ratePolicy, _storePolicy,} from "../../store/policy/service";
import {
    _getCountry,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_validateForm} from "../../utils/utility";
import useConfig from "../qnect-ui/qnect-config";
import {ReactComponent as Edit} from "../../assets/EditBlack.svg";
import {_getCampaign} from "../../store/campaign/service";
import TagManager from "react-gtm-module";
import QnectButton from "../qnect-ui/qnect-button";
import {ReactComponent as HeadingIcon1} from "../../assets/step3icon.svg";
import {_getAgent} from "../../store/agent/service";

import {AssetType, getAsset} from "../../utils/pdf-asset";
import InfoIcon from "../../assets/info_outline_darkblue.svg";
import InfoDarkIcon from "../../assets/info_darkblue.svg";
import QBEBalloonTip from "../ui/qbe-balloon-tips";
import SpecifiedPersonalValuables from "../../assets/personal_valuable.svg";
import TenantIcon from "../../assets/tenant_icon.svg";
import OwnerOccIcon from "../../assets/owner_occpied.svg";
import LandlordIcon from "../../assets/landlord_icon.svg";
import {cloneDeep} from "lodash";


function amountFormat(num) {
    const amount = parseFloat(num).toFixed(2).toLocaleString('en');
    return amount;
}
function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

const getAssetTypeDocOpen = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            window.open(getAsset(assetTypePB).file,  '_blank');
            break
        case 'PolicyWording':
            window.open(getAsset(assetTypePW).file,  '_blank');
            break
        default:
            window.open(getAsset(assetTypePB).file,  '_blank');
    }
}

const Confirm = (props) => {
    const cfg = useConfig(props.lang, props.country);
	const country = props.country;
	const propertyProvinceObject = (cfg('propertyProvince'));

    const DeclarationsPopup = (props) => {
        props.showModal(
            {
                title: cfg('ConfirmDeclarationPopupTitle.label'),
                className: 'DeclarationsContainer large',
                content:
                    <div className="Declarations DeclarationsPopupContent">
                        <ul>
                            {cfg('ConfirmDeclarationPopupContent1.label').split('\n').map(line=><li>{line}</li>)}
                        </ul>
                    </div>,

            });
    }
    const MajorExclusionPopup = (props) => {
        props.showModal(
            {
                title: cfg('ConfirmMajorExclusionsPopupTitle.label'),
                className: 'MajorExclusionContainer large',
                content:
                    <div className="MajorExclusions">
                        <ul>
                            {cfg('ConfirmMajorExclusionsPopupContent.label').split('\n').map(line=><li>{line}</li>)}
                        </ul>
                    </div>,

            });
    }


    const PersonalInformationCollectionStatementPopup = (props) => {
        const lastPICS = () => {
            if(props.lang === "en"){
                return <li>Email: <a href="mailto:info.viet@qbe.com">info.viet@qbe.com</a> {cfg('ConfirmPICSPopupContent8.label')}</li>
            } else {
                return <li>{cfg('ConfirmPICSPopupContent9.label')}<a href="mailto:info.viet@qbe.com">info.viet@qbe.com</a> {cfg('ConfirmPICSPopupContent9a.label')}</li>
            }
        };
        props.showModal(
            {
                title: cfg('ConfirmPICSPopupTitle.label'),
                className: 'PersonalInformationCollectionStatementContainer large',
                content:
                    <div className="PersonalInformationCollectionStatement">

                                {cfg('ConfirmPICSPopupContent1.label')}
                                <ol type={"a"}>
                                    <li>{cfg('ConfirmPICSPopupContent2.label')}</li>
                                    <li>{cfg('ConfirmPICSPopupContent3a.label')}
                                        <a href={cfg('ConfirmPICSPopupContent3b.label')}>{cfg('ConfirmPICSPopupContent3b.label')}</a>
                                        {cfg('ConfirmPICSPopupContent3c.label')}
                                    </li>
                                    <li>{cfg('ConfirmPICSPopupContent4.label')}</li>
                                    <ol type={"i"}>
                                        {cfg('ConfirmPICSPopupContent5.label').split('\n').map(line=><li>{line}</li>)}
                                    </ol>
                                    {cfg('ConfirmPICSPopupContent6.label').split('\n').map(line=><li value={"4"}>{line}</li>)}
                                    {cfg('ConfirmPICSPopupContent7.label').split('\n').map(line=><>{line}<br/></>)}
                                    <br/>
                                    {lastPICS()}
                                </ol>
                    </div>,
            });
    }


    const formId = 'payment';
    const plans = props.policy?.policyHome?.premiumItems;
    const premiumUnit = "VND";
    const occupancyType = props.policy?.policyHome?.occupancyType;
    const campaignDiscount = amountFormat(props.policy?.policyHome?.totalDiscount);
    const planItems = props.policy?.policyHome?.planItems;
    const housingType = props.policy?.policyHome?.housingCode;

    const province = props.policy?.policyHome?.propertyProvince;
    let provinceDesc = props.policy?.policyHome?.propertyProvinceDesc;
    if(props.lang==='vn'){
        provinceDesc = propertyProvinceObject.options_vn.find(x=>x.optionValue==province).optionLabel
    }
    let eclevy = 0.00;
    let premiumPlan = 0.00;
    let campaign = 0.00
    if (Array.isArray(plans)) {
        premiumPlan = Number(plans[0]?.premium);
        campaign = plans[0]?.discount;
        console.log("premiumPlan-------------------" + premiumPlan);
    }
    if(campaign > 0){
        premiumPlan = premiumPlan+Number(campaign);
    }
    if (Array.isArray(planItems)) {
        eclevy = planItems[0]?.ecLevy;
        console.log("eclevy-------------------" + eclevy);
    }
    const totalPremium = numberWithCommas(parseFloat(props.policy?.policyHome?.totalDueAmount).toFixed(2));
   	const vat = amountFormat(props.policy?.policyHome?.totalVat);
    const marketingMessage =  (props?.lang && props?.lang !== 'en') ? props?.campaign?.marketingMessageCn: props?.campaign?.marketingMessage;
    const policyPaymentSuccess= props.policy?.paymentSuccess;
    const p400Campaign = props?.campaign?.p400Campaign;
    const propertyAddress1 = props.policy?.policyHome?.propertyAddress1;
    console.log("propertyAddress1------"+ propertyAddress1);
    const propertyAddress2 = props.policy?.policyHome?.propertyAddress2;
    const propertyArea = props.policy?.policyHome?.propertyAreaDesc;
    const propertyDistrict = props.policy?.policyHome?.propertyDistrictDesc;
    const propertyStreetName = props.policy?.policyHome?.propertyStreetName;

    const declarationHKG = cfg('ConfirmDeclarationAndConsentHKG.label');
    const applicant = props.policy?.policyHome?.applicant;
    let applicantProvinceDesc = applicant.applicantProvinceDesc
    if(props.lang==='vn'){
        const ind = propertyProvinceObject.options.findIndex(x=>x.optionLabel===applicantProvinceDesc)
        applicantProvinceDesc = propertyProvinceObject.options_vn.find((e,i)=>i == ind).optionLabel
    }
    const noOfEmployees = props.policy?.policyHome?.numberOfEmployees;
    const employeeElements = [];
    const noOfSV = props.policy?.policyHome?.specifiedValuablesList.length;
    const specifiedValuablesElements=[];
    const totalSpecified = numberWithCommas(parseFloat(props.policy?.policyHome?.totalSpecifiedValuables));
    const specifiedElements = [];
    let noSpecifiedValuables = 0;
    const specifiedValuables = props.policy?.policyHome?.specifiedValuablesList;
    if (Array.isArray(specifiedValuables)) {
        noSpecifiedValuables = specifiedValuables.length;
        console.log("noSpecifiedValuables-------------------" + noSpecifiedValuables);
    }

    const insuredPersonType = props.policy?.policyPAN?.insuredPersonType;
    const numberOfAdults = props.policy?.policyPAN?.family?.numberOfAdults;
    const noOfChildren = props.policy?.policyPAN?.family?.noOfChildren;
    const childElements = [];

    function isCoverWithSpecified() {
        console.log("noSpecifiedValuables="+noSpecifiedValuables);
        if (!isNaN(noSpecifiedValuables) && Number(noSpecifiedValuables) > 0) {
            return true;
        }
        return false;
    }

    const OccupancyTypeDisplay = () => {
        if (occupancyType === '0001') {
            return (
                <React.Fragment>
                    <div className="PersonRoleGroup PersonContentInfoGroup">
                        <img src={TenantIcon}  className="Icon2"/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg('ConfirmInsuredProperty.label')}</div>
                            <div className="JobRole">{cfg("ConfirmOccupancyName" + occupancyType + ".label")}</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (occupancyType === '0002') {
            return (
                <React.Fragment>
                    <div className="PersonRoleGroup PersonContentInfoGroup">
                        <img src={OwnerOccIcon}  className="Icon2"/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg('ConfirmInsuredProperty.label')}</div>
                            <div className="JobRole">{cfg("ConfirmOccupancyName" + occupancyType + ".label")}</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="PersonRoleGroup PersonContentInfoGroup">
                        <img src={LandlordIcon}  className="Icon2"/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg('ConfirmInsuredProperty.label')}</div>
                            <div className="JobRole">{cfg("ConfirmOccupancyName" + occupancyType + ".label")}</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }


    function isCoverWithDomestic() {
        if (!isNaN(noOfEmployees) && Number(noOfEmployees) > 0) {
            return true;
        }
        return false;
    }

    const PropertyAddress = () => {

        return (
            <React.Fragment>
                <div className="PersonContentInfoGroup maxWidth">
                    <div className="Row">
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key">{cfg('landingHousingTypeLabel.label')}</div>
                            <div className="Value">{cfg("ConfirmHousingName" + housingType + ".label")}</div>
                        </div>
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key">{cfg('propertyProvince.label')}</div>
                            <div className="Value">{provinceDesc}</div>
                        </div>
                    </div>
                    <div className="Row">
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key ">{cfg('ConfirmAddress.label')}</div>
                            <div className="Value">{propertyStreetName} {propertyAddress2}</div>
                        </div>
                    </div>
                    <div className="Row">
                        <div className="RowItem MarginTB0">
                            <div className="Key">{cfg('applyCity.label')}</div>
                            <div className="Value">{propertyArea}</div>
                        </div>
                        <div className="RowItem MarginTB0">
                            <div className="Key">{cfg('applicantDistrict.label')}</div>
                            <div className="Value">{propertyDistrict}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }

    const ApplicantAddress = () => {
        if (applicant.sameAsInsuredAddress === true) {
            return (
                <React.Fragment>
                    <div className="Row">
                        <div className="RowItem2 SameasInsured">
                            <div className="Value">{cfg('ConfirmSameasInsured.label')}</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {

            return (
                <React.Fragment>
                    <div className="Row">
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key2">{cfg('ConfirmCorrespondingAddress.label')}</div>
                        </div>
                    </div>
                    <div className="Row">
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key ">{cfg('ConfirmAddress.label')}</div>
                            <div className="Value">{applicant.applicantBuildingStreetNo} {applicant.applicantStreetName}</div>
                        </div>
                    </div>
                    <div className="Row">
                        <div className="RowItem">
                            <div className="Key">{cfg('applyCity.label')}</div>
                            <div className="Value">{applicant.applicantAreaDesc}</div>
                        </div>
                        <div className="RowItem MarginTB0">
                            <div className="Key">{cfg('applicantDistrict.label')}</div>
                            <div className="Value">{applicant.applicantDistrictDesc}</div>
                        </div>
                    </div>
                    <div className="Row">
                        <div className="RowItem MarginTB0 fullname">
                            <div className="Key">{cfg('propertyProvince.label')}</div>
                            <div className="Value">{applicantProvinceDesc}</div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }

    }


    for (let i = 0; i < noOfEmployees; i++) {
        employeeElements.push({
            fullName: props.policy?.policyHome?.employeeList[i]?.fullName,
            hkId: props.policy?.policyHome?.employeeList[i]?.hkId,
            idType: props.policy?.policyHome?.employeeList[i]?.idType,
            dateOfBirth: props.policy?.policyHome?.employeeList[i]?.dateOfBirth,
            nationality: props.policy?.policyHome?.employeeList[i]?.nationality,
            nationalityDesc: props.policy?.policyHome?.employeeList[i]?.nationalityDesc
        });
    }
    for(let i = 0; i< noOfSV; i++) {
        specifiedValuablesElements.push({
            TypeOfValuables: props.policy?.policyHome?.specifiedValuablesList[i]?.type,
            description: props.policy?.policyHome?.specifiedValuablesList[i]?.typeDesc,
            SumInsured: props.policy?.policyHome?.specifiedValuablesList[i]?.sumInsured
        })
    }
    for(let i = 0; i < noSpecifiedValuables; i++){
        specifiedElements.push({
            type: props.policy?.policyHome?.specifiedValuablesList[i]?.type,
            typeDesc: props.policy?.policyHome?.specifiedValuablesList[i]?.typeDesc,
            sumInsured : props.policy?.policyHome?.specifiedValuablesList[i]?.sumInsured,
            description : props.policy?.policyHome?.specifiedValuablesList[i]?.description
        })
    }

    const [directMarketing, setDirectMarketing] = useState('false');
    function toggleMarketing() {
        var checkBox = document.getElementById("myMarketing");

        if (checkBox.checked == true){
            setDirectMarketing('true');
        } else {
            setDirectMarketing('false');
        }
    }

    const isFamilyHavingMoreThanOnePerson = (noOfChildren && noOfChildren > 0) || (numberOfAdults && numberOfAdults > 1);
    const continueToBackPage = () => {
        if (insuredPersonType?.toLowerCase() === "family" && isFamilyHavingMoreThanOnePerson) {
            props.history.push('/apply');
        } else {
            props.history.push('/applicant');
        }
    }

    const continueToQuotePage = () => {
        const policyData = cloneDeep(props.policy);
        props.storePolicy(policyData);

        props.showSpinner();
        props.ratePolicy().then(resp => {
            console.log(resp)
            if (resp.errorMessage) {
                console.error(resp.errorMessage);
            } else {
                props.history.push('/quote');
            }
            props.hideSpinner();
        }, (error) => {
            console.error(error?.response?.data?.message);
            props.hideSpinner();

        })
    }

    const continueToApplicantPage = () => {
        props.history.push('/applicant');
    }
    const continueToApplyPage = () => {
        props.history.push('/apply');
    }

    const [declarationError, setDeclarationError] = useState(false)

    const continueToNextPage = () => {

        if(_validateForm(formId)) {

            let checkBoxDeclaration = document.getElementById("myDeclaration");

            if (checkBoxDeclaration.checked && policyPaymentSuccess == false) {
                console.log("PROCESS PAYMENT--------");

                props.policy.policyHome.applicant.directMarketing = directMarketing;
                props.storePolicy(props.policy);
                props.history.push('/payment');

            } else {
                setDeclarationError(!checkBoxDeclaration.checked);
            }
        }
    };
    const getPlanName= ()=>{
        let temp = props.policy?.policyHome?.planItems.filter(x=>x.planCode===props.policy?.policyHome?.planType)[0].planCode
        return cfg(`ConfirmPlanName${temp}.label`)

    }
    return (
        <Template step={3} info={'date'}  mobileBack={continueToBackPage}  mobileSpace>
            <div className="Confirm">
                <div className={"PageHeading TCard LargeH tertiary90"}>
                    <HeadingIcon1/>
                    <span>{cfg('ConfirmHeading.label')}</span>
                </div>
                <div className="Title">{cfg('ConfirmPlanSummary.label')}</div>
                <div className="PlanSummaryInfoGroup">
                    <Edit className={"EditButton"} onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'edit-plan-selected',
                            }
                        });
                        return continueToQuotePage();
                    }} alt=""/>
                    <div className="RowGroup">
                        <div className="Row">
                            <div className="Key">{cfg('ConfirmBasicPlan.label')}</div>
                            <div className="Mid">{cfg('ConfirmPlanDescription.label')} - {getPlanName()}</div>
                            <div className="Amount">{premiumUnit}{numberWithCommas(amountFormat(premiumPlan))}</div>
                        </div>
                        {/* show the discount only if there is a campaign available and it's non zero */}
                        {(p400Campaign == 'B2CC00' || campaignDiscount !== '0.00') &&
                        <div className="Row">
                            <div className="Key">{cfg('ConfirmDiscount.label')}</div>
                            <div className="Mid">{marketingMessage}</div>
                            <div className="Amount">- {premiumUnit}{numberWithCommas(campaignDiscount)}</div>
                        </div>
                        }

                        <div className="Row">

                            <div className="Key MobileOneRow">
                                <div className={"keyTitle"}>{cfg('ConfirmVat.label')}&nbsp;

                                </div>
                                <div className={"MobileAmount"}>{premiumUnit}{numberWithCommas(vat)}</div>
                            </div>
                            <div className="Mid"></div>
                            <div className={"Amount"}>{premiumUnit}{numberWithCommas(vat)}</div>
                        </div>
                    </div>
                    <div className="TotalPremium">
                        {cfg('ConfirmTotalPremium.label')}<span className="Amount">{premiumUnit}{totalPremium}</span>
                    </div>
                </div>
                <div className="Title">{cfg('ConfirmApplicantAndInsuredProperty.label')}</div>
                <div className="WhiteCard BlueBorder InsuredPersonGroup">
                    <Edit className={"EditButton"} onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'edit-applicant-insured-property',
                            }
                        });
                        return continueToApplicantPage();
                    }} alt=""/>
                    <div className="PersonRoleGroup PersonContentInfoGroup">
                        <img src={Individual} className="Icon"/>
                        <div className="Content">
                            <div className="AgeGroup">{cfg('ConfirmApplicant.label')}</div>
                            <div className="JobRole">{/*occupationAdult2*/}</div>
                        </div>
                    </div>
                    <div className="PersonContentInfoGroup maxWidth">
                        <div className="Row">
                            <div className="RowItem MarginTB0">
                                <div className="Key">{cfg('ConfirmFullName.label')}</div>
                                <div className="Value">{applicant.fullName}</div>
                            </div>
                            <div className="RowItem MarginTB0">
                                <div className="Key">{cfg('ConfirmDob.label')}</div>
                                <div className="Value ">{applicant.dateOfBirth}</div>
                            </div>
                        </div>
                        <div className="Row">
                            <div className="RowItem MarginTB0">
                                <div className="Key">{cfg('ConfirmHKIDOrPassport.label')}</div>
                                <div className="Value ">{applicant.hkId} </div>
                            </div>
                            <div className="RowItem MarginTB0">
                                <div className="Key">{cfg('ConfirmEmail.label')}</div>
                                <div className="Value wordBreak ">{applicant.email?.toLowerCase()}</div>
                            </div>
                        </div>
                        <div className="Row ">
                            <div className="RowItem MarginTB0 fullname">
                                <div className="Key">{cfg('ConfirmMobile.label')}</div>
                                <div className="Value ">+{applicant.mobileNoCountryCode} {applicant.mobileNo}</div>
                            </div>
                        </div>
                        <ApplicantAddress/>
                    </div>
                    <div className="BlueBorder NoPersonRoleGroup PersonContentInfoGroup">
                        <div className="Content">
                        </div>
                    </div>
                    <div className="NoBorderPersonRoleGroup PersonContentInfoGroup">
                        <div className="Content">
                        </div>
                    </div>
                    <OccupancyTypeDisplay/>
                    <PropertyAddress/>
                </div>
                {/*<InsuredInfo/>*/}
                <div className="PaymentDeclarationTitle">{cfg('ConfirmDeclarationAndConsent.label')}</div>
                <div className="PaymentDeclarationGroups">
                    <div className="Item">
                        <label className="container">
                            <input type="checkbox" id="myDeclaration" onChange={(event) => {
                                setDeclarationError(!event.target.checked)
                            }}/>
                            <span className="checkmark"></span>
                        </label>
                        <div className="Wording">
                            <span>{cfg('ConfirmDeclarationAndConsent1A.label')}<span
                                className="Link"
                                onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'declarations',
                                        }
                                    });
                                    return DeclarationsPopup(props);
                                }}>{cfg('ConfirmDeclarationAndConsent1B.label')}
                                </span>{ cfg('ConfirmDeclarationAndConsentComma.label')}<span
                                    className="Link"
                                    onClick={() => {
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                'event': 'major-exclusion',
                                            }
                                        });
                                        return MajorExclusionPopup(props);
                                    }}>{cfg('ConfirmDeclarationAndConsent1C.label')}
                                </span>{cfg('ConfirmDeclarationAndConsentComma.label')}<span
                                className="Link"
                                onClick={()=>{
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'policy-wording',
                                        }
                                    });
                                    getAssetTypeDocOpen("PolicyWording", props.lang);
                                }}>{cfg('ConfirmDeclarationAndConsent1D.label')}
                            </span>{cfg('ConfirmDeclarationAndConsentAnd.label')}<span
                                className="Link"
                                onClick={() => {
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'PICs',
                                        }
                                    });
                                    return PersonalInformationCollectionStatementPopup(props);
                                }}>
                            {cfg('ConfirmDeclarationAndConsent1E.label')}{cfg('ConfirmDeclarationAndConsent1F.label')}
                        </span>
                        </span>
                            <br/>
                            {
                                declarationError&&
                                <div className={"Error"}>
                                    {cfg('ConfirmDeclaration1ErrorMsg.label')}
                                </div>
                            }
                        </div>
                    </div>

                    <div className="Item">
                        <label className="container">
                            <input type="checkbox" id="myMarketing" onClick={() => toggleMarketing()}/>
                            <span className="checkmark"></span>
                        </label>
                        <div  className="Wording"><span>
                            {declarationHKG}
                        </span></div>
                    </div>
                </div>
                <div className="ButtonGroup">
                    <QnectButton color="secondary"  className={"mobileBack"}  onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'back',
                            }
                        });
                        return continueToBackPage();
                    }}>{cfg('ConfirmBackButton.label')}</QnectButton>
                    <div className="space"></div>
                    <QnectButton color="primary" onClick={() =>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'continue-to-pay',
                            }
                        });
                        return continueToNextPage();
                    }}>{cfg('ConfirmPayButton.label')}</QnectButton>
                </div>

            </div>
        </Template>
    );

}


// get status from redux (subscription)
const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
        lang: _getLang(state),
        campaign:_getCampaign(state),
		country:_getCountry(state),
        agent: _getAgent(state),
    }
}

// send service request to redux
const mapDispatchToProps = {
    storePolicy: _storePolicy,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    ratePolicy: _ratePolicy
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Confirm));


