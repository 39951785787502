import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import QuotePage from "../pages/quote-page";

import {connect} from "react-redux";
import MainPage from "../pages/main-page";
import ApplicantPage from "../pages/applicant-page";
import ApplyPage from "../pages/apply-page";
import ConfirmPage from "../pages/confirm-page";
import PaymentPage from "../pages/payment-page";
import FinishPage from "../pages/finish-page";

let history;

export function getHistory() {
    if (!history) {
        history = createBrowserHistory();
    }

    return history;
}

const Routes = (props) => {
    return (
        <>
            <Route path='/' exact component={MainPage} />
            <Route path='/(partner|campaign|promo|lang|sub)/' component={MainPage} />
            <Route path='/quote' exact component={QuotePage} />
            <Route path='/apply' exact component={ApplyPage} />
            <Route path='/applicant' exact component={ApplicantPage} />
            <Route path='/confirm' exact component={ConfirmPage} />
            <Route path='/payment' exact component={PaymentPage} />
            <Route path='/finish' exact component={FinishPage} />
        </>
    );
}


const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
