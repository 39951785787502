export const nationalityOptions =
    [
    {
        "optionLabel": "Hong Kong",
        "optionValue": "HKG"
    },
    {
        "optionLabel": "Bangladeshi",
        "optionValue": "BGD"
    },
    {
        "optionLabel": "Burmese",
        "optionValue": "MMR"
    },
    {
        "optionLabel": "Cambodian",
        "optionValue": "KHM"
    },
    {
        "optionLabel": "Filipino",
        "optionValue": "PHL"
    },
    {
        "optionLabel": "Indian",
        "optionValue": "IND"
    },
    {
        "optionLabel": "Indonesian",
        "optionValue": "IDN"
    },
    {
        "optionLabel": "Malaysian",
        "optionValue": "MYS"
    },
    {
        "optionLabel": "Nepalese",
        "optionValue": "NPL"
    },
    {
        "optionLabel": "Singapore",
        "optionValue": "SGP"
    },
    {
        "optionLabel": "Sri Lankan",
        "optionValue": "LKA"
    },
    {
        "optionLabel": "Thai",
        "optionValue": "THA"
    },
    {
        "optionLabel": "Pakistani",
        "optionValue": "PAK"
    }
];
