import MomentUtils from '@date-io/moment';

/**
 * Customised MomentUtils to provide two-letter days of the week in the date
 * picker.
 */
export default class PickerUtils extends MomentUtils {
    getWeekdays() {
        return this.moment.weekdaysMin();
    }
}
