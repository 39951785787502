import * as type from "./types";
import { updateObject } from "../../utils/utility";
import _ from "lodash";

const INITIAL_STATE = {
    name: '',
    email: '',
    tel: '',
    registrationNo: '',
    agentCode: '',
};

const UNCHANGED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

const storeAgent = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

export const agentReducer = (state = INITIAL_STATE, action) => {
    switch ( action.type ) {
        case type.AGENT_STORE:
            return storeAgent(state, action);
        case type.FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        case type.RESET_AGENT:
            return _.cloneDeep(UNCHANGED_INITIAL_STATE);
        default:
            return state;
    }
}
