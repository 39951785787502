import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import Finish from "../components/finish/finish";
import {connect} from "react-redux";
import {_getPolicy} from "../store/policy/service";
import {_getAgent} from "../store/agent/service";
import {_getCampaign} from "../store/campaign/service";
import {_getLang} from "../store/system/service";

const FinishPage = (props) => {

    function getPlanName(planType) {
        if (planType) {
            if (String(planType).toLocaleUpperCase() === "PP") {
                return "QBE Home Plus Protection - Platinum";
            } else if (String(planType).toLocaleUpperCase() === "SP") {
                return "QBE Home Plus Protection - Silver";
            } else if(String(planType).toLocaleUpperCase() === "GP"){
                return "QBE Home Plus Protection - Gold";
            }
        }
        return "";
    }

    function getHousingType(housingCode) {
        if (housingCode) {
            if (String(housingCode).toLocaleUpperCase() === "PU") {
                return "Apartment";
            } else if (String(housingCode).toLocaleUpperCase() === "PR") {
                return "Private House";
            }
        }
        return "";
    }

    function getCookie(name) {
        let cookieValue;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                let cookie1 = cookie.trim();
                if (cookie1.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie1.substring(name.length + 1));
                    break;
                }
            }
        }
            return cookieValue;
    }

    function isPolicyInLocalStorage(policyNumber){
        let policies = window.localStorage.getItem('policies') ? window.localStorage.getItem('policies').split(";") : "";
        console.log(policies);
        if(policies){
            for (let policy of policies) {
                if(policy === policyNumber){
                    return true;
                }
            }
        }
        return false;
    }

    function addToPolicyInLocalStorage(policyNumber){
        let policies = window.localStorage.getItem('policies');
        if(policies){
            policies = policies + ";" + policyNumber;
        } else {
            policies = policyNumber;
        }
        window.localStorage.setItem('policies', policies);
        console.log(window.localStorage.getItem('policies'));
    }

    useEffect(() => {

        const policyNumber = props.policy?.policyNumber;
        let transactionExists = false;
        let transactions = getCookie('transactions');
        if(transactions && transactions.split('|').includes(policyNumber)){
            transactionExists = true;
        }
        
        if(!transactionExists && !isPolicyInLocalStorage(policyNumber)){
            addToPolicyInLocalStorage(policyNumber);
            const agentCode = props.agent?.agentCode;
            const price = props.policy?.policyHome?.policyPremium?.totalPremium;
            const campaignCode = props.campaign?.campaignCode;
            const planName = getPlanName(props.policy?.policyHome?.planType);
            const occupancyDesc = props.policy?.policyHome?.occupancyTypeDesc;
            const housingType = getHousingType(props.policy?.policyHome?.housingCode);
            const province = props.policy?.policyHome?.propertyProvinceDesc;
            const city = props.policy?.policyHome?.propertyAreaDesc;
            const district = props.policy?.policyHome?.propertyDistrictDesc;
            const promoCode = props.policy?.promo ? props.policy?.promo : "N/A";

            //Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             'event': 'vpageview',
             'pageStep': 'Finish',
             'vPath':'/finish',
              'ecommerce': {
                'purchase': {
                  'actionField': {
                    'id': policyNumber,                         // Policy Number. Replace H1234567 with actual number
                    'affiliation': agentCode,	            // Agent number. Replace 778888 with actual agent number
                    'revenue': price,                       // Net premium. Replace 555 with actual premium pay by customer
                    'coupon': campaignCode                            // Campaign Code. Replace MILAAA with actual campaign in that transaction
                    },
                  'products': [{                            // List of productFieldObjects.
                    'name': planName,           // Product name. Replace Gold with actual product plan in that transaction
                    'variant': occupancyDesc,                 // Occupancy Type in the policy.
                    'price': price,                        // Net premium. Replace 555 with actual premium pay by customer
                    'dimension1': housingType,           //Apartment. Replace with actual housing type selected in transaction e.g. landlord
                    'dimension14': province,	//Province
                    'dimension15': city,	//City
                    'dimension16': promoCode,
                    'dimension17': district,	//District
                    'quantity': '1',                        // product quantity. Please use 1 as the value.
                   }]
                }
              }
            });
        }
    }, []);

    return (
        <Finish>
        </Finish>
    )
}
const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        agent: _getAgent(state),
        campaign: _getCampaign(state),
    }
}

export default withRouter(connect(mapStateToProps, null)(FinishPage));
