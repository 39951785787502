import * as type from "./types";
import { updateObject } from "../../utils/utility";
import _ from "lodash";

const INITIAL_STATE = {
    productType: 'HPK',
    country: 'VNM',
    agentCode: '',
    campaign: '',
    promo:'',
    paymentSuccess: 'false',
    subAgentId: '',
    reactB2CProduct: true,
    policyHome: {
        riskNumber: 0,
        promotionCode: '',
        country: 'VNM',
        inceptionDate: '',
        effectiveDate: '',
        expiryDate: '',
        planType: '',
        occupancyType: '',
        occupancyTypeDesc: '',
        grossFloorArea: '',
        grossFloorAreaDesc: '',
        buildingType: '',
        buildingTypeDesc: '',
        numberOfEmployees: 0,
        unspecifiedValuables: '',
        unspecifiedValuablesPlan: '',
        unspecifiedValuablesPlanDesc: '',
        totalSpecifiedValuables: '',
        accountNumber: '',
        housingCode: '',
        applicant: {
            clientKey: '',
            clientType: 'P',
            fullName: '',
            additionalName: '',
            dateOfBirth: '',
            hkId: '',
            uen: '',
            mobileNoCountryCode: '84',
            mobileNo: '',
            email: '',
            idType: '',
            directMarketing: 'false',
            forAttentionOf: '',
            applicantAddress1: '',
            applicantAddress2: '',
            applicantArea: '',
            applicantAreaDesc: '',
            applicantDistrict: '',
            applicantDistrictDesc: '',
            applicantAccumulationRegister: '',
            applicantUnitNo: '',
            applicantFloorNo: '',
            applicantBuildingName: '',
            applicantBuildingStreetNo: '',
            applicantPostCode: '',
            applicantCountry: '',
            applicantStreetName: '',
            sameAsInsuredAddress: true,
            hasInterestedParty: '',
        },
        propertyAddress1: '',
        propertyAddress2: '',
        propertyArea: '',
        propertyAreaDesc: '',
        propertyDistrict: '',
        propertyDistrictDesc: '',
        propertyPostCode: '',
        propertyStreetName: '',
        propertyBuildingName: '',
        propertyStreetNumber: '',
        propertyFloorNumber: '',
        propertyUnitNumber: '',
        propertyAccumulationReg: '',
        propertyProvince: '',
        propertyProvinceDesc: '',
        policyPremium: {
            planCode: '',
            planDesc: '',
            premium: '',
            ecLevy: '',
            levy: '',
            gst: '',
            totalPremium: 0.00,
            commission: '',
            commissionRate: '',
            discount: '',
            campaignDiscount: '',
            groupDiscount: '',
            insuredCommissionDiscount: '',
            premiumTopUp: []        
		},
        premiumItems: [],
        planItems: [],
        totalDueAmount: 0.00,
        totalDiscount: 0.00,
        campaignDiscountRate: 0.00,
        totalIALevy: 0.00,
        totalVat: 0.00,
        creditCardNumber: '',
        cardHolderName: '',
        cardExpiryDate: '',
        cvv: '',
        declaration: '',
        agreement: '',
        policyNumber: '',
        receiptNumber: '',
        rating: '',
        ratingReason: '',
        ratingReasonDetail: '',
        device: '',
        buildingsSumInsured:0.00,
        additionalSumInsured:0.00,
        employeeList:[],
        specifiedValuablesList:[]
    }
};

const UNCHANGED_INITIAL_STATE = _.cloneDeep(INITIAL_STATE);

const INITIAL_PERSON_STATE = {
    lastName: '',
    firstName: '',
    hkId: '',
    passportNumber: '',
    dob: ''
}

const INITIAL_CHILD_STATE = {
    lastName: '',
    firstName: '',
    hkId: '',
    passportNumber: '',
    dob: '',
    fullTimeStudent: ''
}

const INITIAL_PLANS_STATE = {
    plans: []
}

const INITIAL_PLAN_STATE = {
    plan: '',
    originalAmount: '',
    totalAmount: '',
    deathOfPermanentDisablement: '',
    accidentalMedicalExpenses: '',
    emergencyAssistanceService: '',
    accidentalDeathBenefit: '',
    additionalMedicalExpense: '',
    brokenBones: '',
    comaBenefit: '',
    creditCardProtection: '',
    educationFund: '',
    funeralExpenses: '',
    homeNursingAssistance: '',
    mobilityAid: '',
    personalEffects: ''
}

const storePolicy = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

export const policyReducer = (state = INITIAL_STATE, action) => {
    switch ( action.type ) {
        case type.POLICY_STORE:
            return storePolicy(state, action);
        case type.POLICY_FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        case type.RESET_POLICY:
            return _.cloneDeep(UNCHANGED_INITIAL_STATE);
        default:
            return state;
    }
}


