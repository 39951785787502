import React from "react";
import {_hideModal, _showError, _showModal, _showSpinner, _hideSpinner} from "../store/system/service";
import {_saveQuote, _getPolicy, _getSaveForLaterToken} from "../store/policy/service";
import axios from 'axios';
import TextField from "../ui/components/form-fields/text-field";
import {qnectFormValidator} from "../components/qnect-ui/qnect-validator";
import {nationalityOptions} from "./nationality-options";


export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }

    if (rules.required) {
        isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export const _validateForm = (formId) => {
    let valid = true;
    if (qnectFormValidator.checkForm(formId).isValid !== true) {
        valid = false;
    }

    return valid;
}

export function _formatAmount(amount, locale, prefix){
    let value = _removeFormatting(amount);

    if(_isNotDecimalChar(value)){
        return "";
    }

    let _prefix = !prefix || prefix == "currency.prefix" ? "$" : prefix;

    return (_prefix || "") + Intl.NumberFormat(locale || "en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0
    }).format(value);
}

export function _removeFormatting(value){
    if(!!value){
        return "".concat(value).replace(/[^0-9.]/g, "");
    } else {
        return "";
    }
}

export function _isNotDecimalChar(value){
    if(!value || isNaN(value)){
        return true;
    } else {
        return false;
    }
}

let scrollTimer = null;

export function _scrollToTop(){
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const page = document.querySelector('#Template')

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                page.scrollTop=0
            } else {
                page.scrollTo({top: 0, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}
export function _scrollToComponent(ref) {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const page = document.querySelector('#Template')

    // prevent multiple call to scroll.
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                page.scrollTop=ref?.current?.offsetTop
            } else {
                page.scrollTo({top: ref?.current?.offsetTop, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}

export function _scrollToComponentAbs(ref){
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const offsetTop = window.innerWidth>768?114:56
    // prevent multiple call to scroll.
    const page = document.querySelector('#Template')
    console.log(page.scrollY)
    if(!scrollTimer){
        scrollTimer = setTimeout(function(){
            if(isIE){
                page.scrollTop = page.scrollTop + ref?.current?.getBoundingClientRect().top-offsetTop
            } else {
                page.scrollTo({top: page.scrollTop + ref?.current?.getBoundingClientRect().top-offsetTop, behavior: 'smooth'});
            }
            scrollTimer = null;
        }, 500);
    }
}

export function _gaPushEvent(event) {
    console.log('ga_event', event);
    window.dataLayer = window.dataLayer || [];
    try {
        window.dataLayer.push({'event': event});
    } catch(ex) {
        console.error('Error in GA push data: ', ex);
    }
}

export function _gaPushData(data) {
    console.log('ga_push', data);
    window.dataLayer = window.dataLayer || [];
    try{
        window.dataLayer.push(data);
    } catch (ex) {
        console.error('Error in GA push event: ', ex);
    }
}

export async function _sendLog(message, type) {
    return new Promise(function(resolve, reject) {
        if(process.env.REACT_APP_SEND_LOG && message){
            let data = ''
            if(Array.isArray(message)){
                data = message.join(', ')
            } else {
                data = message;
            }
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            axios.post(server + process.env.REACT_APP_SEND_LOG + (!type ? 'error' : type), {'data': data})
                .then(resp => {
                    resolve(resp.data);
                })
                .catch(error => {
                    resolve();
                });
        }
    })
}

export function getParameterValueFromUrl (url, parameterName)  {
    let harshValue = '';
    if (url) {
        const harshArray = String(url).split("/");
        const paraNames = ['partner','campaign','promo','lang','quote'];
        if (parameterName && Array.isArray(harshArray) && harshArray.length > 0) {
            const position = harshArray.findIndex(item => { return String(item).toLowerCase() === parameterName.toLowerCase()})
            if (position >= 0) {
                const tempValue  = harshArray[position+1];
                if (tempValue && !paraNames.includes(String(tempValue).toLowerCase())) {
                    harshValue = tempValue
                }
            }
        }
    }
    return harshValue;
}
export function existsFromHash (urlHash, keyOnHarsh)  {
    let harshValue = '';
    if (urlHash) {
        const harshArray = String(urlHash).split("/");
        //const keywordOnHarsh = ['agent','campaign','promo','lang','quote'];
        if (keyOnHarsh && Array.isArray(harshArray) && harshArray.length > 0) {
            const position = harshArray.findIndex(item => { return String(item).toLowerCase() === keyOnHarsh.toLowerCase()})
            if (position >= 0) {
                const tempValue  = harshArray[position];
                if (tempValue) {
                    harshValue = tempValue.toUpperCase();
                }
            }
        }
    }
    return harshValue;
}

export function getPaymentValueFromUrl(url, parameterName)  {
    let harshValue = '';
    if (url) {
        const harshArray = String(url).split("&");
        console.log("harshArray="+harshArray);
        const paraNames = ['order_no','checksum','totalAmount','paymentFee'];
        if (parameterName && Array.isArray(harshArray) && harshArray.length > 0) {
            console.log("Inside1="+parameterName);
            const position = harshArray.findIndex(item => { return String(item).toLowerCase().startsWith(parameterName.toLowerCase())})
            console.log("position1="+position);
            if (position >= 0) {
                console.log("position="+harshArray[position]);
                const tempValue  = harshArray[position];
                if (tempValue && !paraNames.includes(String(tempValue).toLowerCase())) {
                    harshValue = tempValue.split("=")[1];
                }
            }
        }
    }
    return harshValue;
}

export function getPathNameFromUrl(pathName,pathSearch)  {
    const hostname = window.location.hostname;
    let localPathName = '';
    if (pathName && pathSearch) {
        if (hostname === 'localhost' && pathName.includes("/")) {
            localPathName = pathName +pathSearch.replace('?','');
            return localPathName;
        }
    }
    return pathName;
}


export function getCountryFromUrl()  {
    const hostname = window.location.hostname;
    const countryParamValue = getQueryParamValueByName (window.location.search, "country");

    if (hostname.includes("vmn-") || countryParamValue?.toUpperCase() === "VNM") {
        return "VNM";
    } else if (hostname.includes("hk-") || countryParamValue?.toUpperCase() === "HKG") {
        return "HKG";
    } else {
        return "VNM";
    }
}

export function getQueryParamValueByName (urlStr, paramName)  {
    const nameStr = paramName.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + nameStr + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(urlStr);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    const decodedResult = decodeURIComponent(results[2].replace(/\+/g, ' '));
    if (decodedResult) {
        return decodedResult;
    }
    return '';
}

export function getOptionalCoverForGA(policyHome) {
    let optionValue = '';
    if (policyHome) {
        const planType = policyHome?.planType;
        if (planType === 'PS') {
            optionValue = optionValue + 'Plan-Prestige';
        } else if (planType === 'PM') {
            optionValue = optionValue + 'Plan-Premier';
        }

        const optionalCoversList = policyHome?.optionalCoversList;
        if (Array.isArray(optionalCoversList) && optionalCoversList.length > 0 ) {
            optionalCoversList.forEach((optionalCover)=>{
                if (optionalCover) {
                    if (optionalCover?.label === "quick_quote.optionalCovers.unspecified_valuable_plan") {
                        optionValue = optionValue + '-Personal-Valuables';
                        if (optionalCover?.coverValue === "PA") {
                            optionValue = optionValue + '-Plan-A';
                        } else if (optionalCover?.coverValue === "PB") {
                            optionValue = optionValue + '-Plan-B';
                        }
                    }
                    else if (optionalCover?.label === "quick_quote.optionalCovers.lbl_specified_valuable") {
                        optionValue = optionValue + '-Specified';
                    }
                    else if (optionalCover?.label === "quick_quote.optionalCovers.domestic_employers_liability") {
                        optionValue = optionValue + '-Domestic-Worker';
                        if (!isNaN(optionalCover?.coverValue)) {
                            optionValue = optionValue + '-'+optionalCover?.coverValue;
                        }
                    }
                }
            });
        }
    }
    return optionValue;
}

export function mapBr(str) {
    if (str) {
        return str.split('\n').map((item, i) => {
            return <>{item}<br/></>;
        });
    }
}

export function getNationalityDesc(nationalityCode) {
    let desc = "";
    if (nationalityCode) {
        const option =  nationalityOptions.find((option)=>{
            return option.optionValue === nationalityCode
        });
        if (option) {
            desc = option.optionLabel;
        }
    }
    return desc;
}

