import {_getCountry, _getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import React from "react";
import styled from "styled-components";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicy, _storePolicy} from "../../store/policy/service";
import Info from "../../assets/info.png";
import BalloonTip from "../balloon-tip/balloon-tip";
import TagManager from "react-gtm-module";
import './price-summary.css'
import InfoIcon from "../../assets/info_outline_darkblue.svg";
import InfoDarkIcon from "../../assets/info_darkblue.svg";
import QBEBalloonTip from "../ui/qbe-balloon-tips";


const mapBr = (str)=>str.split('\n').map((item, i) => {
    return <>{item}<br/></>;
});

const PriceSummary = (props) => {
    const cfg = useConfig(props.lang);
    const isForHK = props.country === "HKG";

    const totalPremium = Number(props.policy?.policyHome?.policyPremium?.totalPremium);
    const tax = totalPremium - props.policy?.policyHome?.policyPremium?.premium;
    const taxLabel = "vat.label";
    const premiumUnit = "VND";

    const getPremium = (totalPremium, tax)=>{
        let premium = 0;
        if (totalPremium && !isNaN(totalPremium)) {
           if (tax && !isNaN(tax)) {
               premium = Number(totalPremium) - Number(tax);
           } else {
               premium = totalPremium;
           }
        }
        return premium.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0});
    }

    return (
        <>
            <div className={"info-card InfoBox PriceSummary"}>
                <div className="info-row InfoItems">
                    <div className="info-heading InfoHeading">
                        {cfg("premium.label")} + {cfg(taxLabel)}
                    </div>

                    <div className="info-row-nw InfoContent price">
                        {premiumUnit}{getPremium(totalPremium,tax)} + {premiumUnit}{tax ? tax.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0}) : 0}
                    </div>
                </div>
                <div className="infoVLine InfoVLine"></div>
                <div className="info-row InfoItems">
                    <div className="info-heading InfoHeading">
                        {cfg("totalPremium.label")}
                    </div>
                    <div className="info-row-nw InfoContent price1">
                        {premiumUnit}{totalPremium.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}
                    </div>
                </div>
            </div>
        </>

    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state),
        country: _getCountry(state)
    }
}

export default connect(mapStateToProps, null)(PriceSummary);
