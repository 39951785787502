import React from 'react';
import propTypes from 'prop-types';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import get from 'lodash/get';

import qbeTheme from '../themes/qbe';
import {breakpoints} from './breakpoints';
import ConfigContext from './config-context';

// Default MUI theme with our own breakpoints and overrides
export function muiTheme(theme) {
    return createTheme({
        breakpoints: {
            values: {
                // QBE breakpoints
                ...breakpoints,

                // More breakpoints to appease MUI
                lg: 1280,
                xl: 1920,
            },
        },
        overrides: {
            MuiBackdrop: {
                root: {
                    backgroundColor: 'transparent',
                },
            },
            MuiPaper: {
                rounded: {
                    borderRadius: 4,
                },
            },
            MuiDialogActions: {
                root: {
                    justifyContent: 'center',
                    borderTop: `1px solid ${get(theme, 'color.grey30')}`,

                },
            },
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: get(theme, 'color.primary'),
                    height: 70,
                    fontFamily: 'times',
                },
            },
            MuiPickersToolbarText: {
                toolbarTxt: {
                    color: get(theme, 'color.white'),
                },
            },
            MuiPickersDay: {
                day: {
                    'color': get(theme, 'color.tertiary90'),
                    '&:hover': {
                        backgroundColor: get(theme, 'color.grey40'),
                    },
                },
                current: {
                    color: get(theme, 'color.primary'),
                },
                daySelected: {
                    'backgroundColor': get(theme, 'color.primary'),
                    '&:hover': {
                        backgroundColor: get(theme, 'color.primary90'),
                    },
                },
                dayDisabled: {
                    color: get(theme, 'color.grey50'),
                },
            },
            MuiPickersCalendarHeader: {
                dayLabel: {
                    color: get(theme, 'color.black'),
                    textTransform: 'uppercase',
                },
            },
            MuiPickersBasePicker: {
                pickerView: {
                    justifyContent: 'flex-start',
                    minHeight: 312,
                },
            },
            MuiPickersCalendar: {
                transitionContainer: {
                    minHeight: 172,
                },
            },
            MuiToolbar: {
                gutters: {
                    paddingLeft: 24,
                    paddingRight: 24,
                },
            },
            MuiDialog: {
                paperScrollPaper: {
                    maxHeight: 'calc(100% - 24px)',
                },
            },
            MuiTypography: {
                h4: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.heading.size.normal.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.heading.fontWeight.medium'),
                    lineHeight: `${get(theme, 'typography.heading.size.normal.lineHeightPx.desktop')}px`,
                },
                subtitle1: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.text.size.small.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.text.fontWeight.regular'),
                },
                body1: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.text.size.normal.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.text.fontWeight.medium'),
                    color: get(theme, 'color.tertiary90'),
                },
                body2: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.text.size.small.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.text.fontWeight.medium'),
                },
                caption: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.text.size.xSmall.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.text.fontWeight.regular'),
                },
            },
            MuiButton: {
                root: {
                    fontFamily: get(theme, 'typography.text.fontFamily'),
                    fontSize: get(theme, 'typography.text.size.small.fontSizePx.desktop'),
                    fontWeight: get(theme, 'typography.text.fontWeight.semiBold'),
                    textTransform: 'none',
                    color: get(theme, 'color.primary'),
                },
                textPrimary: {
                    'borderRadius': 999,
                    '&:hover': {
                        backgroundColor: get(theme, 'color.grey40'),
                    },
                    '&:focus': {
                        backgroundColor: get(theme, 'color.grey40'),
                    },
                },
            },
            MuiIconButton: {
                root: {
                    'color': get(theme, 'color.tertiary90'),
                    '&:disabled': {
                        color: get(theme, 'color.grey50'),
                    },
                },
            },
        },
        props: {
            MuiButtonBase: {
                disableRipple: true,
            },
        },
    });
}

// Theme provider for this component library.
export default function ConfigProvider({
    theme = qbeTheme,
    imageBaseUrl = '',
    inputDateFormat,
    alertLevels,
    autoCompleteForms = true,
    children,
}) {
    return (
        <StyledComponentsThemeProvider theme={{...theme, imageBaseUrl}}>
            <MuiThemeProvider theme={muiTheme(theme)}>
                <ConfigContext.Provider value={{inputDateFormat, alertLevels, autoCompleteForms}}>
                    {children}
                </ConfigContext.Provider>
            </MuiThemeProvider>
        </StyledComponentsThemeProvider>
    );
}

ConfigProvider.propTypes = {
    theme: propTypes.object.isRequired,
    imageBaseUrl: propTypes.string,
    inputDateFormat: propTypes.string,
    alertLevels: propTypes.shape({
        info: propTypes.string.isRequired,
        success: propTypes.string.isRequired,
        warning: propTypes.string.isRequired,
        error: propTypes.string.isRequired,
    }),
    autoCompleteForms: propTypes.bool, // eslint-disable-line react/boolean-prop-naming
    children: propTypes.node,
};
