import * as type from "./types";
import { updateObject } from "../../utils/utility";

const INITIAL_STATE = {
    addresses: []
};

export function addressReducer(state = INITIAL_STATE, action) {
    switch ( action.type ) {
        case type.SET_ADDRESS:
            return updateObject(
                state,
                {addresses: action.addresses}
            );
        case type.FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        default:
            return state;
    }
}
