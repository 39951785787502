import React, {Component, useEffect} from 'react';
import Quote from "../components/quote/quote";
import TagManager from "react-gtm-module";

const QuotePage = (props) => {

    useEffect(() => {
        //Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        TagManager.dataLayer({
            dataLayer: {
                'pageStep': 'Quote',
                'vPath': '/quote',
                'event': 'vpageview',
                'ecommerce': {
                    'checkout': {
                        'actionField': {
                            'step': 2
                        },
                    }
                }
            }
        });
    }, []);

    return (
        <React.Fragment>
            {
                <Quote />
            }
        </React.Fragment>
    )

}

export default QuotePage;


