import React, {useEffect} from 'react';
import Apply from "../components/apply/apply";
import TagManager from "react-gtm-module";
import {connect} from "react-redux";
import {_getPolicy} from "../store/policy/service";
import {withRouter} from "react-router";

const ApplyPage = (props) => {
    useEffect(() => {
        //Google Tag Manager
        const tagManagerArgs = {
            dataLayer: {
                'pageStep': 'Apply – Optional Cover',
                'vPath': '/apply-optional-cover',
                'event': 'vpageview',
            }
        };
        TagManager.dataLayer(tagManagerArgs);
    }, []);

    return (
        <Apply>
        </Apply>
    )
}
const mapStateToProps = (state) => {
    return {
        policy: _getPolicy(state),
    }
}
export default withRouter(connect(mapStateToProps, null)(ApplyPage));
