import React from 'react'
import {withRouter} from "react-router";
import styled from 'styled-components';
import {ReactComponent as BackToTop} from '../../assets/backtotop.svg';
import {_scrollToTop} from "../../utils/utility";
import {_getCountry, _getInitialURL, _getLang, _hideModal, _showError, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import useConfig from "../qnect-ui/qnect-config";
import Button from "../ui/button";
import './footer.css'
import QBELogo from '../../assets/QnectQBE_logo.png';
import {ReactComponent as QBELogo1} from '../../assets/QBELogo.svg';
import TagManager from "react-gtm-module";
import AppVersion from '../version';

const FooterStyle = styled.div`
  background-color: #14284B;
  width: 100vw;
  text-align: left;
  color: #FFFFFF;
  font-family: Arial, "Noto-Regular";
  font-size: 12px;
  letter-spacing: 0;
  line-height: ${({lang})=>lang==="en"?14:18}px;
  position: relative;
  span.link{
    cursor: pointer;
  }
  hr.LineBreak{
    background-color: #00abf0;
    border:none;
    margin-left: -7px;
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #4EC2F6;
    opacity: 0.5;
  }
  .ContactUs {
      border-radius: 50px;
      background: white;
      color: #00abf0;
      height: 44px;
      width: 126px;
      cursor: pointer;
      margin-left: 7px;
  }
  .ContactUsLabel {
    height: 44px;
    width: 120px;
    font-family: Inter;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: 25px;
    z-index: 99;
  }
  .hyperlink {
      text-decoration: none;
      color: white;
  }
  .box1 {
    width: 80%;
    display:inline-block;
    border-radius:5px;
  }
  .box2 {
    width: 10%;
    display:inline-block;
    border-radius:5px;
  }
  .box3 {
    width: 10%;
    display:inline-block;
    margin:10px 0;
    border-radius:5px;
  }
    .text {
        height: 24px;
        color: #FFFFFF;
        font-family: Inter;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        margin: 0px 10px 0px 7px;
    }
    #container {
        white-space:nowrap;
        margin-top: -10px;
    }
    .QBELogo {
        width: 96px;
        height: 28px;
    }
    .LogoBox {
        display:inline-flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .UpperFooterLinks {
        color: #FFFFFF;
        font-family: Inter;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;
    }
    .CopyrightBox {
        display:inline-block;
        text-align: left;
        margin-left: 33.07px;
        margin-top: -12px;
        font-family: Inter;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 38px;
    }
    .CopyrightNotice {
        margin-top: -20px;
    }
    .ContactUsLink {
        cursor: pointer;
        text-decoration: none;
        color: #003DA5;
    }
    .Version {
        font-family: Inter;
        font-size: 12px;
        letter-spacing: 0;
    }
    @media(max-width: 1024px){
        padding-bottom: ${({mobileSpace})=>mobileSpace?"70":"0"}px;
    }
`;

const FooterContainer = styled.div`
  padding: 25px 0 17px 0;
  margin: 0 auto;
  width: 1190px;
  @media (max-width: 1440px){
    min-width: 620px;
    width: 80vw;
    max-width: 80vw;
  }
  @media (max-width: 1024px){
    display: none;
  }
`;
const StyledBackToTop = styled(BackToTop)`

`;


const mapBr = (str)=>str.split('\n').map((item, i) => {
    return <span key={i}>{item}<br/></span>;
});

const contactUs = (url) => {
    window.open(url, "_blank");
}



const Footer = ({mobileSpace,...props})=>{
    const cfg = useConfig(props.lang, props.country);
    const jumpProduct = (productRoute, newTab)=>{
        const contextPath = (process.env.REACT_APP_ENV  === 'dev') ? '' : productRoute;
        if(newTab){
            var win = window.open(window.location.origin + contextPath + props.initialURL, '_blank');
            win.focus();
            return
        }
        window.location.href = window.location.origin + contextPath + props.initialURL
    }
    return (<FooterStyle lang={props.lang} mobileSpace={mobileSpace}>
        <div className="LandingContainer BTTContainer">
            <StyledBackToTop className={"BackToTopBtn"} onClick={_scrollToTop}/>
        </div>

        <FooterContainer>
            <div className="UpperFooterLinks">
                <span className="link">
                    <a className="hyperlink" href={cfg("footerAboutUsUrl.label")} target="_blank" onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'about-us',
                            }
                        });
                    }}>{cfg("footerAboutUsTitle.label")}</a>
                </span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span className="link">
                    <a className="hyperlink" target="_blank" onClick={()=>{
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': 'footer-home',
                            }
                        });
                        jumpProduct('/home')
                    }}>{ cfg("footerHomePrestigePackageTitle.label")}</a>
                    </span>
            </div>
            <div id="container">
                <div className="box1">
                    <div className="text"><hr className="LineBreak"/></div>
                </div>
                <div className="box2">
                    <div className="text">{cfg("footerNeedHelpsTitle.label")}</div>
                </div>
                <div className="box3 ContactUsLink">
                    <div className="text">
                        <Button className="ContactUs" onClick={()=>{
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'contact-us',
                                }
                            });
                            return contactUs(cfg("footerContactUsUrl.label"));
                        }} color={"primary"} isSmall>
                            <span className="ContactUsLabel">
                                <a className="ContactUsLink" href={cfg("footerContactUsUrl.label")} target="_blank">
                                 {cfg("footerContactUsTitle.label")}
                                </a>
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
            <div id="container">
                <div className="LogoBox">
                    <img className="QBELogo" src={QBELogo}></img>
                </div>
                <div className="CopyrightBox">
                    <span className="link"><a className="hyperlink" href={cfg("footerQBEURL.label")} target="_blank">{cfg('footerCountryTitle.label')}</a></span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <span className="link"><a className="hyperlink" href={cfg("footerTermsOfUseUrl.label")} target="_blank">{cfg("footerTermsOfUse.label")}</a></span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <span className="link"><a className="hyperlink" href={cfg("footerPrivacyPolicyUrl.label")} target="_blank">{cfg("footerPrivacyPolicy.label")}</a></span>
                    <AppVersion/>
                    <div className="CopyrightNotice">
                        { mapBr(cfg("footerCopyrightNotice.label"))}
                    </div>
                </div>

            </div>
            <br/>
        </FooterContainer>
        <div className="MobileFooter">
            <div className="SiteMap">
                <div className="Site"><a className="hyperlink" href={cfg("footerAboutUsUrl.label") } target="_blank">{cfg("footerAboutUsTitle.label")}</a></div>
                <div className="Site" onClick={()=>{
                    _scrollToTop()
                }}><a className="hyperlink">{cfg("footerHomePrestigePackageTitle.label")}</a></div>
            </div>

            <div className="MobileNeedHelp">
                <div className="Bar"></div>
                {cfg("footerNeedHelpsTitle.label")}
            </div>
            <Button className="ContactUs" onClick={()=>{contactUs(cfg("footerContactUsUrl.label"))}} color={"white"} >
                {cfg("footerContactUsTitle.label")}
            </Button>
            <div className="LogoBox">
                <QBELogo1 className="QBELogo"/>
            </div>
            <div className="QbeLinkContainer">
                <span className="link"><a className="hyperlink" href={cfg("footerQBEURL.label")} target="_blank">{cfg(`footerCountryTitle.label`)}
                </a></span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span className="link"><a className="hyperlink" href={cfg("footerTermsOfUseUrl.label")} target="_blank">{cfg("footerTermsOfUse.label")}</a></span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span className="link"><a className="hyperlink" href={cfg("footerPrivacyPolicyUrl.label")} target="_blank">{cfg("footerPrivacyPolicy.label")}</a></span>
            </div>
            <AppVersion/>
            <div className="MobileCopyrightNotice">
                { mapBr(cfg("footerCopyrightNotice.label"))}
            </div>
        </div>
    </FooterStyle>)
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        country: _getCountry(state),
        initialURL: _getInitialURL(state),
    }
};

// send service request to redux
const mapDispatchToProps = {
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
