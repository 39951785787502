
export const POLICY_STORE = 'POLICY_STORE';
export const POLICY_FETCH_FAILED = 'POLICY_FETCH_FAILED';
export const STORE_PRODUCT = 'STORE_PRODUCT';
export const STORE_BUSINESS_SIZE = 'STORE_BUSINESS_SIZE';
export const STORE_UEN = 'STORE_UEN';

export const ANZSIC_SGPPACK_LIST = [
    'SGP-511000',
    'SGP-512000',
    'SGP-520000',
    'SGP-523100',
    'SGP-525300',
    'SGP-526000',
    'SGP-525100',
    'SGP-523000',
    'SGP-524000',
    'SGP-952200',
    'SGP-786800',
    'SGP-785405',
    'SGP-785401',
    'SGP-785407',
    'SGP-785408',
    'SGP-785409',
    'SGP-785402',
    'SGP-785411',
    'SGP-785412',
    'SGP-785413',
    'SGP-785414',
    'SGP-785403',
    'SGP-785416',
    'SGP-785417',
    'SGP-785418',
    'SGP-963500',
    'SGP-885405',
    'SGP-985405'];

export const ANZSIC_HKGPACK_LIST = [
    ''];

export const POLICY_SET_CAMPAIGN = "POLICY_SET_CAMPAIGN";
export const POLICY_SET_PROMO = "POLICY_SET_PROMO";
export const POLICY_SET_AGENTCODE = "POLICY_SET_AGENTCODE";
export const RESET_POLICY = "RESET_POLICY";
