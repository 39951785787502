import {connect} from 'react-redux';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import styled from 'styled-components'
import "./landing.css"
import {withRouter} from "react-router";
import Button from "../ui/button";
import Template from "../template";
import {ReactComponent as WhiteCross} from "../../assets/WhiteCross.svg";
import {ReactComponent as MobileMoneyIcon} from "../../assets/eclaims.svg";

import {
    _getCampaignImage,
    _getCountry,
    _getInitialURL,
    _getLang,
    _getSystemAvailable,
    _getValidAgent,
    _hideModal,
    _hideSpinner,
    _setAgentNo,
    _setCampaignImage,
    _setValidAgent,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicy, _ratePolicy, _storePolicy} from "../../store/policy/service";
import QnectSelectField from "../qnect-ui/qnect-select-field";
import {
    _scrollToComponentAbs,
    getCountryFromUrl,
    getParameterValueFromUrl,
    getPathNameFromUrl
} from "../../utils/utility";
import {_getCampaign, _setCampaignDetails, _storeCampaign, _validateCampaignPromo} from "../../store/campaign/service";
import {CAMPAIGN_INITIAL_STATE} from "../../store/campaign/reducers";
import {cloneDeep} from 'lodash';
import UnhappyError from "../../assets/unhappyerror.svg";
import {_findAgentDetails, _findCampaignImage, _findStaffCode, _getAgent} from "../../store/agent/service";
import TagManager from "react-gtm-module";

import {ReactComponent as GetQuoteArrow} from "../../assets/arrowmain.svg";
import {ReactComponent as HomeContentIcon} from "../../assets/home_content.svg";
import {ReactComponent as BuildingIcon} from "../../assets/buildings.svg";
import {ReactComponent as LiabilityIcon} from "../../assets/liability.svg";
import {ReactComponent as BlueArrow} from "../../assets/bluearrow.svg";
import {ReactComponent as FlagIcon} from "../../assets/flag.svg";
import {ReactComponent as MobilePlan} from "../../assets/MobileTab.svg";

import {ReactComponent as MobileFindOutMoreArrow} from "../../assets/Arrow-right@0.5x.svg";
import 'font-awesome/css/font-awesome.min.css';
import {ReactComponent as WhiteCoupon} from "../../assets/couponwhite.svg";
import {ReactComponent as LandlordIcon} from "../../assets/landlord_outline_white.svg";
import {ReactComponent as TenantIcon} from "../../assets/tenant_outline_white.svg";
import {ReactComponent as LandlordIconDarkBlue} from "../../assets/landlord_outline_darkblue.svg";
import {ReactComponent as TenantIconDarkBlue} from "../../assets/tenant_outline_darkblue2.svg";
import QnectButton from "../qnect-ui/qnect-button";
import QBEBalloonTips from "../ui/qbe-balloon-tips";
import {ReactComponent as LandlordInternalBlueIcon} from "../../assets/tenant_icon.svg";
import {ReactComponent as TenantInternalBlueIcon} from "../../assets/landlord_icon.svg";

import {AssetType, downloadPDF, getAsset} from "../../utils/pdf-asset";
import WhatsApp from "../../assets/whatsapp@2x.png";
import {ReactComponent as Email} from "../../assets/emailbig.svg";
import ErrorBlue from '../../assets/error_blue.svg'
import {PRODUCT_FEATURES} from "../../utils/product-feature";

const StyledPoint = styled.div`
  line-height: ${({lang}) => lang === "en" ? 18 : 27}px;
`;
const MobileBG = styled.div`
    @media(max-width: 1024px){
      ${({bg}) => (bg ? 'background-image:' + bg + ';' : '')}
      
    }
`
let {HOME_CONTENT, BUILDINGS, LIABILITY_TO_OTHERS} = PRODUCT_FEATURES
let ProductFeatures = {
    privateHouse: [
        {
            toggle: true,
            item: HOME_CONTENT,
            icon: <HomeContentIcon className={'homecontent_vn'}/>,
            plan: HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS.privateHouse,
            subItems: [
                HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_THE_OPEN_AIR,
                HOME_CONTENT.child.EMERGENCY_STORAGE,
                HOME_CONTENT.child.OCCUPIER_ADDITIONAL_BENEFITS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX,
                HOME_CONTENT.child.HOME_CONTENTS_TEMPORARY_REMOVED,
                HOME_CONTENT.child.HOME_CONTENTS_DURING_TRANSIT,
                HOME_CONTENT.child.HOME_CONTENTS_AT_A_NEW_SITUATION,
                HOME_CONTENT.child.HOME_CONTENTS_AT_YOUR_WORKPLACE,
                HOME_CONTENT.child.COMPENSATION_FOR_MEDICAL,
                HOME_CONTENT.child.DOMESTIC_HELPERS_PERSONAL,
                HOME_CONTENT.child.FUNERAL_EXPENSES,
                HOME_CONTENT.child.GUESTS_AND_VISITORS,
                HOME_CONTENT.child.KEYS_LOCK_REPLACEMENT,
                HOME_CONTENT.child.TEMPORARY_ACCOMMODATION,
            ],
        },
        {
            toggle: true,
            item: BUILDINGS,
            icon: <BuildingIcon className={'buildings_vn'}/>,
            plan: BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD.privateHouse,
            subItems: [
                BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD,
                BUILDINGS.child.CONTRACT_WORK_ALTERATION,
                BUILDINGS.child.ELECTRIC_GAS_WATER,
                BUILDINGS.child.FIRE_EXTINGUISHING,
                BUILDINGS.child.MODIFICATION_COST,
                BUILDINGS.child.LANDLORD_ADDITIONAL_BENEFITS,
                BUILDINGS.child.RENT_LOSS_UP_TO_6_MONTHS,
            ],
        },
        {
            toggle: true,
            item: LIABILITY_TO_OTHERS,
            icon: <LiabilityIcon className={'liability_vn'}/>,
            plan: LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD.privateHouse,
            subItems: [
                LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD
            ],
        }

    ],
    apartment: [
        {
            toggle: true,
            item: HOME_CONTENT,
            icon: <HomeContentIcon className={'homecontent_vn'}/>,
            plan: HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS.apartment,
            subItems: [
                HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_THE_OPEN_AIR,
                HOME_CONTENT.child.EMERGENCY_STORAGE,
                HOME_CONTENT.child.OCCUPIER_ADDITIONAL_BENEFITS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX,
                HOME_CONTENT.child.HOME_CONTENTS_TEMPORARY_REMOVED,
                HOME_CONTENT.child.HOME_CONTENTS_DURING_TRANSIT,
                HOME_CONTENT.child.HOME_CONTENTS_AT_A_NEW_SITUATION,
                HOME_CONTENT.child.HOME_CONTENTS_AT_YOUR_WORKPLACE,
                HOME_CONTENT.child.COMPENSATION_FOR_MEDICAL,
                HOME_CONTENT.child.DOMESTIC_HELPERS_PERSONAL,
                HOME_CONTENT.child.FUNERAL_EXPENSES,
                HOME_CONTENT.child.GUESTS_AND_VISITORS,
                HOME_CONTENT.child.KEYS_LOCK_REPLACEMENT,
                HOME_CONTENT.child.TEMPORARY_ACCOMMODATION,
            ],
        },
        {
            toggle: true,
            item: BUILDINGS,
            icon: <BuildingIcon className={'buildings_vn'}/>,
            plan: BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD.apartment,
            subItems: [
                BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD,
                BUILDINGS.child.CONTRACT_WORK_ALTERATION,
                BUILDINGS.child.ELECTRIC_GAS_WATER,
                BUILDINGS.child.FIRE_EXTINGUISHING,
                BUILDINGS.child.MODIFICATION_COST,
                BUILDINGS.child.LANDLORD_ADDITIONAL_BENEFITS,
                BUILDINGS.child.RENT_LOSS_UP_TO_6_MONTHS,
            ],
        },
        {
            toggle: true,
            item: LIABILITY_TO_OTHERS,
            icon: <LiabilityIcon className={'liability_vn'}/>,
            plan: LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD.apartment,
            subItems: [
                LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD
            ],
        }
    ]
}
const getHousingType = (type) => {
    switch (type) {
        case 'PU':
            return 'apartment'
        case 'PR':
            return 'privateHouse'
        default:
            return 'apartment'
    }
}
const getProductFeatureByID = (id, country) => {
    switch (id) {
        case 'PR':
            return ProductFeatures.privateHouse
        case 'PU':
            return ProductFeatures.apartment
        default:
            return ProductFeatures.apartment
    }
}
const getAssetTypeDocDownload = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            return downloadPDF(getAsset(assetTypePB));
        case 'PolicyWording':
            return downloadPDF(getAsset(assetTypePW));
        default:
            return downloadPDF(getAsset(assetTypePB));
    }
}
const getOccupancyByID = (id) => {
    switch (id) {
        case '0001':
            return 'landingOccupancyTenant'
        case '0004':
            return 'landingOccupancyOwner'
        case '0003':
            return 'landingOccupancyLandlord'
        default:
            return 'landingOccupancyOwner'
    }
}


const Landing = (props) => {
    const [country, setCountry] = useState(() => {
        return getCountryFromUrl();
    });
    const cfg = useConfig(props.lang, country);
    const formId = "landing";
    const rightRef = useRef(null);
    const pathName = getPathNameFromUrl(props.location.pathname, props.location.search);

    const [insuredPersonTypePicked, setInsuredPersonTypePicked] = useState({
        individual: true,
        family: false,
    });
    const [occupancyTypePicked, setOccupancyTypePicked] = useState("0004");
    const [occupancyTypePickedDesc, setOccupancyTypePickedDesc] = useState(occupancyTypePicked === "0004" ? "Occupier" : "Landlord");
    const [noOfAdultPicked, setNoOfAdultPicked] = useState({
        one: true,
        two: false
    });
    const [housingType, setHousingType] = useState('PU');
    const [agentNumber, setAgentNumber] = useState(() => {
        return getParameterValueFromUrl(pathName, 'partner');
    });
    const [subAgentId, setSubAgentId] = useState(() => {
        return getParameterValueFromUrl(pathName, 'sub');
    });
    //const [systemAvailable, setSystemAvailable] = useState(true);
    const [promotionCode, setPromotionCode] = useState('');
    const [staffCode, setStaffCode] = useState('');
    const [mobilePickedPlan, setMobilePickedPlan] = useState("B");
    const [isPersonSelected, setPersonSelected] = useState(true);
    const [productBenefitsHousingTypePicked, setProductBenefitsHousingTypePicked] = useState("PU");
    const [mobileProductFeatures, setMobileProductFeatures] = useState({
        silver: true,
        gold: false,
        platinum: false
    })
    const hasError = !props.systemAvailable || !props.validAgent;
    const [open, setOpen] = useState(false)

    const [size, setSize] = useState([0, 0]);
    const [errorHeight, setErrorHeight] = useState(100);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
            if (hasError) {
                const errEle = document.querySelector('.TopErrorContainer')
                if (errEle) {
                    setErrorHeight(Math.max(...[errEle.offsetHeight, 100]))
                }
            }

        }

        window.addEventListener('resize', updateSize);
        updateSize();
        setTimeout(() => {
            updateSize()
        }, 1000)
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    useEffect(() => {
        console.log('invalid partner state', hasError, props.systemAvailable, props.validAgent)
        if (hasError || !props.systemAvailable || !props.validAgent) {
            let getEle = setInterval(() => {
                let errEle = document.querySelector('.TopErrorContainer')
                if (!errEle)
                    return
                clearInterval(getEle)
                setErrorHeight(Math.max(...[errEle.offsetHeight, 100]))
                setTimeout(() => {
                    console.log('error here', errEle.offsetHeight)
                    setErrorHeight(Math.max(...[errEle.offsetHeight, 100]))
                }, 1000)
            }, 300)
        }
    }, [hasError, props.systemAvailable, props.validAgent]);

    useEffect(() => {
        const campaignCode = getParameterValueFromUrl(pathName, 'campaign');
        setOccupancyTypePicked("0004");
        setOccupancyTypePickedDesc("Occupier");
        const campaignCountry = getCountryFromUrl();
        console.log("campaignCountry=" + campaignCountry);

        if (campaignCode) {
            const policyParam = cloneDeep(props.policy);
            policyParam.campaign = campaignCode;
            policyParam.country = campaignCountry;
            props.setCampaignDetails(policyParam).then(campaignDetails => {
                if (campaignDetails) {
                    props.storeCampaign(campaignDetails);
                    if (campaignDetails?.campaignId) {
                        props.findCampaignImage(campaignDetails.campaignId).then(resp => {
                            if (resp.image) {
                                const imageUrl = 'url("data:image;base64,' + resp.image + '")';
                                props.setCampaignImage(imageUrl);
                            } else {
                                props.setCampaignImage('');
                            }
                        })
                    }
                }
            }).catch(function (e) {
                props.storeCampaign(CAMPAIGN_INITIAL_STATE);
                props.setCampaignImage('');
            });
        } else {
            props.storeCampaign(CAMPAIGN_INITIAL_STATE);
            props.setCampaignImage('');
        }

        const promo = getParameterValueFromUrl(pathName, 'promo');
        if (promo) {
            setPromotionCode(promo);
        }

        const agentNo = getParameterValueFromUrl(pathName, "partner");
        const country = getCountryFromUrl();
        console.log("country-----" + country);
        if (agentNo) {
            console.log("agentNo-----" + agentNo);
            console.log("campaignCode-----" + campaignCode);
            props.setAgentNo(agentNo);
            let campaignId = campaignCode ? props.campaign?.campaignId : '';
            // props.showSpinner();
            props.hideSpinner();
            props.findAgentDetails(agentNo, campaignCode, country, campaignId, subAgentId).then(resp => {
                if (resp && resp?.agentCode) {
                    setAgentNumber(resp?.agentCode);
                    if (resp?.isDefaultAgent === false) {
                        props.findStaffCode(resp?.agentCode, country, "HPK").then(data => {
                            console.log("STAFF CODE-----" + data);
                            setStaffCode(data);
                            props.hideSpinner();
                        }, (error) => {
                            props.hideSpinner();
                            console.error(error?.response?.data?.message);
                            props.showError([cfg("landingGetQuoteError.message")]);
                        });
                    }
                }
                props.hideSpinner();
                props.setValidAgent(resp?.agentCode && resp?.isDefaultAgent === false);
            });
        } else {
            props.setValidAgent(false);
        }

    }, []);

    function isLandlord() {
        return !!(occupancyTypePicked && String(occupancyTypePicked) === "0003");
    }

    function isOccupier() {
        return !!(occupancyTypePicked && String(occupancyTypePicked) === "0004");
    }

    function hasCampaignDiscount() {
        const campaign = props.campaign;
        const lang = props?.lang
        return !!((lang === "en" && campaign && campaign?.marketingMessage) || (lang !== "en" && campaign && campaign?.marketingMessageCn));
    }

    function updateHousingType(housingType){
        setHousingType(housingType);
        const input = document.querySelector('input[name="housingTypeVN"]');
        const lastValue = input.value;
        input.value = housingType;
        const event = new Event("input", { bubbles: true });
        const tracker = input._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        input.dispatchEvent(event);
    }

    const goNextPage = async () => {
        const policyData = cloneDeep(props.policy);
        //hardcode for now
        policyData.reactB2CProduct = true;
        policyData.policyHome.staffCode = staffCode;
        policyData.policyHome.occupancyType = occupancyTypePicked;
        policyData.policyHome.buildingType = "MS";
        policyData.policyHome.housingCode = housingType;
        policyData.policyHome.occupancyTypeDesc = occupancyTypePickedDesc;


        if (props.campaign && props.campaign?.campaignCode) {
            policyData.campaign = props.campaign?.campaignCode;
        } else {
            policyData.campaign = '';
        }

        if (promotionCode) {
            policyData.promo = promotionCode;
            policyData.policyHome.promotionCode = promotionCode;
        }
        console.log("agentNumber-----------" + agentNumber);
        policyData.agentCode = agentNumber;
        policyData.subAgentId = subAgentId;
        policyData.policyHome.accountNumber = agentNumber;

        console.log("country for policy-----------" + country);
        policyData.country = country;
        policyData.policyHome.country = country;

        props.storePolicy(policyData);
        props.showSpinner();
        props.ratePolicy().then(resp => {
            console.log(resp)
            if (resp.errorMessage) {
                console.error(resp.errorMessage);
            } else {
                props.history.push('/quote');
            }
            props.hideSpinner();
        }, (error) => {
            console.error(error?.response?.data?.message);
            props.hideSpinner();
            props.showError([cfg("landingGetQuoteError.message")]);
        })
    }

    const termsConditions = (props?.lang && props?.lang !== 'en') ? props?.campaign?.termsConditionsCn : props?.campaign?.termsConditionsEn;
    const marketingMessage = (props?.lang && props?.lang !== 'en') ? props?.campaign?.marketingMessageCn : props?.campaign?.marketingMessage;
    let campaignImage = (props.campaign && props.campaign?.campaignCode) ? props.campaignImage : "";
    const divStyle = {
        backgroundImage: campaignImage
    }

    const mapBr = (str) => str.split('\n').map((item, i) => {
        return <span key={i}>{item}<br/></span>;
    });
    const mapListBr = (str) => str.split('\n').map((item, i) => {
        return <li key={i}>{item}</li>;
    });

    const getBalloonTip =(label)=>{
        switch (label) {
            case 'HOME_CONTENT.label':
                return <QBEBalloonTips>{mapBr(cfg('LandingProductFeatureHomeContentsBalloonTip.label'))}</QBEBalloonTips>
            case 'BUILDINGS.label':
                return <QBEBalloonTips>{mapBr(cfg('LandingProductFeatureBuildingsBalloonTip.label'))}</QBEBalloonTips>
            case 'LIABILITY_TO_OTHERS.label':
                return <QBEBalloonTips>{cfg('LandingProductFeaturePersonalAccidentBalloonTip.label')}</QBEBalloonTips>
            default:
                return <></>
        }
    }

    const ProductFeatureRow = ({productFeature, color, children, isSmall, onClick, ...rest}) => {
        let [toggleState, setToggleState] = useState(productFeature.toggle)
        let pSilver = productFeature.plan.silver.endsWith('.label')?cfg( productFeature.plan.silver): productFeature.plan.silver
        let pGold = productFeature.plan.gold.endsWith('.label')?cfg( productFeature.plan.gold): productFeature.plan.gold
        let pPlatinum = productFeature.plan.platinum.endsWith('.label')?cfg( productFeature.plan.platinum): productFeature.plan.platinum
        let mobileP1 = ''
        try{
            mobileP1 = productFeature.subItems[0][getHousingType(housingType)][Object.keys(mobileProductFeatures).filter(x=>mobileProductFeatures[x]==true)[0]]
            mobileP1 = mobileP1.endsWith('.label')?cfg(mobileP1):mobileP1
        }catch (e){
            console.log('product error',e)
        }
        return <>
            <tr>
                <td className={"LandingTableFeaturePaddingTop"}
                    colSpan={4}>
                </td>
            </tr>
            <tr className={"LandingTableRow LandingProductFeature"}>
                <td className={"Icon"}>{productFeature.icon}</td>
                <td className={"Item"} colSpan={3}>
                    <div className={"ProductFeatureItemContainer"}>{cfg(productFeature.item.label)} {getBalloonTip(productFeature.item.label)}</div>
                </td>
                <td className={`Value Limit`}>{pSilver}</td>
                <td className={`Value Limit`}>{pGold}</td>
                <td className={`Value Limit`}>{pPlatinum}</td>
                <td className={"Arrow"} onClick={() => {
                    productFeature.toggle = !toggleState
                    setToggleState(!toggleState)
                }}><BlueArrow className={"LandingProductFeatureArrow " + (!toggleState ? 'Picked' : '')}/></td>
            </tr>
            <tr className={"MobileLandingTableRow LandingProductFeature"}>
                <td className={"Icon"}>{productFeature.icon}</td>
                <td className={"Item"} colSpan={2}>
                    <div className={"ProductFeatureItemContainer"}>
                        {cfg(productFeature.item.label)} {getBalloonTip(productFeature.item.label)}

                    </div>
                    <div >{mobileP1}</div>
                </td>
                <td className={"Arrow"} onClick={() => {
                    productFeature.toggle = !toggleState
                    setToggleState(!toggleState)
                }}><BlueArrow className={"LandingProductFeatureArrow " + (!toggleState ? 'Picked' : '')}/></td>
            </tr>
            <tr>
                <td className={"LandingTableFeaturePaddingBottom"}
                    colSpan={4}>
                </td>
            </tr>
            {
                (!toggleState && productFeature.subItems.map((subItem,index) => {
                    let sub = subItem[getHousingType(housingType)];
                    let productHighlightTitle = productFeature.item.label;
                    let silver = sub.silver.endsWith('.label')?cfg(sub.silver):sub.silver
                    let gold = sub.gold.endsWith('.label')?cfg(sub.gold):sub.gold
                    let platinum = sub.platinum.endsWith('.label')?cfg(sub.platinum):sub.platinum
                    let mobileP = subItem[getHousingType(housingType)][Object.keys(mobileProductFeatures).filter(x=>mobileProductFeatures[x]==true)[0]]
                    mobileP = mobileP.endsWith('.label')?cfg(mobileP):mobileP
                    const getRemarks = () => {
                        if(index === 3 && productHighlightTitle === "HOME_CONTENT.label"){
                            return <b>{cfg("OccupierAdditionalBenefits.label")}</b>;
                        } else if(index === 5 && productHighlightTitle === "BUILDINGS.label"){
                            return <b>{cfg("LandlordAdditionalBenefits.label")}</b>;
                        } else {
                            return <li>{mapBr(cfg(subItem.label))}</li>;
                        }
                    };
                    return <>
                        <tr className={"LandingTableRow LandingProductSubFeature"}>
                            <td className={"Icon"}></td>
                            <td className={"Item"} colSpan={3}>
                                {getRemarks()}
                            </td>
                            <td colSpan={1} className={`Value Limit`}>
                                {silver}
                            </td>
                            <td colSpan={1} className={`Value Limit`}>
                                {gold}
                            </td>
                            <td colSpan={1} className={`Value Limit`}>
                                {platinum}
                            </td>
                            <td></td>
                        </tr>
                        <tr className={"MobileLandingTableRow LandingProductSubFeature"}>
                            <td className={"Item"} colSpan={4}>
                                <div>
                                    {getRemarks()}
                                    <div className={"MobileProductSubFeatureValue"}>
                                        {
                                            mobileP
                                        }
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className={"LandingTableSubFeatureSeparator"}
                                colSpan={4}>
                            </td>
                        </tr>
                    </>
                }))
            }
        </>
    }

    const SystemUnavailableError = () => {
        return (
            <div className="TopErrorContainer">
                <img className="ErrorImg" src={UnhappyError} alt=""/>
                <span className="ErrorText">{cfg("landingSystemUnavailableError.message")}</span>
            </div>
        )
    }

    const findOutMore = () => {
        window.open("https://www.qbe.com/vn/claims/", "_blank");
    }

    const gotoProductFeatures = () => {
        window.location.href = "#ProductFeatures";
    }

    const gotoInsuranceQuotesSection = () => {
        window.location.href = "#InsuranceQuotesForm";
    }

    const setHousingTypeHandler = (val, val1) => {
        console.log('set housing type handler ',val,val1)
        setHousingType(val);
        setProductBenefitsHousingTypePicked(val);
        if(housingTypeError){
            setHousingTypeError(false)
        }
    }

    function getWhatsAppUrl() {
        return "https://api.whatsapp.com/send?phone=852";
    }

    const BiggerThan3000Popup = () => {
        props.showModal(
            {
                title: cfg("BiggerThan3000Popup.label"),
                className: 'BiggerThan3000Container normal',
                content:
                    <div className="BiggerThan3000">
                        <div>{mapBr(cfg('BiggerThan3000.label'))}</div>

                        <ul>
                            <li>{cfg('BiggerThan3000Item1.label')}</li>
                            <li>{cfg('BiggerThan3000Item2.label')}</li>
                            <li>
                                {mapBr(cfg('BiggerThan3000Item3.label'))}
                            </li>
                        </ul>
                        <div>{cfg('BiggerThan3000ContactAgent.label')}</div>
                        <div className="ContactMethods">
                            <a className="ContactMethod WhatsApp" href={getWhatsAppUrl() + (props.agent?.tel || "")}
                               target="_blank" onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactwhatsapp',
                                    }
                                });
                            }}>
                                <img src={WhatsApp} className="WhatsAppImg"/>
                                <span>WhatsApp</span>
                            </a>
                            <div className="VLine"></div>
                            <a className="ContactMethod Email" href={"mailto:" + props.agent?.email} onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactemail',
                                    }
                                });
                            }}>
                                <Email className="EmailImg" alt=""/>
                                <div>{cfg('popupEmail.label')}</div>
                            </a>
                        </div>
                    </div>,
            });
    }
    const openProduct = (productRoute) => {
        const contextPath = (process.env.REACT_APP_ENV === 'dev') ? '' : productRoute;
        const tab = window.open(window.location.origin + contextPath + props.initialURL, '_blank')
        tab.focus()
    }

    const FormRef = useRef(null);

    const Decorators = [{
        position: 'CenterLeft',
        style: {
            padding: 20,
            marginTop: 0
        }
    }];

    const [mobileCampaignDiscountPopup, setMobileCampaignDiscountPopup] = useState(true);
    const PromotionTermsAndConditionsPopup = () => {
        props.showModal(
            {
                title: 'Terms and conditions',
                className: 'promotionTermsAndConditions normal',
                content:
                    <div>
                        {termsConditions}
                    </div>

            });
    }
    const [housingTypeError, setHousingTypeError] = useState(false)
    return (
        <Template raw title=''>

            <div className="LandingTop" style={divStyle}>
                {hasError && <div style={{height: errorHeight}} className="LandingPadding"></div>}
                {/*{true && <div className="LandingPadding"></div>}*/}
                {/*{ !systemAvailable && <SystemUnavailableError /> }*/}
                <div className="LandingTopContainer LandingContainer">
                    <div className={"LandingTopRightImage"}>

                    </div>
                    <MobileBG bg={divStyle.backgroundImage} className={`LandingTopLeft ${"LandingTopLeft_HK"}`}>
                        <div className="LandingProductTitle">{cfg('landingBannerHighlightTitle.label')}</div>
                        <div className="MobileLandingStartQuoteButton">
                            <Button className={"FindOutMoreButton"} onClick={() => {
                                _scrollToComponentAbs(rightRef)
                            }} isSmall>{cfg('landingStartQuoteButton.label')}</Button>
                        </div>
                        <div className="LandingTopProductBenefit">
                            <div className="ListFeatures">
                                <ul>
                                    <li><p
                                        className="ListItemText">{mapBr(cfg('landingListItemCoverDescription_HK.label'))}</p>
                                    </li>
                                    <li><p
                                        className="ListItemText">{mapBr(cfg('landingListItemOptionalCoverDescription_HK.label'))}</p>
                                    </li>
                                    <li><p
                                        className="ListItemText">{mapBr(cfg('landingListItemAdditionalCoverDescription_HK.label'))}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="LandingTopFindOutMoreBtn">
                            <Button className={"FindOutMoreButton"} onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'find-out-more-product-details',
                                    }
                                });
                                gotoProductFeatures()
                            }
                            } isSmall>{cfg('landingFindMoreButton.label')}</Button>
                        </div>
                        <div className="MobileLandingTopFindOutMoreBtn">
                            {cfg('MobileLandingDiscover.label')} <MobileFindOutMoreArrow/>
                        </div>
                    </MobileBG>
                    <div className="LandingTopRight" ref={rightRef}>
                        <div id="InsuranceQuotesForm" className="Title">
                            {cfg("landingInsuranceQuoteTitle.label")}<br/>
                        </div>
                        <div className="LandingFormRow">
                            <div onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'Occupier',
                                    }
                                });
                                setOccupancyTypePicked("0004");
                                setOccupancyTypePickedDesc("Occupier")
                            }}
                                 className={"OutlineIconButton" + (occupancyTypePicked === "0004" ? ' Picked' : '')}>
                                {occupancyTypePicked !== "0004" ? <TenantIcon className="IconGroup"/> :
                                    <TenantIconDarkBlue className="IconGroup"/>}
                                {cfg('landingOccupancyHKTenant.label')}
                            </div>
                            <div onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'Landlord',
                                    }
                                });
                                setOccupancyTypePicked("0003");
                                setOccupancyTypePickedDesc("Landlord")
                            }}
                                 className={"OutlineIconButton Landlord" + (occupancyTypePicked === "0003" ? ' Picked' : '')}>
                                {occupancyTypePicked !== "0003" ? <LandlordIcon className="IconGroup"/> :
                                    <LandlordIconDarkBlue className="IconGroup"/>}
                                {cfg('landingOccupancyHKLandlord.label')}
                            </div>
                        </div>

                        <div className="LandingFormRow Small">
                            <div className="Key">
                                <div className="Title Center">
                                    {cfg('landingHousingTypeLabel.label')}
                                    <QBEBalloonTips mode={'dark'}>
                                    <span className="LandingHousingTypeBalloonTipContent_HK">
                                        {mapListBr(cfg('LandingHousingTypeBalloonTipContent.label'))}
                                    </span>
                                    </QBEBalloonTips>
                                </div>
                            </div>

                            <div className="input Full">
                                <QnectSelectField
                                    field="housingTypeVN"
                                    name="housingTypeVN"
                                    formId={formId}
                                    value={housingType}
                                    label={cfg('landingHousingTypeSelectLabel.label')}
                                    multiline
                                    placeHolder={cfg('selectPlaceholder.label')}
                                    overrideStyle={true}
                                    className="HousingTypeSelector"
                                    onChange={(value, _, setSelected) => {
                                        console.log('onchange', value, setSelected)
                                        setHousingTypeHandler(value, setSelected)
                                    }}>
                                </QnectSelectField>
                            </div>
                        </div>
                        {
                            housingTypeError &&
                            <div className="LandingFormRow NoMB">
                                <div className="error-row">
                                    <img src={ErrorBlue} alt=""/>
                                    {cfg('PleaseSelectError.label')}
                                </div>
                            </div>
                        }

                        <div className="LandingFormRow Notice" >
                            {cfg('landingCorporateCustomerNotice.label')} <br/><br/>
                            {cfg('landingCorporateCustomerNotice2.label')}
                        </div>
                        <div className="LandingFormRow GetQuoteBtnContainer">
                            <Button className="GetQuoteBtn" disabled={hasError} onClick={() => {
                                let eventName = 'get-a-quote-';
                                if (isOccupier()) {
                                    eventName = eventName + "occupier"
                                }else if (isLandlord()) {
                                    eventName = eventName + "landlord"
                                }
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': eventName,
                                    }
                                });
                                if (housingType === 'null') {
                                    setHousingTypeError(true)
                                    return null
                                }
                                return goNextPage()
                            }}>
                                {cfg('landingQuoteButton.label')}&nbsp;&nbsp;<GetQuoteArrow/>
                            </Button>
                        </div>
                    </div>
                </div>
                {hasCampaignDiscount() &&
                <div className={`LandingTopDiscountBanner ${marketingMessage.length >= 10 ? 'long' : 'short'}` + (mobileCampaignDiscountPopup ? "" : " Close")}>
                    <div className="LandingContainer">
                        <div className="DiscountCloseButton">
                            <WhiteCross onClick={() => {
                                setMobileCampaignDiscountPopup(false)
                            }}/>
                        </div>
                        <div className="DiscountBannerContent">
                            <div className="DiscountIconContainer">
                                <WhiteCoupon className={"FinishLearnMoreBannerIcon"}/>
                            </div>
                            <div className={"DiscountContent FlexGrow"}>
                                {marketingMessage}
                            </div>
                            {/*<div className="TermsAndConditionsButton">*/}
                            {/*    <QnectButton className={"LearnMoreButton"} color={"secondary"} onClick={()=>{*/}
                            {/*        TagManager.dataLayer({*/}
                            {/*            dataLayer: {*/}
                            {/*                'event': 'promotion-learn-more',*/}
                            {/*            }*/}
                            {/*        });*/}
                            {/*        PromotionTermsAndConditionsPopup()*/}
                            {/*    }}>*/}
                            {/*        Terms and Conditions*/}
                            {/*    </QnectButton>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                }

            </div>
            <div id="ProductFeatures" className="LandingHeadingTitle">{cfg('productFeaturesTitle.label')}</div>
            <div className={"LandingProductFeatures" + (" HKG")}>
                <div className="LandingContainer">
                    <table className={"ProductsTable "}>
                        <thead>
                        <tr className={"DesktopHeader"}>
                            <th colSpan={4}>
                                <div className="InsuredPersonTypes ProductBenefits">
                                    <div className="Options">
                                        <div onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'Private House',
                                                }
                                            });
                                            updateHousingType("PR");
                                            setProductBenefitsHousingTypePicked("PR");
                                        }}
                                             className={"Option" + (productBenefitsHousingTypePicked === "PR" ? ' Picked' : '')}
                                        >
                                            <TenantInternalBlueIcon
                                                className={window.navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/) ?
                                                    'QuotePersonIconIE' : 'QuotePersonIcon'}/>
                                            <div
                                                className="QuotePersonLabel">{cfg('landingPrivateHouse.label')}</div>
                                        </div>
                                        <div onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'Apartment',
                                                }
                                            });
                                            updateHousingType("PU")
                                            setProductBenefitsHousingTypePicked("PU");
                                        }}
                                             className={"Option" + (productBenefitsHousingTypePicked === "PU" ? ' Picked' : '')}
                                        >
                                            <LandlordInternalBlueIcon
                                                className={window.navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/) ?
                                                    'QuotePersonIconIE' : 'QuotePersonIcon'}/>
                                            <div
                                                className="QuotePersonLabel">{cfg('landingApartment.label')}</div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                            <th className={` Limit`}>
                                <div className="FlagContainer ">
                                    <div className="Flag">
                                        <FlagIcon/>
                                        <span className={"FlagOccupancy"}>
                                            {cfg('LandingSilverPlan.label')}
                                        </span>
                                    </div>
                                </div>
                            </th>
                            <th className={` Limit`}>
                                <div className="FlagContainer ">
                                    <div className="Flag">
                                        <FlagIcon/>
                                        <span className={"FlagOccupancy"}>
                                            {cfg('LandingGoldPlan.label')}
                                        </span>
                                    </div>
                                </div>
                            </th>
                            <th className={` Limit`}>
                                <div className="FlagContainer ">
                                    <div className="Flag">
                                        <FlagIcon/>
                                        <span className={"FlagOccupancy"}>
                                            {cfg('LandingPlatinumPlan.label')}
                                        </span>
                                    </div>
                                </div>
                            </th>
                            <th></th>
                        </tr>

                        <tr className="MobileHeader">
                            <th colSpan={4} className="Limit">
                                <div className="InsuredPersonTypes ProductBenefits ProductBenefitsMobile">
                                    <div className="Options">
                                        <div onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'tenant-insured',
                                                }
                                            });
                                            updateHousingType("PR")
                                            setProductBenefitsHousingTypePicked("PR");
                                        }}
                                             className={"Option" + (productBenefitsHousingTypePicked === "PR" ? ' Picked' : '')}
                                        >
                                            <TenantInternalBlueIcon
                                                className={window.navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/) ?
                                                    'QuotePersonIconIE' : 'QuotePersonIcon'}/>
                                            <div
                                                className="QuotePersonLabel">{cfg('landingPrivateHouse.label')}</div>
                                        </div>
                                        <div onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'landlord-insured',
                                                }
                                            });
                                            updateHousingType("PU")
                                            setProductBenefitsHousingTypePicked("PU");
                                        }}
                                             className={"Option" + (productBenefitsHousingTypePicked === "PU" ? ' Picked' : '')}
                                        >
                                            <LandlordInternalBlueIcon
                                                className={window.navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/) ?
                                                    'QuotePersonIconIE' : 'QuotePersonIcon'}/>
                                            <div
                                                className="QuotePersonLabel">{cfg('landingApartment.label')}</div>
                                        </div>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr className="MobileHeader">
                            <th colSpan={4} className="Limit">
                                <div className="FlagContainer">
                                    <div className={"Flag" + (mobileProductFeatures.silver === true ? " Picked" : "")}
                                         onClick={() => {
                                             setMobileProductFeatures({silver: true, gold: false, platinum: false})
                                         }}>
                                        <MobilePlan/>
                                        <span className="FlagOccupancy">
                                            {cfg('LandingSilverPlan.label')}
                                        </span>
                                    </div>
                                    <div className={"Flag" + (mobileProductFeatures.gold === true ? " Picked" : "")}
                                         onClick={() => {
                                             setMobileProductFeatures({silver: false, gold: true, platinum: false})
                                         }}>
                                        <MobilePlan/>
                                        <span className="FlagOccupancy">
                                            {cfg('LandingGoldPlan.label')}
                                        </span>
                                    </div>
                                    <div className={"Flag" + (mobileProductFeatures.platinum === true ? " Picked" : "")}
                                         onClick={() => {
                                             setMobileProductFeatures({silver: false, gold: false, platinum: true})
                                         }}>
                                        <MobilePlan/>
                                        <span className="FlagOccupancy">
                                            {cfg('LandingPlatinumPlan.label')}
                                        </span>
                                    </div>
                                </div>
                            </th>
                        </tr>

                        <tr className={"DesktopHeader"}>
                            <th className={"HeaderLabel"} colSpan={4}>{cfg('ProductFeatureHeading1.label')}</th>
                            <th className={"HeaderLabel Limit"}
                                colSpan={3}>
                                {cfg('ProductFeatureHeading2.label')}
                                </th>
                            <th></th>
                        </tr>
                        <tr className={"MobileHeader"}>
                            <th className={"HeaderLabel Limit"}
                                colSpan={4}>{cfg('LandingProductFeatureLabelMobile.label')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <td colSpan={10}>
                            <div className={'LandingTableBoldSeparator'}></div>
                        </td>
                        {
                            getProductFeatureByID(housingType, country).map(item => {
                                return <ProductFeatureRow productFeature={item}/>
                            }).reduce((p, c) => {
                                return [p, <tr className={"LandingTableSeparatorRow"}>
                                    <td colSpan={10}>
                                        <div className={'LandingTableSeparator'}></div>
                                    </td>
                                </tr>, c]
                            })
                        }
                        <td colSpan={10}>
                            <div className={'LandingTableBoldSeparator'}></div>
                        </td>
                        </tbody>
                    </table>
                    <div className="explain">{cfg('productHighlightRemark1.label')}
                        <span className="link" onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'product-brochure',
                                }
                            });

                            return getAssetTypeDocDownload("Brochure", props.lang);
                        }}>
                            {cfg('productHighlightRemarkProductBrochure1.label')}
                        </span>
                        {cfg('productHighlightRemark2.label')}
                        <span onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    'event': 'policy-wording',
                                }
                            });
                            return getAssetTypeDocDownload("PolicyWording", props.lang);
                        }}>
                            <span className="link">
                                {(cfg('productHighlightRemarkPolicyWording.label'))}
                            </span>
                            {cfg('productHighlightRemark3.label')}
                        </span>
                    </div>
                    <div className="ProductFeatureRemark">
                        <QnectButton color={'secondary'} className={"ProductBrochureButton ProductFeatureRemarkBtn"}
                                     onClick={() => {
                                         TagManager.dataLayer({
                                             dataLayer: {
                                                 'event': 'product-brochure-button',
                                             }
                                         });
                                         return getAssetTypeDocDownload("Brochure", props.lang);
                                     }} isSmall>
                            <span className="ProductBrochureBtnLabel">
                                {cfg('landingInsuranceProductFeatureTitle.label')}
                            </span>
                        </QnectButton>
                        <QnectButton color={'primary'} className={`InstantQuoteButton ProductFeatureRemarkBtn`}
                                     onClick={() => {
                                         TagManager.dataLayer({
                                             dataLayer: {
                                                 'event': 'get-a-instant-quote',
                                             }
                                         });
                                         return gotoInsuranceQuotesSection();
                                     }} isSmall>
                            <span className="InstantQuoteBtnLabel">
                                {
                                    cfg('landingInsuranceInstantQuoteTitle.label')
                                }
                            </span>
                        </QnectButton>
                    </div>

                </div>
            </div>
            <div className="LandingHeadingTitle">{cfg("landingSecondInfoBannerTitle_VN.label")}</div>
            <div className="MobileEClaimFamily"></div>
            <div className="LandingQBESGP">
                <div className="LandingContainer">
                    <div className="LandingQBESGPFamilyImg">
                    </div>
                    <div className="LandingQBESGPContainer">
                        <div className="eClaimContainer">
                            <div className={"eClaimsHeading"}>{cfg('landingEClaimTitle.label')}</div>
                            <div className={"eClaimsContent"}>{cfg('landingEClaimDescription.label')}</div>
                            <MobileMoneyIcon className="eClaimsPhoneImg MobileDeviceIcon"/>
                            <QnectButton className={"LearnMoreButton"} color={"secondary"} onClick={()=>{
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'find-out-more-eclaims',
                                    }
                                });
                                findOutMore();
                            }}>
                                {cfg('landingFindMoreButton1.label')}
                            </QnectButton>
                        </div>
                    </div>
                    <div className="LandingQBESGPCircle">

                    </div>
                </div>
            </div>
        </Template>
    )
};

// get status from redux (subscription)
const mapStateToProps = (state) => {
    return {
        agent: _getAgent(state),
        lang: _getLang(state),
        country: _getCountry(state),
        campaign: _getCampaign(state),
        campaignImage: _getCampaignImage(state),
        policy: _getPolicy(state),
        validAgent: _getValidAgent(state),
        systemAvailable: _getSystemAvailable(state),
        initialURL: _getInitialURL(state)
    }
};

// send service request to redux
const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storePolicy: _storePolicy,
    ratePolicy: _ratePolicy,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    setCampaignImage: _setCampaignImage,
    storeCampaign: _storeCampaign,
    validateCampaignPromo: _validateCampaignPromo,
    setCampaignDetails: _setCampaignDetails,
    findCampaignImage: _findCampaignImage,
    setAgentNo: _setAgentNo,
    findAgentDetails: _findAgentDetails,
    setValidAgent: _setValidAgent,
    findStaffCode: _findStaffCode
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Landing));
