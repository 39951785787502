import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import propTypes from 'prop-types';
import React, {useContext} from 'react';

import ConfigContext from '../../../common/config-context';
import useDeterministicMemo from '../../../common/use-deterministic-memo';
import TextInput from '../../form-controls/text-input';
import IconCalendar from '../../icons/legacy/calendar';
import dayRenderer from './day-renderer';
import PickerUtils from './picker-utils';
import Calendar from '../../../../assets/calendar.png';

const DateField = React.forwardRef((
    {
        label,
        name,
        helperText,
        disableToolbar,
        minDate,
        maxDate,
        value,
        onChange,
        onBlur,
        errorMessage,
        fixedLabel,
        initialFocusedDate
    },
    ref
) => {
    // Create textFieldComponent that forwards the provided ref.
    // Memoize it so it's not recreated on every render, which would cause a loss of focus.
    // Recreate it in case the provided ref changes (which is why we're not using useRef).
    // We're using useDeterministicMemo instead of useMemo, because useMemo provides no semantic guarantee, which means
    // no guarantee that the component won't be recreated, therefore no guarantee that focus won't be lost arbitrarily.
    const textField = useDeterministicMemo(
        () => (props) => <TextInput
            // fixedLabel={fixedLabel}

            {...props}
            name={name}
            variant="filled"
            ref={ref} />,
        [ref]
    );

    const {inputDateFormat} = useContext(ConfigContext);

    return (
        <MuiPickersUtilsProvider utils={PickerUtils}>
            <KeyboardDatePicker
                label={label}
                disableToolbar={disableToolbar}
                format={inputDateFormat}
                minDate={minDate}
                maxDate={maxDate}
                autoOk
                okLabel=""
                keyboardIcon={<IconCalendar color="black" size={24} />}
                value={value || null}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errorMessage}
                helperText={errorMessage || helperText||<>&nbsp;</>}
                TextFieldComponent={textField}
                inputVariant="filled"
                renderDay={dayRenderer(value, minDate, maxDate)}
                initialFocusedDate={initialFocusedDate}
            />
        </MuiPickersUtilsProvider>
    );
});

DateField.propTypes = {
    label: propTypes.string,
    name: propTypes.string,
    helperText: propTypes.string,
    disableToolbar: propTypes.string,
    minDate: propTypes.object,
    maxDate: propTypes.object,
    value: propTypes.oneOfType([propTypes.string, propTypes.instanceOf(moment)]),
    onChange: propTypes.func,
    onBlur: propTypes.func,
    errorMessage: propTypes.string,
};

DateField.displayName = 'DateField';

export default DateField;
