import property from "lodash/property";
import {_sendLog} from "../../utils/utility";
import * as type from "../campaign/types";
import axios from "axios";
import {_fetchAddressesFailed} from "../addresses/service";


export const _storeCampaign = (campaign) => {
    _sendLog(['PAN-React - Campaign Service - _storeCampaign: ', JSON.stringify(campaign, null, 3)], 'info');
    return (dispatch, getState) => {
        dispatch({
            type: type.CAMPAIGN_STORE,
            data: campaign
        })
    };
}

export function _setCampaignDetails(policy) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let path = process.env.REACT_APP_FIND_CAMPAIGN_DETAILS;
            let sessionId = _getSystem(getState()).sessionNameId;

            // @ts-ignore
            axios.post(server + path,policy, { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId} })
                .then(resp => {
                    let campaignDetails = resp.data;
                    dispatch({
                        type: type.CAMPAIGN_STORE,
                        data: campaignDetails
                    });
                    resolve(resp.data);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Campaign Service - _setCampaignDetails: ', error]);
                    dispatch(_fetchAddressesFailed())
                    reject(error);
                });
        });
    };
}

export function _validateCampaignPromo(campaign,promo,campaignId) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
            let path = process.env.REACT_APP_VALIDATE_PROMO_CODE + campaign +"/"+ promo +"/"+ campaignId ;
            let sessionId = _getSystem(getState()).sessionNameId;
            console.log("_validateCampaignPromo: " +campaign );
            // @ts-ignore
            axios.get(server + path, { headers: {'Content-Type': 'application/json', 'Session-Name' : sessionId} })
                .then(resp => {
                    const result = resp.data;
                    resolve(result);
                })
                .catch(error => {
                    console.error(error);
                    _sendLog(['PAN-React - Campaign Service - _validateCampaignPromo: ', error]);
                    reject(error);
                });
        });
    };
}

export function _resetCampaign() {
    return (dispatch, getState) => {
        dispatch({
            type: type.RESET_CAMPAIGN,
        })
    };
}


export const _getCampaign = property ( 'cm' );
export const _getSystem = property ( 'sys' );
