import React from 'react';
import styled from 'styled-components';
import {TextField as MuiTextField} from '@material-ui/core';
import property from 'lodash/property';

const StyledMuiTextField = styled(MuiTextField)`

    &.MuiFormControl-root {
        width: 100%;

        .MuiFormLabel-root {
            color: #0A1F44;
            font-family: Inter-Medium, sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
            //transform: translateY(-12px);
            //z-index: 5;
        }
        .MuiFormLabel-filled{
          color: ${property('theme.color.primary')};
        }
//.MuiFilledInput-input {
//    
//}
        .Mui-focused {
            color: ${property('theme.color.primary')};
        }
        
        .MuiInputBase-input{
            //padding-top: 0px;
            //padding-bottom: 18px;
        }

        //input::placeholder{
        //    
        //    color: #5E6073!important;
        //    font-family: Arial, Noto;
        //    font-size: 16px;
        //    letter-spacing: 0;
        //    font-weight: normal;
        //    opacity: 1!important;
        //}
        //.Mui-error input::placeholder{
        //    
        //    color: #C14A36!important;
        //    font-family: Arial, Noto;
        //    font-size: 16px;
        //    letter-spacing: 0;
        //    font-weight: normal;
        //    opacity:1!important;
        //}
        
        //.MuiInputBase-input,
        .MuiInputBase-root {
            border-radius: 0;
            background-color: ${property('theme.color.grey30')};
            color: ${property('theme.color.black')};
            font-family: Inter-Medium, sans-serif;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
        }

        .MuiInput-underline::before {
            border-bottom-color: #8E90A2;
        }
        
        
        //input:not(:placeholder-shown) .MuiInput-underline::before{
        //    border-bottom-color: #00A758;
        //    border-bottom-width: 3px;
        //}
        //.MuiFormHelperText-filled ~ .MuiInput-underline::before,
        //.MuiFormLabel-filled ~ .MuiInput-underline::before,
        .MuiFilledInput-underline::after {
            border-bottom-color: #455571;
            border-bottom-width: 2px;
        }
        .MuiFilledInput-underline.Mui-error::after {
            border-bottom-color: #A50000!important;
            border-bottom-width: 3px;
        }
        .MuiFilledInput-underline.Mui-error::after {
            border-bottom-color: #A50000;
            border-bottom-width: 3px;
        }
        .MuiFormHelperText-root {
            //margin-top: 0;
            //padding: 5px 0 0 0px;
            color: ${property('theme.color.tertiary90')};
            font-family: Inter, sans-serif;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
        }
        .MuiFormHelperText-root.Mui-error {
            color: #A50000;
        }
    }

    .MuiFormLabel-root.Mui-error,
    .MuiFormHelperText-root.Mui-error {
        color: ${property('theme.color.error')};
    }


`;

const TextInput = React.forwardRef(({fixedLabel,...props}, ref) => (
    <StyledMuiTextField
        {...props}
        inputRef={ref}
        InputLabelProps={{
            //shrink: fixedLabel,
        }}
    />
));

TextInput.displayName = 'TextInput';

export default TextInput;
