import React, {useState} from "react";
import styled from 'styled-components'
import {ReactComponent as Expand} from "../../assets/expand.svg";
import {ReactComponent as Collapse} from "../../assets/expand2.svg";

const ToggleStyle = styled.div`
margin-top: 7px;
margin-bottom: 11px;
  svg{
  cursor: pointer;
  }
  .ToggleHeader{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #282B3E;
  font-family: Inter-Bold;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  }
  .ToggleHeading{
    margin-left: 16.71px;
  }
`;


const Toggle = ({className, heading, children, isSmall, isExpanded, onClick, ...rest}) => {
    let [open,setOpen] = useState(isExpanded || false)
    return (<ToggleStyle onClick={onClick} className={className}>
        <div className="ToggleHeader">
            {
                open?<Collapse onClick={()=>setOpen(false)}/>:<Expand onClick={()=>setOpen(true)}/>
            }
            <span className={"ToggleHeading"}>
                {
                    heading||'Missing Heading'
                }
            </span>
        </div>
        {
            open&&
            <div className="ToggleContent">
                {children}
            </div>
        }
    </ToggleStyle>)
}

export default Toggle
