import {_getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import Family from "../../assets/familyblue.svg";
import React from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import useConfig from "../qnect-ui/qnect-config";
import {_getPolicy, _storePolicy} from "../../store/policy/service";
import Individual from "../../assets/adultblue.svg";

const InfoBox = styled.div`
  height: 75px;
  width: 665px;
  background-color: #ffffff;
  border: 1px solid #EDEDED;
  border-bottom: 5px solid #003DA5;
  box-shadow: 1px 1px 4px 0 rgba(20,40,75,0.12);
  margin-right: 0px;
  padding-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  @media (max-width: 1440px) {
  width: 740px;
    margin-right: 38px;
    padding-bottom: 37px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    height: auto;
    width: calc(100vw - 40px);
    margin: 0 auto;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }  
  .InfoItems{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 40%;
      margin-left: 34px;
        @media (max-width: 1024px) {
            height: auto;
            width: calc(100% - 33px);
            margin-left: 14px;
        }
  }
  .InfoVLine{
    box-sizing: border-box;
    min-height: 26px;
    height: 26px;
    width: 1px;
    border: 1px solid #4EC2F6;
    background: #4EC2F6;
    margin-top: auto;
    margin-bottom: auto;
    @media (max-width: 1024px) {
        border: none;
        height: 25px;
    }
  }
  .InfoHeading{
      color: #009AE4;
      font-family: Inter-Bold, sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 10px;
  }
  .InfoContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 42px;
        color: #282B3E;
        font-family: Inter-Medium, sans-serif;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        @media (max-width: 1024px) {
          justify-content: space-between;
          width: 100%;
        }  
  }
  .InfoContent.Person{
        @media (max-width: 1024px) {
          justify-content: flex-start;
        }  

  }
  .InfoContent .Individual{
        height: 42px;
        width: 36.4px;
        @media (max-width: 1024px) {
          width: 26px;
          height: 30px;
          margin-right: 11px;
        }  

  }
  .InfoContent .Family{
        width: 42px;
        height: 42px;
        @media (max-width: 1024px) {
          width: 37px;
          height: 37px;
          margin-right: 11px;
        }  

  }
  .InfoContent.price{
        color: #282B3E;
        font-family: Inter, sans-serif;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 23px;
  }
  .InfoContent.price1{
        color: #282B3E;
        font-family: Inter, sans-serif;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
  }
  .info{
    width: 20px;
    height: 20px;
    margin-left: 25px;
  }
  .InfoTextContent{
    margin-left: 5px;
    margin-right: 10px;
    font-weight: bold;
  }
`;
const PersonSummary = (props) => {
    console.log("Insurer Summary - props =  " + props);
    const insuredPersonType = props.policy?.policyPAN?.insuredPersonType;
    const numberOfAdults = props.policy?.policyPAN?.family?.numberOfAdults;
    const noOfChildren = props.policy?.policyPAN?.family?.noOfChildren;

    const cfg = useConfig(props.lang);

    function isFamily() {
        if (insuredPersonType && String(insuredPersonType).toLowerCase() === "family") {
           return true;
        }
        return false;
    }

    function hasChildren() {
        if (noOfChildren > 0) {
            return true;
        }
        return false;
    }

    const InsuredFamily = () => {
        return (
            <React.Fragment>
                <img src={Family} className={"Family"} alt="" />
                <div className="InfoTextContent">
                    <div>{cfg("family.label")}</div>
                </div>
            </React.Fragment>
        );
    }

    const InsuredIndividual = () => {
        return (
            <React.Fragment>
                <img src={Individual}  className={"Individual"} alt=""/>
                <div className="InfoTextContent">
                    <div>{cfg("landingInsuredPersonIndividual.label")}</div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <InfoBox className={"PersonSummary"}>
            <div className="InfoItems">
                <div className="InfoContent Person">
                    <div className="InfoHeading">{ cfg("landingInsuredPerson.label") }</div>
                    <div className="InfoContent">
                    {
                        isFamily() ?<InsuredFamily/>:<InsuredIndividual/>
                    }
                    </div>
                </div>
            </div>
            {  isFamily() &&
                <React.Fragment>
                    <div className="InfoVLine"></div>
                    <div className="InfoItems">
                        <div className="InfoContent Person">
                            <div className="InfoHeading">
                                {cfg('landingNoOfAdultsLabel.label')}
                            </div>
                            <img src={Individual} className={"Individual"} alt=""/>
                            <div className="InfoTextContent">
                                <div>{numberOfAdults}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            {   hasChildren() &&
                <React.Fragment>
                    <div className="InfoVLine"></div>
                    <div className="InfoItems">
                        <div className="InfoContent Person">
                            <div className="InfoHeading">
                                {cfg('occupationNoOfChildrenLabel.label')}
                            </div>
                            <img src={Individual} className={"Individual"} alt=""/>
                            <div className="InfoTextContent">
                                <div>{noOfChildren}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </InfoBox>
    );
}

// get status from redux (subscription)
const mapStateToProps = (state) => {
    console.log('BEGIN mapStateToProps');
    return {
        lang: _getLang(state),
        policy: _getPolicy(state)
    }
}

export default withRouter(connect(mapStateToProps, null)(PersonSummary));
