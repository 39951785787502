import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import filePath from '../assets/build.number';

const BottomFixedFontStyle = styled.div`
    font-family: "Inter-Regular", Helvetica, Arial, serif, sans-serif;
    font-size: 13px;
    color: white;
`

const BottomFixedStyle = styled.div`
    position: absolute;
    bottom: 5px;
    width: 1440px;
    height: auto;
`

const AppVersion = (git ) => {
    const [versionString, setVersionString] = useState('');
    useEffect(() => {
        fetch(filePath).then((res)=> {
            if (res) {
              res.text().then(result => {
                  if (result) {
                      setVersionString(result);
                  }
              });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }, []);

    return (
        <BottomFixedStyle>
            <BottomFixedFontStyle>{versionString}</BottomFixedFontStyle>
        </BottomFixedStyle>
    );
}

export default AppVersion
