import React from 'react';
import {connect} from 'react-redux';

import {
    _hideModal, _getModal, _getShowModal
} from '../store/system/service';

import Actions from "../ui/components/buttons/actions";
import Modal from "../ui/components/modals/modal";
import './modal-popup.css';
function ModalPopup({
    showModal,
    hideModal,
    modal,
}) {
    let custom  = {};
    if(modal.marginTop)
        custom.marginTop = modal.marginTop;
    let { size} = modal
    return (
        <Modal
            className={"ModalBody "+modal?.className}
            onClose={modal?.onClose ? modal?.onClose : hideModal}
            size={size}
            isOpen={showModal}>
            {/*<div className="ModalTop"/>*/}
            {/*<img className="ModalIcon" src={modal?.icon}/>*/}
            <div className="ModalTitle">
                {modal?.title}
            </div>
            {modal?.content && React.isValidElement(modal?.content) &&
            <div className="ModalContent" style={custom}>
                {modal?.content}
            </div>}
            {   modal?.primaryAction &&
                <Actions className="StyledModalActions"
                         primaryAction={modal?.primaryAction}
                         secondaryAction={modal?.secondaryAction}
                         tertiaryAction={modal?.tertiaryAction}
                />
            }
        </Modal>
    );
}

function mapStateToProps(state) {
    return {
        showModal: _getShowModal(state),
        modal: _getModal(state),
    };
}

const mapDispatchToProps = {
    hideModal: _hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPopup);
