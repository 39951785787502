import React, {useState} from 'react';
import Info from '../../assets/Info.svg';
import styled from 'styled-components';
import InfoWhite from "../../assets/info_white.png";


const ToolTipDiv = styled.div`

    display: ${({showToolTip}) => showToolTip ? 'inline' : 'none'};
    position: absolute;
    top: -48px;
    left: 43px;
    z-index: 999;

    .pointerDiv {
        border-top: 10px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 20px solid #EDEDED;
        border-right: 15px solid transparent;
        position: absolute;
        top: 41px;
        left: -11px;
    }


    .contentDiv {
        font-family: Inter-Regular, sans-serif;
        font-size: 14px;
        background:  #EDEDED;
        height: 129px;
        width: 233px;
        margin-left: 19px;
        padding: 10px 10px 10px 20px;
        text-align: left;
        line-height: 19px;
        border-bottom: 5px solid #00A758;
        position: absolute;
    }
    
`;

const MainDiv = styled.div`
    position: relative;
    display: inline;
    
    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        cursor: pointer;
    }
`;

const Background = styled.div`
width: 100vw;
height: 100vh;
position: fixed;
z-index: 10;
top: 0;
left: 0;
`;
const BalloonTip = (props) => {
    const [showToolTip, updateTooltipState] = useState(false);
    const mouseClick = () => {
        if(showToolTip) {
            updateTooltipState(false)
        }else
            updateTooltipState(true);
    };
    const mouseEnter = () => {
        updateTooltipState(true);
    };

    const mouseLeave = () => {
        updateTooltipState(false);
    };
    const imageSrc = props?.imageSrc || InfoWhite;
    const hoverImageSrc = props?.hoverImageSrc || InfoWhite;
    return (
        <>
            {showToolTip&&<Background onClick={mouseClick}/>}
            <MainDiv>
                <div className="imageDiv"  >
                    <img className="Icon" src={imageSrc}
                         onClick={mouseClick}
                         onMouseOver={e => (e.currentTarget.src = hoverImageSrc)}
                         onMouseLeave={e => (e.currentTarget.src = imageSrc)}/>
                </div>
                {
                    showToolTip&&<ToolTipDiv showToolTip={showToolTip}>
                        <div className="pointerDiv"></div>
                        <div className="contentDiv">
                            {props.children}
                        </div>
                    </ToolTipDiv>
                }

            </MainDiv>
        </>
    );
};

export default BalloonTip;
