import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import GreenDone from "../../assets/GreenDone.svg";

const useStyles = makeStyles(theme => ({
    root: {
        height: 240,
    },
    margin: {
        width: theme.spacing(3)
        // height: theme.spacing(3)
    }
}));

function is_touch_device4() {
    var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");

    var mq = function (query) {
        // console.log(window.matchMedia(query).matches);
        return window.matchMedia(query).matches;
    };

    if (
        "ontouchstart" in window //||
        // (window.DocumentTouch && document instanceof DocumentTouch?)
    ) {
        // console.log(true)
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
    console.log(mq(query));
    return mq(query);
}


const iOSBoxShadow =
    "4px 4px 12px 0 rgba(20,40,75,0.2)";

const IOSSlider = withStyles({
    root: {
        color: "#003DA5",
        height: 240,
        "&.Mui-disabled": {
            opacity: 0.6
        }
    },
    thumb: {
        height: 33,
        width: 33,
        opacity: 1,
        backgroundColor: "#fff",
        boxShadow: iOSBoxShadow,
        background: `url(${GreenDone})  no-repeat center center`,
        backgroundPosition: '54% 52%',
        marginTop: 0,
        marginLeft: 1,
        // left: 7.5,
        // left: 15,
        left: is_touch_device4() ? 15 : 7.5,
        transform: "translateY(2.5px) translateX(-3.5px)",
        "&:focus, &:hover, &$active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow
            }
        }
    },
    active: {},
    valueLabel: {
        display: "none"
    },
    track: {
        width: "5px !important"
    },
    rail: {
        width: "5px !important",
        opacity: 1,
        backgroundColor: "#003DA5"
    },
    mark: {
        transform: "translateY(3px)",

        backgroundColor: "#003DA5",
        height: 13,
        width: 13,
        borderRadius: "50%",
        left: is_touch_device4() ? 16 : 9
        // left: 9
        // left: 16
        // top: -3.5
    },
    markActive: {
        opacity: 1,
        backgroundColor: "#003DA5"
    },
    markLabel: {
        left: 300,
        color: "#000000",
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: 300,
        opacity: 1,
        letterSpacing: 0,
        lineHeight: "14px",
        transform: "translateY(10px) translateX(-120px)!important"
    },
    markLabelActive: {
        left: 300,
        color: "#000000",
        fontFamily: "Arial",
        fontSize: 12,
        fontWeight: 300,
        opacity: 1,
        letterSpacing: 0,
        lineHeight: "14px",
        transform: "translateY(10px) translateX(-120px)!important"
    }
})(Slider);



const QBESliderMobile = ({className,...props})=>{
    const classes = useStyles();
    return <div   className={classes.root + " "+className}>
        <IOSSlider
            // aria-label="ios slider mobile"
            defaultValue={props.defaultValue}
            // aria-labelledby="discrete-slider-restrict"
            // valueLabelFormat={props.valueLabelFormat}
            // getAriaValueText={props.getAriaValueText}
            ValueLabelComponent={props.ValueLabelComponent}
            step={props.step}
            disabled={props.disabled}
            valueLabelDisplay="on"
            onChangeCommitted={props.onChange}
            onChange={props.onChangeE}
            min={props.min}
            max={props.max}
            marks={props.marks}
            orientation="vertical"

        />
        {/*            orientation="vertical"*/}
    </div>

}
export default QBESliderMobile
