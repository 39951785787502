import {updateObject} from "../../utils/utility";
import * as type from "../campaign/types";
import _ from "lodash";

export const CAMPAIGN_INITIAL_STATE = {

    campaignId: '',
    campaignCode: '',
    campaignName: '',
    status: '',
    productCode: '',
    catalogueCode: '',
    startDate: '',
    endDate: '',
    p400Campaign: '',
    marketingMessage: '',
    marketingMessageCn: '',
    createdBy: '',
    dateCreated: '',
    updatedBy: '',
    dateUpdated: '',
    promoCodeRequired: '',
    promoCodeReusability: '',
    promoCodeDescription: '',
    promoCodeDescriptionCn: '',
    image: '',
    imageFileName: '',
    promoCode: '',
    validPromoCode: '',
    redirect: '',
    errorMessage: '',
    termsConditionsEn:'',
    termsConditionsCn:'',
}

const UNCHANGED_CAMPAIGN_INITIAL_STATE = _.cloneDeep(CAMPAIGN_INITIAL_STATE);

const storeCampaign = (state, action) => {
    return updateObject(
        state,
        action.data
    )
}

export const campaignReducer = (state = CAMPAIGN_INITIAL_STATE, action) => {
    switch ( action.type ) {
        case type.CAMPAIGN_STORE:
            return storeCampaign(state, action);
        case type.FETCH_FAILED:
            return updateObject(
                state,
                { error: true }
            );
        case type.RESET_CAMPAIGN:
            return _.cloneDeep(UNCHANGED_CAMPAIGN_INITIAL_STATE);
        default:
            return state;
    }
}
