import React, {Component} from 'react';
import {_getLang, _hideModal, _setLang, _showModal} from "../../store/system/service";
import {connect} from "react-redux";
import {ReactComponent as Email24} from "../../assets/email_24px.svg";
import {ReactComponent as Phone24} from "../../assets/phone_24px.svg";
import useConfig from "../qnect-ui/qnect-config";
import {_getAgent} from "../../store/agent/service";
import {_getCountry} from "../../store/system/service";

const sendMail = (email) => {
    window.location.href = "mailto:"+email;
}

const AgentDetail = (props) => {
    let cn = props.className || '';

    const agentName = props.agent?.name;
    const emailAddress = props.agent?.email;
    const telephoneNo = '84 28 3514 7969';
    const registrationNo = props.agent?.registrationNo;
    const agentNo = props.agent?.agentCode;
    const mobileNoCountryCode = props?.mobileNoCountryCode || '852'

    const cfg = useConfig(props.lang);
    return (

        <div className={"InfoGroup agentboxContainer "+cn}>
            <div className="AgentInfoGroup">
                <div className="Item">
                    <div className="AgentName">{agentName}</div>
                    <div className="AgentNo"><br/>

                    </div>
                </div>
                <div className="AgentVLine"></div>
                <div className="AgentContactInfoGroup">
                    {telephoneNo ?
                        <div className="AgentContactInfo">
                            <Phone24 style={{cursor: "pointer"}} onClick={()=> window.open(
                                "http://api.whatsapp.com/send?phone="+telephoneNo, "_blank")} />
                            <a className="Value" href={"http://api.whatsapp.com/send?phone="+telephoneNo} target="_blank">{telephoneNo}</a>
                        </div>: <div></div>}
                    {emailAddress ?
                        <div  className="AgentContactInfo">
                            <Email24 style={{cursor:"pointer"}} onClick={() => sendMail(emailAddress)} />
                            <a className="Value" href={"mailto:" + emailAddress}>{emailAddress}</a>
                        </div>: <div></div>}
                </div>
            </div>
            <div className="MobileAgentInfoGroup">
                <div className="AgentName">{agentName}</div>
                <div className="AgencyName">{props?.agent?.agency}</div>
                {emailAddress ?
                    <div className="AgentContactInfo">
                        <Email24 style={{cursor: "pointer"}} onClick={() => sendMail(emailAddress)}/>
                        <a className="Value" href={"mailto:" + emailAddress}>{emailAddress}</a>
                    </div> : <div></div>}
                <div className={"AgentRow"}>
                    {telephoneNo ?
                        <div className="AgentContactInfo">
                            <Phone24 style={{cursor: "pointer"}} onClick={() => window.open(
                                "http://api.whatsapp.com/send?phone=" + '+' + mobileNoCountryCode + telephoneNo, "_blank")}/>
                            {/* <a className="Value" href={"tel:"+telephoneNo}>{telephoneNo}</a> */}
                            <a className="Value" href={"http://api.whatsapp.com/send?phone=" + '+' + mobileNoCountryCode + telephoneNo}
                               target="_blank">{telephoneNo}</a>
                        </div> : <div></div>}
                    <div className="AgentNo">

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: _getLang(state),
        agent: _getAgent(state),
        country: _getCountry(state)
    };
}

const mapDispatchToProps = {
    showModals: _showModal,
    hideModal: _hideModal,
    setLang: _setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentDetail);
