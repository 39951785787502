export const SET_STEP = "SET_STEPS";
export const SET_SITE = "SET_SITE";
export const SET_LANG = "SET_LANG";
export const SET_COUNTRY = "SET_COUNTRY";
export const SHOW_ERROR = 'SHOW_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_CAMPAIGN = "SET_CAMPAIGN";
export const SET_CAMPAIGN_IMAGE = "SET_CAMPAIGN_IMAGE";
export const SET_AGENT_NO = "SET_AGENT_NO";
export const SET_OCCUPATIONS = "SET_OCCUPATIONS";
export const SET_INITIAL_URL = "SET_INITIAL_URL";
export const SET_SYSTEM_AVAILABLE = "SET_SYSTEM_AVAILABLE";
export const SET_VALID_AGENT = "SET_VALID_AGENT";


