import property from 'lodash/property';
import * as type from "./types";
import axios from "axios";
import {_getSystem} from "../agent/service";
import {_sendLog} from "../../utils/utility";

export const _setAddresses = (addresses) => {
    return {
        type: type.SET_ADDRESS,
        addresses: addresses
    }
}

export const _fetchAddressesFailed = () => {
    return {
        type: type.FETCH_FAILED
    };
}

export const _searchAddress = async (address, country) => {
    return new Promise((resolve, reject) => {
        return axios.get(process.env.REACT_APP_FIND_ADDRESS + address + "/" + country).then(resp => {
            resolve(resp.data);
        }).catch(error => {
            reject(error);
        });
    });
}
/*export const _searchAddress = async (postCode, country) => {
    return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const server = (process.env.REACT_APP_ENV  === 'dev' ? process.env.REACT_APP_LOCAL_URL : '');
        let path = process.env.REACT_APP_FIND_ADDRESS + postCode + "/" + country;

        return axios.get(server + path)
            .then(resp => {
                let address = resp.data;
                resolve(resp.data);
            }).catch(error => {
                reject(error);
            });
    })
    };
}*/

export const getAddressesResult = property ( 'addr.addresses');

