import React from "react";
import styled from 'styled-components'
import {ReactComponent as Done} from '../../assets/Done.svg';

const StyledDone = styled(Done)`
  fill: #FFF;
  height: 9px;
  width: 11px;
  stroke-width: 2;
`;
const COLORS = {
    "primary": {
        background: "#FF7769",
        font:"#FFFFFF"
    },
    "secondary": {
        background:"#8E90A2",
        font:"#FFFFFF"
    },
    "info": {background:"#FFFFFF",
        font:"#282B3E"}
}

const StepperStyle = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100vw;
box-sizing: border-box;
padding-left: 20px;
padding-right: 20px;
color: #282B3E;
font-family: Arial, Noto;
font-size: 14px;
font-weight: bold;
letter-spacing: 0;
line-height: 16px;
margin-bottom: 20px;
& .StepIcons{
  display: flex;
  flex-direction: row;
  align-items: center;
}
`;

const StepIcon = styled.div`
  width: 19px;
  height: 19px;
  margin-left: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({fill})=>fill?'#282B3E':'#8E90A2'};
  color:  ${({fill})=>fill?'#fff':'#8E90A2'};
  background-color: ${({fill})=>fill?'#282B3E':'transparent'};
  font-family: Arial;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
`


const MobileStepper = ({className, steps, activeStep, ...rest}) => {
    console.log(steps,activeStep)
    return (<StepperStyle className={className}>
        <div className={"StepText"}>{steps[activeStep].name}</div>
        <div className={"StepIcons"}>
            {
                steps.map((e,i,a)=>{
                    console.log(e,i,a)
                   if (i===0||i===steps.length-1){
                       return<></>
                   }else if(i===activeStep){
                      return  <StepIcon fill>{i}</StepIcon>
                   }else if(i<activeStep){
                       return <StepIcon fill><StyledDone/></StepIcon>
                   }else{
                       return <StepIcon>{i}</StepIcon>
                   }
                })
            }
        </div>
    </StepperStyle>)
}

export default MobileStepper
