import {connect} from 'react-redux';
import React, {useEffect, useRef, useState} from 'react';
import "./quote.css";
import {withRouter} from "react-router";
import Template from "../template";
import PromoIcon from '../../assets/promo_darkblue.svg';
import AddBlueIcon from '../../assets/add_circle_outline_blue.svg';
import MinusBlueIcon from '../../assets/minus_circle_outline_blue.svg';
import {ReactComponent as AdjustPlus} from "../../assets/add_circle_white.svg";
import {ReactComponent as AdjustMinus} from "../../assets/minus_circle_white.svg";
import MinusGrayWhiteIcon from '../../assets/minus_circle_outline_grey.svg';
import IndividualIcon from '../../assets/adultblue.svg';
import {debounce,throttle} from 'lodash'
import NavArrow from "../../assets/bluearrow.svg";
import {ReactComponent as CircleTick} from "../../assets/tick_selected.svg";
import WhiteArrow from '../../assets/whitearrow.png';
import Phone from "../../assets/telbig@2x.png";
import SadIcon from "../../assets/sad.png";
import WhatsApp from "../../assets/whatsapp@2x.png";
import {ReactComponent as Email} from "../../assets/emailbig.svg";
import {
    _getCountry,
    _getInitialURL,
    _getLang,
    _hideModal,
    _hideSpinner,
    _showError,
    _showModal,
    _showSpinner
} from "../../store/system/service";
import {_getAgent} from "../../store/agent/service";
import QnectButton from "../qnect-ui/qnect-button";
import {_getPolicy, _ratePolicy, _singleRatePolicy, _storePolicy} from "../../store/policy/service";
import {_getCampaign, _validateCampaignPromo} from "../../store/campaign/service";
import useConfig from "../qnect-ui/qnect-config";
import {cloneDeep} from "lodash";
import TagManager from "react-gtm-module";
import Toggle from "../ui/toggle";
import InfoIcon from "../../assets/info_outline_darkblue.svg";
import InfoDarkIcon from "../../assets/info_darkblue.svg";
import QBEBalloonTip from "../ui/qbe-balloon-tips";
import ActionModal from "../../ui/components/modals/action-modal";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from '@material-ui/core/styles';
import QBESlider from "../ui/qbe-slider";
import QBESliderMobile from "../ui/qbe-slider-mobile";

import {AssetType, downloadPDF, getAsset} from "../../utils/pdf-asset";
import QnectTextField from "../qnect-ui/qnect-text-field";
import {ReactComponent as WhiteCoupon} from "../../assets/couponwhite.svg";
import {PRODUCT_FEATURES} from "../../utils/product-feature";
import {ReactComponent as HomeContentIcon} from "../../assets/home_content.svg";
import {ReactComponent as BuildingIcon} from "../../assets/buildings.svg";
import {ReactComponent as PersonalAccidentIcon} from "../../assets/personal_accident.svg";
import {ReactComponent as LiabilityIcon} from "../../assets/liability.svg";
import QBEBalloonTips from "../ui/qbe-balloon-tips";
import {v4 as uuidv4} from "uuid";


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'transparent',
        color: 'rgba(0,0,0,0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(18),
        fontFamily: 'Arial',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '21px',
        textAlign: 'center',
    },
}))(Tooltip);
const HtmlTooltipMobile = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'transparent',
        color: 'rgba(0,0,0,0.87)',
        maxWidth: 100,
        fontSize: theme.typography.pxToRem(18),
        fontFamily: 'Arial',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '21px',
        textAlign: 'left',
        margin: 0
    },
}))(Tooltip);


let {HOME_CONTENT, BUILDINGS, LIABILITY_TO_OTHERS} = PRODUCT_FEATURES
let ProductFeatures =  {
    privateHouse: [
        {
            toggle: true,
            item: HOME_CONTENT,
            icon: <HomeContentIcon  className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS.privateHouse,
            balloon: HOME_CONTENT.balloon,
            subItems: [
                HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_THE_OPEN_AIR,
                HOME_CONTENT.child.EMERGENCY_STORAGE,
                HOME_CONTENT.child.OCCUPIER_ADDITIONAL_BENEFITS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX,
                HOME_CONTENT.child.HOME_CONTENTS_TEMPORARY_REMOVED,
                HOME_CONTENT.child.HOME_CONTENTS_DURING_TRANSIT,
                HOME_CONTENT.child.HOME_CONTENTS_AT_A_NEW_SITUATION,
                HOME_CONTENT.child.HOME_CONTENTS_AT_YOUR_WORKPLACE,
                HOME_CONTENT.child.COMPENSATION_FOR_MEDICAL,
                HOME_CONTENT.child.DOMESTIC_HELPERS_PERSONAL,
                HOME_CONTENT.child.FUNERAL_EXPENSES,
                HOME_CONTENT.child.GUESTS_AND_VISITORS,
                HOME_CONTENT.child.KEYS_LOCK_REPLACEMENT,
                HOME_CONTENT.child.TEMPORARY_ACCOMMODATION,
            ],
        },
        {
            toggle: true,
            item: BUILDINGS,
            icon: <BuildingIcon  className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD.privateHouse,
            balloon: BUILDINGS.balloon,
            subItems: [
                BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD,
                BUILDINGS.child.CONTRACT_WORK_ALTERATION,
                BUILDINGS.child.ELECTRIC_GAS_WATER,
                BUILDINGS.child.FIRE_EXTINGUISHING,
                BUILDINGS.child.MODIFICATION_COST,
                BUILDINGS.child.LANDLORD_ADDITIONAL_BENEFITS,
                BUILDINGS.child.RENT_LOSS_UP_TO_6_MONTHS,
            ],
        },
        {
            toggle: true,
            item: LIABILITY_TO_OTHERS,
            icon: <LiabilityIcon  className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD.privateHouse,
            balloon: LIABILITY_TO_OTHERS.balloon,
            subItems: [
                LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD
            ],
        }

    ],
    apartment: [
        {
            toggle: true,
            item: HOME_CONTENT,
            icon: <HomeContentIcon  className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS.apartment,
            balloon: HOME_CONTENT.balloon,
            subItems: [
                HOME_CONTENT.child.LOSS_OR_DAMAGE_TO_ALL_HOME_CONTENTS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_THE_OPEN_AIR,
                HOME_CONTENT.child.EMERGENCY_STORAGE,
                HOME_CONTENT.child.OCCUPIER_ADDITIONAL_BENEFITS,
                HOME_CONTENT.child.HOME_CONTENTS_IN_A_SAFE_DEPOSIT_BOX,
                HOME_CONTENT.child.HOME_CONTENTS_TEMPORARY_REMOVED,
                HOME_CONTENT.child.HOME_CONTENTS_DURING_TRANSIT,
                HOME_CONTENT.child.HOME_CONTENTS_AT_A_NEW_SITUATION,
                HOME_CONTENT.child.HOME_CONTENTS_AT_YOUR_WORKPLACE,
                HOME_CONTENT.child.COMPENSATION_FOR_MEDICAL,
                HOME_CONTENT.child.DOMESTIC_HELPERS_PERSONAL,
                HOME_CONTENT.child.FUNERAL_EXPENSES,
                HOME_CONTENT.child.GUESTS_AND_VISITORS,
                HOME_CONTENT.child.KEYS_LOCK_REPLACEMENT,
                HOME_CONTENT.child.TEMPORARY_ACCOMMODATION,
            ],
        },
        {
            toggle: true,
            item: BUILDINGS,
            icon: <BuildingIcon  className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD.apartment,
            balloon: BUILDINGS.balloon,
            subItems: [
                BUILDINGS.child.LOSS_OR_DAMAGE_TO_BUILD,
                BUILDINGS.child.CONTRACT_WORK_ALTERATION,
                BUILDINGS.child.ELECTRIC_GAS_WATER,
                BUILDINGS.child.FIRE_EXTINGUISHING,
                BUILDINGS.child.MODIFICATION_COST,
                BUILDINGS.child.LANDLORD_ADDITIONAL_BENEFITS,
                BUILDINGS.child.RENT_LOSS_UP_TO_6_MONTHS,
            ],
        },
        {
            toggle: true,
            item: LIABILITY_TO_OTHERS,
            icon: <LiabilityIcon className={"Icon"} style={{"paddingLeft": "10px"}} />,
            plan: LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD.apartment,
            balloon: LIABILITY_TO_OTHERS.balloon,
            subItems: [
                LIABILITY_TO_OTHERS.child.LIMIT_OF_LIABILITY_ANY_ONE_PERIOD
            ],
        }
    ]
}

const getAssetTypeDocDownload = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            return downloadPDF(getAsset(assetTypePB));
            break
        case 'PolicyWording':
            return downloadPDF(getAsset(assetTypePW));
            break
        default:
            return downloadPDF(getAsset(assetTypePB));
    }
}
const getAssetTypeDocOpen = (id, lang) => {
    let assetTypePB= 'AssetType.PRODUCT_BROCHURE_'+lang;
    let assetTypePW= 'AssetType.POLICY_WORDING_'+lang;
    switch (id) {
        case 'Brochure':
            window.open(getAsset(assetTypePB).file,  '_blank');
            break
        case 'PolicyWording':
            window.open(getAsset(assetTypePW).file,  '_blank');
            break
        default:
            window.open(getAsset(assetTypePB).file,  '_blank');
    }
}
const Quote = (props) => {
    const formId = "quoteForm";
    const cfg = useConfig(props.lang, props.country);

    const premiumItems = getPlanItems();
    const occupancyType = props.policy?.policyHome?.occupancyType;
    const [dispImportantNotes, setDispImportantNotes] = useState(true);
    const housingType = props.policy?.policyHome?.housingCode;
    const [selectedPlanCode, setSelectedPlanCode] = useState( ()=>{
        if (props.policy.policyHome?.planType) {
            return props.policy.policyHome?.planType
        } else {
            return 'SP';
        }
    });

    const [currentPremiumItem, setCurrentPremiumItem] = useState(()=>{
        return getSelectedPremiumItem(premiumItems, selectedPlanCode);
    });

    const campaignDiscountRate = getCampaignDiscountRate();
    const unspecifiedValuableOptions = getUnspecifiedValuableOptions();
    const [unspecifiedValuableOptionsDesc, setUnspecifiedValuablesOptionsDesc] = useState('');

    const [specifiedValuesTotalSum, setSpecifiedValuesTotalSum] = useState(()=>{
        const totalSpecifiedValuables = props.policy.policyHome?.totalSpecifiedValuables
        return totalSpecifiedValuables;
    });

    const [promotionCode, setPromotionCode] = useState('');
    const [showPromotionCode, setShowPromotionCode] = useState(false);
    const [promotionCodeErrorMessage, setPromotionCodeErrorMessage] = useState('');

    useEffect(() => {
        const campaign = props.campaign;
        if (campaign && campaign?.promoCodeRequired === "Y") {
            setShowPromotionCode(true);
        }

        const promo = props.policy?.promo
        if (promo) {
            setPromotionCode(promo);
        }
    }, []);

    const [specifiedValuesTotalSumErrorMessage, setSpecifiedValuesTotalSumErrorMessage] = useState('');

    const [specifiedValuesOption, setSpecifiedValuesOption] = useState(()=>{
        const totalSpecifiedValuables = props.policy.policyHome?.totalSpecifiedValuables
        return getSpecifiedValuesOption(totalSpecifiedValuables);
    });

    const [numberOfEmployeesOption, setNumberOfEmployeesOption] = useState(()=>{
        const numberOfEmployees = props.policy.policyHome?.numberOfEmployees
        return getNumberOfEmployeesOption(numberOfEmployees);
    });

    const [uiOptionalCoversList, setUiOptionalCoversList] = useState(()=>{
        const coverList = cloneDeep(props.policy.policyHome?.optionalCoversList);
        if (Array.isArray(coverList) && coverList.length > 0) {
            coverList.forEach((optionalCover)=> {
                if(optionalCover.id && optionalCover.label && optionalCover.coverValue && optionalCover.coverValueAmount) {
                    optionalCover.selectedOptionalCover = true;
                }
            });
            return coverList;
        }
        return [];
    });


    // const [showOptionalCover1Remark, setShowOptionalCover1Remark] = useState ( false );
    const [declarationTickError, setDeclarationTickError] = useState(false);
    const [checkboxSelected, setCheckBoxSelected] = useState(false);

    const [mobileDiscountToggle,setMobileDiscountToggle] = useState(false);
    const [mobileAnimation,setMobileAnimation] = useState(" ");

    const TableBody = useRef(null);
    const checkBoxDeclaration = useRef(null);

    const [scrollTop, setScrollTop] = useState(0);

    // -------- home contents popup -----------------------------
    const homeContentsBenefitStepValue = 1000;
    const homeContentsBenefitMinAmount = 200000;
    const homeContentsBenefitMaxAmount = 500000;


    const [homeContentsBenefitAmount , setHomeContentsBenefitAmount] = useState ( ()=>{
        if (!isNaN(props.policy?.policyHome?.additionalSumInsured) && Number(props.policy?.policyHome?.additionalSumInsured) > 0) {
            return Number(props.policy?.policyHome?.additionalSumInsured) + Number(homeContentsBenefitMinAmount);
        }
        return homeContentsBenefitMinAmount;
    });
    const [homeContentsNewLimit , setHomeContentsNewLimit] = useState (()=>{
        if (!isNaN(props.policy?.policyHome?.additionalSumInsured) && Number(props.policy?.policyHome?.additionalSumInsured) > 0) {
            return Number(props.policy?.policyHome?.additionalSumInsured) + Number(homeContentsBenefitMinAmount);
        }
        return homeContentsBenefitMinAmount;
    });
    const [openHomeContentsPopup, setOpenHomeContentsPopup] = useState ( false );

    // -------- buildings popup -----------------------------
    const buildingsBenefitStepValue = 10000;
    const buildingsBenefitMinAmount = 100000;
    const buildingsBenefitMaxAmount = 200000;
    useEffect(()=>{
        console.log('useEffect')
        let picked = document.querySelector('.Plan.HKG.Picked')
        if(picked){
            picked.scrollIntoView({behavior: 'smooth', block:'nearest',inline: 'nearest'})
        }
    },[])
    useEffect(() => {
        const page = document.querySelector('#Template')
        const header = document.querySelector('#QuoteHeader')
        const title = document.querySelector('.QuoteTablePoint')
        const fixedTop = document.querySelector('.TableHeaderGroup')
        const table = document.querySelector('.TableBody')
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        const box = page;
        console.log(box)
        const scrollbarWidth = box.offsetWidth - box.clientWidth;
        console.log("scrollbarWidth: " + scrollbarWidth)
        if(isIE) {
            fixedTop.style.position = "fixed"
            table.style.paddingTop = fixedTop.offsetHeight+"px"
            fixedTop.style.top = title.offsetTop+"px"
            fixedTop.style.width = (box.offsetWidth>1440?1440:table.offsetWidth+scrollbarWidth)+"px"
            console.log("title: ",title.offsetHeight+"px", " "+title.getBoundingClientRect().top ,"table", table.offsetWidth+"px")
        }
        function onScroll() {
            console.log('scrolling')
            if(isIE){
                console.log('[ie scrolling]')
                console.log(title.getBoundingClientRect().top)
                // console.log('scrolling',title.offsetHeight,fixedTop.offsetTop,title.getBoundingClientRect().top, page.scrollY)
                if(page.scrollTop>=0&& title.getBoundingClientRect().top>= header.offsetHeight){
                    // if(fixedTop.offsetTop>0){
                    fixedTop.style.top = title.offsetTop - page.scrollTop+"px"
                    // title.style.opacity = 1-Math.abs(title.getBoundingClientRect().top)/200
                }else{
                    fixedTop.style.top = header.offsetHeight+"px"
                }
            }
        }
        function Resize() {
            if(isIE) {
                fixedTop.style.width = (box.offsetWidth>1440?1440:table.offsetWidth+scrollbarWidth)+"px"
            }
        }
        window.addEventListener('resize',debounce(Resize))
        return page.addEventListener("scroll",throttle(onScroll,16),false);
    }, [scrollTop]);


    function getPlanItems() {
        let planItems = props.policy?.policyHome?.planItems;
        if(Array.isArray(planItems) && planItems.length > 0) {
            planItems =  planItems.sort((a, b) => (a.totalPremium > b.totalPremium) ? 1 : -1)
            return  planItems;
        }
        return [];
    }

    function getCampaignDiscountRate(){
        const rate = props.policy?.policyHome?.campaignDiscountRate
        if (!isNaN(rate) && rate >  0) {
            return rate / 100
        }
        return 0;
    }

    function getUnspecifiedValuableOptions() {
        const options = [];

        // unspecifiedValuable cover
        const unspecifiedValuableOption1 = {};
        unspecifiedValuableOption1.planName =cfg("quoteUnSpecifiedValuesPlanA.label")
        unspecifiedValuableOption1.planCode ="PA"
        unspecifiedValuableOption1.planPremium = 575;
        unspecifiedValuableOption1.isSelected = false;
        options.push(unspecifiedValuableOption1);

        const unspecifiedValuableOption2 = {};
        unspecifiedValuableOption2.planName =cfg("quoteUnSpecifiedValuesPlanB.label")
        unspecifiedValuableOption2.planCode ="PB"
        unspecifiedValuableOption2.planPremium = 1104;
        unspecifiedValuableOption2.isSelected = false;
        options.push(unspecifiedValuableOption2);

        const unspecifiedValuableOption3 = {};
        unspecifiedValuableOption3.planName =cfg("quoteUnSpecifiedValuesPlanC.label")
        unspecifiedValuableOption3.planCode ="PC"
        unspecifiedValuableOption3.planPremium = 2070;
        unspecifiedValuableOption3.isSelected = false;
        options.push(unspecifiedValuableOption3);

        return options;
    }

    function getNumberOfEmployeesOption(numberOfEmployees) {
        const numberOfEmployeesOption = {};

        if (!isNaN(numberOfEmployees) && Number(numberOfEmployees) > 0) {
            numberOfEmployeesOption.numberOfEmployees = numberOfEmployees;
            numberOfEmployeesOption.planPremium = calculateOptionalPremiumByNumberOfEmployees(numberOfEmployees);
        } else {
            numberOfEmployeesOption.numberOfEmployees = 1;
            numberOfEmployeesOption.planPremium = 213;
        }

        return numberOfEmployeesOption;
    }

    function getSpecifiedValuesOption(specifiedValuesTotalSum) {
        const specifiedValuesOption = {};
        if (!isNaN(specifiedValuesTotalSum) && Number(specifiedValuesTotalSum) > 0) {
            specifiedValuesOption.totalSum = specifiedValuesTotalSum;
            specifiedValuesOption.planPremium = calculateOptionalPremiumBySpecifiedValues(specifiedValuesTotalSum);
        } else {
            specifiedValuesOption.totalSum = 0;
            specifiedValuesOption.planPremium = 0;
        }
        return specifiedValuesOption;
    }

    function getWhatsAppUrl() {
        return "https://api.whatsapp.com/send?phone=852";
    }

    function showContactAgentPopup() {
        return ContactYourAgentPopup();
    }

    function isLandlord() {
        return !!(occupancyType && String(occupancyType) === "0003");
    }

    function isTenant() {
        return !!(occupancyType && String(occupancyType) === "0004");
    }

    function isAnyOptionalCoverSelected(uiOptionalCoversList) {
        if (Array.isArray(uiOptionalCoversList) && uiOptionalCoversList.length > 0){
            if (uiOptionalCoversList.some(option => option.selectedOptionalCover === true)) {
               return true;
            }
        }
        return false;
    }

    function isDomesticOptionalCoverSelected(uiOptionalCoversList) {
        if (Array.isArray(uiOptionalCoversList) && uiOptionalCoversList.length > 0){
            if (uiOptionalCoversList.some(option => option.label === "quick_quote.optionalCovers.domestic_employers_liability")) {
                return true;
            }
        }
        return false;
    }

    function calculateOptionalCoverDiscount(policyPremium, optionalCoversList, campaignDiscountRate) {
        let totalDiscount = 0;
        if (policyPremium && Array.isArray(optionalCoversList) && optionalCoversList.length > 0) {
            totalDiscount = policyPremium.discount;
            optionalCoversList.forEach((optionalCover)=>{
                if (optionalCover.selectedOptionalCover === true) {
                    const coverValueAmount = optionalCover?.coverValueAmount;
                    totalDiscount = Number(totalDiscount) + Number(coverValueAmount) * Number(campaignDiscountRate);
                }
            });
        }
        return totalDiscount;
    }

    function calculatePremiumDueBeforeDiscount(premiumItem, optionalCoversList, isWithOptionalCover) {
        let planDueAmountBeforeDiscount = 0;
        if (premiumItem) {
            const discount = calculateTotalDiscount(premiumItem, optionalCoversList, isWithOptionalCover);
            const premiumDue = calculateTotalPremiumDue(premiumItem, optionalCoversList, isWithOptionalCover);
            planDueAmountBeforeDiscount = Number(premiumDue)+ Number(discount);
        }
        return planDueAmountBeforeDiscount;
    }

    function calculatePolicyGrossPremium(policyPremium) {
        let grossPremium = 0;
        if (policyPremium) {
            grossPremium = Number(policyPremium.premium) + Number(policyPremium.discount);
        }
        return grossPremium;
    }

    function calculateTotalDiscount(policyPremium, optionalCoversList, isWithOptionalCover) {
        let totalDiscount = 0;
        if (policyPremium) {
            if (isWithOptionalCover === true) {
                totalDiscount = calculateOptionalCoverDiscount(policyPremium, optionalCoversList, campaignDiscountRate);
            }
            let insuredCommissionDiscount = policyPremium.insuredCommissionDiscount;
            if (isWithOptionalCover === true) {
                insuredCommissionDiscount = Number(policyPremium.insuredCommissionDiscountRate) * Number(policyPremium.premium);
            }
            totalDiscount = Number(totalDiscount) + Number(insuredCommissionDiscount);
        }
        return totalDiscount;
    }

    function calculateTotalPremiumDue(policyPremium, optionalCoversList, isWithOptionalCover) {
        let totalPremiumDue = 0;
        if (policyPremium) {
            totalPremiumDue = policyPremium.totalPremium;

            if (isWithOptionalCover === true) {
                const calculatedInsuredCommissionDiscount = Number(policyPremium.insuredCommissionDiscountRate) * Number(policyPremium.premium);
                const commissionDiscount = Number(policyPremium.insuredCommissionDiscount) - Number(calculatedInsuredCommissionDiscount);
                totalPremiumDue = Number(policyPremium.totalPremium) + Number(commissionDiscount) - Number(policyPremium.levy);
                totalPremiumDue = calculateOptionalCoverTotalPremiumDue(optionalCoversList, totalPremiumDue, campaignDiscountRate);
            }
        }
        return totalPremiumDue;
    }

    function calculateOptionalCoverTotalPremiumDue(optionalCoversList, totalPremiumDue, campaignDiscountRate) {
        if (Array.isArray(optionalCoversList) && optionalCoversList.length > 0) {
            optionalCoversList.forEach((optionalCover) => {
                if (optionalCover.selectedOptionalCover === true) {
                    const coverValueAmount = optionalCover.coverValueAmount;
                    const discount = Number(coverValueAmount) * Number(campaignDiscountRate);
                    totalPremiumDue = Number(totalPremiumDue) + Number(coverValueAmount) - Number(discount);
                }
            });
        }
        return totalPremiumDue;
    }

    function getSelectedPremiumItem(premiumItems, selectedPlan) {
        let selectedPremiumItem;
        if (Array.isArray(premiumItems) && premiumItems.length > 0) {
            selectedPremiumItem = premiumItems.find((item) => item.planCode === selectedPlan);
        }
        return selectedPremiumItem;
    }

    function buildPolicyPremium(premiumItems, selectedPlan) {
        let policyPremium = {};
        if (Array.isArray(premiumItems) && premiumItems.length > 0) {
            const selectedPremiumItem  = getSelectedPremiumItem(premiumItems, selectedPlan);
            if (selectedPremiumItem) {
                policyPremium.planCode = selectedPremiumItem.planCode;
                policyPremium.planDesc = selectedPremiumItem.planName;
                policyPremium.premium = selectedPremiumItem.premium;
                policyPremium.totalPremium = selectedPremiumItem.totalPremium;
                policyPremium.discount = selectedPremiumItem.discount;
                policyPremium.levy = selectedPremiumItem.levy;
                policyPremium.insuredCommissionDiscount = selectedPremiumItem.insuredCommissionDiscount;
                policyPremium.insuredCommissionDiscountRate = selectedPremiumItem.insuredCommissionDiscountRate;
            }
        }
        return policyPremium;
    }

    function hasCampaignDiscount() {
        const campaign = props.campaign;
        const lang = props?.lang
        return !!((lang === "en" && campaign && campaign?.marketingMessage) || (lang !== "en" && campaign && campaign?.marketingMessageCn));
    }

    function selectPremiumItem(premiumItem) {
        const planList = ['SP','GP','PP'];
        const planCode = premiumItem?.planCode;
        if (selectedPlanCode !== planCode) {
            setMobileAnimation(planList.indexOf(selectedPlanCode)-planList.indexOf(planCode)<0?" slide-in-right":" slide-in-left")
            setSelectedPlanCode(planCode);
            setCurrentPremiumItem(premiumItem);
        }
    }

    function calculateOptionalPremiumByNumberOfEmployees(numberOfEmployees) {

        let planPremium = 213;
        if (!isNaN(numberOfEmployees) && Number(numberOfEmployees) > 0) {
            planPremium =  Number(numberOfEmployees) * 213;
        }
        return planPremium;

    }

    function calculateOptionalPremiumBySpecifiedValues(totalSum) {
        let planPremium = 0;
        if (!isNaN(totalSum) && Number(totalSum) > 0) {
            planPremium =  Number(totalSum) * 0.0156;
        }
        planPremium = Number(planPremium).toFixed(2);
        return planPremium;
    }


    function getTotalPremiumDueForCurrentPlan(premiumItem) {
        // const policyPremiumPlanCode = props?.policy?.policyHome?.policyPremium?.planCode;
        // if (policyPremiumPlanCode && policyPremiumPlanCode === premiumItem.planCode) {
        //     return props?.policy?.policyHome?.totalDueAmount;
        // } else {
        //     return premiumItem.totalPremium;
        // }

        if (premiumItem) {
            return premiumItem.premium;
        }
        return 0;
    }

    function getPremiumDueBeforeDiscountForCurrentPlan(premiumItem) {
        // const policyPremiumPlanCode = props?.policy?.policyHome?.policyPremium?.planCode;
        // if (policyPremiumPlanCode && policyPremiumPlanCode === premiumItem.planCode) {
        //     return props?.policy?.policyHome.totalDueAmount;
        // } else {
        //     return premiumItem.totalPremium;
        // }
        if (premiumItem) {
            return premiumItem.premiumWithoutDiscount;
        }
        return 0;
    }

    function preparePolicyData(uiOptionalCoversList) {
        const policyData = cloneDeep(props.policy);
        if (policyData.errorMessage) {
            policyData.errorMessage = null;
        }

        if (selectedPlanCode === 'GP') {
            policyData.policyHome.planType = "GP";
        }
        else if (selectedPlanCode === 'SP') {
            policyData.policyHome.planType = "SP";
        }
        else if (selectedPlanCode === 'PP') {
            policyData.policyHome.planType = "PP";
        }
        const policyPremium = buildPolicyPremium(policyData.policyHome?.planItems, selectedPlanCode);
        policyData.policyHome.policyPremium = policyPremium;

        policyData.policyHome.numberOfEmployees = null;
        policyData.policyHome.unspecifiedValuablesPlan = null;
        policyData.policyHome.totalSpecifiedValuables = null;

        policyData.policyHome.optionalCoversList = uiOptionalCoversList;

        const isWithOptionalCover = isAnyOptionalCoverSelected(uiOptionalCoversList);
        if (isWithOptionalCover) {
            policyData.policyHome.isOptionalCoverSelected = true;
            uiOptionalCoversList.forEach((optionalCover)=>{
                if (optionalCover?.selectedOptionalCover === true) {
                    if (optionalCover?.label === 'quick_quote.optionalCovers.domestic_employers_liability') {
                        policyData.policyHome.numberOfEmployees = optionalCover?.coverValue;
                    } else  if (optionalCover?.label === 'quick_quote.optionalCovers.unspecified_valuable_plan') {
                        policyData.policyHome.unspecifiedValuablesPlanDesc = unspecifiedValuableOptionsDesc;
                        policyData.policyHome.unspecifiedValuablesPlan = optionalCover?.coverValue;
                    } else  if (optionalCover?.label === 'quick_quote.optionalCovers.lbl_specified_valuable') {
                        policyData.policyHome.totalSpecifiedValuables = optionalCover?.coverValue;
                    }
                }
            });
            if (!isDomesticOptionalCoverSelected(uiOptionalCoversList)) {
                policyData.policyHome.employeeList = [];
            }
        } else {
            policyData.policyHome.employeeList = [];
            policyData.policyHome.specifiedValuablesList = [];
        }

        if (showPromotionCode) {
            if (promotionCode) {
                policyData.promo = promotionCode;
                policyData.policyHome.promotionCode = promotionCode;
            } else {
                policyData.promo = '';
                policyData.policyHome.promotionCode = '';
            }
        }

        return policyData;
    }

    function validate() {
        let valid = true;
        if( !checkboxSelected){
            valid =false
        }
        if(checkBoxDeclaration.current && checkBoxDeclaration.current?.checked !== true){
            valid = false;
        }
        if (showPromotionCode) {
            if (promotionCodeErrorMessage !== null && promotionCodeErrorMessage !== ""){
                valid = false;
            }
            if (promotionCode==="") {
                setPromotionCodeErrorMessage("landingPromotionCode.invalidPromoCode");
                valid = false;
            }
        }
        return valid;
    }

    function validateHKG() {
        let valid = true;
        if(!checkboxSelected){
            valid = false;
            setDeclarationTickError(true)
        }else{
            setDeclarationTickError(false)
        }
        if(checkBoxDeclaration.current && checkBoxDeclaration.current?.checked !== true){
            valid = false;
        }

        if (showPromotionCode) {
            console.log("promotionCode====="+promotionCode);
            if (promotionCodeErrorMessage !== null && promotionCodeErrorMessage !== ""){
                valid = false;
            }
            if (promotionCode==="") {
                setPromotionCodeErrorMessage("landingPromotionCode.invalidPromoCode");
                valid = false;
            }
        }
        return valid;
    }

    function getNextPageLocation(){
        let nextPageLocation = "/applicant";
        if (!isLandlord()) {
            const isWithOptionalCover = isDomesticOptionalCoverSelected(uiOptionalCoversList);
            if (isWithOptionalCover) {
                nextPageLocation = "/apply";
            }
        }
        return nextPageLocation;
    }

    function callRateTopUp(policyData) {
        if (policyData) {
            props.storePolicy(policyData);

            props.showSpinner();
            //props.singleRatePolicy().then ( resp => {
            props.ratePolicy().then ( resp => {
                console.log(resp)
                if(resp.errorMessage){
                    console.error(resp.errorMessage);
                }
                props.hideSpinner();
            }, (error) => {
                console.error(error?.response?.data?.message);
                props.hideSpinner();
            })
        }
    }

    function goNextPage() {
        if(validateHKG()) {
            savePolicyAndCallTopRateTopUp();
        }
    }

    async function handlePromotionCode(promo){
        await validatePromotionCode(promo);
        setPromotionCode(promo);
    }


    async function validatePromotionCode(promo) {
        let policyCampaign = props.campaign?.campaignCode;
        let campaignId = props.campaign?.campaignId;
        let campaign = props.campaign;
        if (policyCampaign && promo || campaign && campaign?.promoCodeRequired === "Y") {
            const response = await props.validateCampaignPromo(policyCampaign, promo, campaignId);
            console.log("response!!!"+response.result);
            if (response.result === 'Y') {
                setPromotionCodeErrorMessage(null);
                return true;
            } else {
                setPromotionCodeErrorMessage("landingPromotionCode.invalidPromoCode");
                return false;
            }
        } else {
            setPromotionCodeErrorMessage(null);
            return true;
        }
    }

    function savePolicyAndCallTopRateTopUp () {
        const policyData = preparePolicyData(uiOptionalCoversList);
        if (policyData) {
            const nextPageLocation = getNextPageLocation();
            props.storePolicy(policyData);

            props.showSpinner();
            props.singleRatePolicy().then ( resp => {
                console.log(resp)
                if(resp.errorMessage){
                    console.error(resp.errorMessage);
                } else {
                    props.history.push(nextPageLocation);
                }
                props.hideSpinner();
            }, (error) => {
                console.error(error?.response?.data?.message);
                props.hideSpinner();
            })
        }
    }

    function backToPreviousPage() {
        const url = props.initialURL;
        if(url){
            props.history.push('/');
            props.history.push(url);
        }else {
            props.history.push('/');
        }
    }

    const ContactYourAgentPopup = () => {
        props.showModal(
            {
                title: cfg('popupAgentTitle.label'),
                className: 'ContactYourAgentContainer normal',
                content:
                    <div className="ContactYourAgent">
                        <div className="Content">
                            {cfg('popupAgentContent.label')}
                        </div>
                        <div className="ContactMethods">
                            <a className="ContactMethod Phone" href={"tel:"+props.agent?.tel} onClick={()=>{
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactphone',
                                    }
                                });
                            }}>
                                <img src={Phone} className="PhoneImg" alt=""/>
                                <span>{props.agent?.tel}</span>
                            </a>
                            <div className="VLine"></div>
                            <a className="ContactMethod Email" href={"mailto:" + props.agent?.email} onClick={()=>{
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactemail',
                                    }
                                });
                            }}>
                                <Email className="EmailImg"  alt=""/>
                                <div>{cfg('popupEmail.label')}</div>
                            </a>
                        </div>
                    </div>,
            });
    }

    const IncreaseLimitPopup = () => {
        props.showModal(
            {
                title: cfg('popupIncreaseLimitTitle.label'),
                className: 'ContactYourAgentContainer normal',
                content:
                    <div className="ContactYourAgent">
                        <div className="ContentImage">
                            <img src={SadIcon} className="PhoneImg" alt=""/>
                        </div>
                        <div className="Content CenterContent SubTitle">
                            {cfg('popupIncreaseLimitSubTitle.label')}
                        </div>
                        <div className="Content CenterContent">
                            {cfg('popupIncreaseLimitContent.label')}
                        </div>
                        <div className="ContactMethods">
                            { props.agent?.tel &&
                            <React.Fragment>
                                <a className="ContactMethod Phone" href={"tel:"+props.agent?.tel} onClick={()=>{
                                    TagManager.dataLayer({
                                        dataLayer: {
                                            'event': 'contactphone',
                                        }
                                    });
                                }}>
                                    <img src={Phone} className="PhoneImg" alt=""/>
                                    <span>{props.agent?.tel}</span>
                                </a>
                                <div className="VLine"></div>
                            </React.Fragment>
                            }
                            <a className="ContactMethod WhatsApp" href={getWhatsAppUrl() + (props.agent?.tel || "")} target="_blank" onClick={()=>{
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactwhatsapp',
                                    }
                                });
                            }}>
                                <img src={WhatsApp} className="WhatsAppImg"/>
                                <span>WhatsApp</span>
                            </a>
                            <div className="VLine"></div>
                            <a className="ContactMethod Email" href={"mailto:" + props.agent?.email} onClick={()=>{
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'contactemail',
                                    }
                                });
                            }}>
                                <Email className="EmailImg"  alt=""/>
                                <div>{cfg('popupEmail.label')}</div>
                            </a>
                        </div>
                    </div>,
            });
    }


    const MobileCampaignDiscount = () => {
        const marketingMessage =  (props?.lang && props?.lang !== "en") ? props?.campaign?.marketingMessageCn: props?.campaign?.marketingMessage;
        if (hasCampaignDiscount()) {
            return (
                <React.Fragment>
                    <div>
                        <WhiteCoupon className={"QuoteWhiteCoupon"}/>
                        <div className="Discount">{marketingMessage}</div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    const CampaignDiscount = () => {
        const marketingMessage =  (props?.lang && props?.lang !== "en") ? props?.campaign?.marketingMessageCn: props?.campaign?.marketingMessage;
        if (hasCampaignDiscount()) {
            return (
                <React.Fragment>
                    <div>
                        <img src={PromoIcon} className="PromoIcon" alt=""/>
                        <div className="Discount">{marketingMessage}</div>
                    </div>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    const DiscountHLine = () => {
        if (hasCampaignDiscount()) {
            return (
                <React.Fragment>
                    <div className="HLine"></div>
                </React.Fragment>
            )
        } else {
            return null;
        }
    }

    const PlanRows = (p) => {
        const selectedPlanCode = p.selectedPlanCode;
        const premiumItems = p.premiumItems;

        function getPlanNameDesc(planName) {
            if (planName) {
                if (String(planName).toLocaleUpperCase() === "GOLD") {
                   return cfg("benefitDescriptionGP.label");
                } else if (String(planName).toLocaleUpperCase() === "SILVER") {
                    return cfg("benefitDescriptionSP.label");
                } else if (String(planName).toLocaleUpperCase() === "PLATINUM") {
                    return cfg("benefitDescriptionPP.label");
                }
            }
            return "";
        }

        if (Array.isArray(premiumItems) && premiumItems.length > 0) {
            return premiumItems.map((premiumItem, index) => {
                const totalDueAmount = getTotalPremiumDueForCurrentPlan(premiumItem);
                const dueAmountBeforeDiscount = getPremiumDueBeforeDiscountForCurrentPlan(premiumItem);
                let planName = premiumItem?.planName;
                console.log('gpnd',planName)

                return (
                      <React.Fragment key={index}>
                            <div
                                className={"Plan HKG" + (selectedPlanCode === premiumItem.planCode ? " Picked" : "")}
                                onClick={(e) => {
                                    if (premiumItem.planCode && premiumItem.planCode.length > 1) {
                                        const planName = 'Select-Plan-' + String(premiumItem.planCode);
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                'event': planName,
                                            }
                                        });
                                    }
                                    if(window.innerWidth<=1024){
                                        e.target.scrollIntoView({behavior: 'smooth', block:'nearest',inline: index!=premiumItems.length-1?'center':'nearest'})
                                    }
                                    return selectPremiumItem(premiumItem);
                                }} key={index}>

                                   <div className="PlanName">{getPlanNameDesc(planName)}</div>
                                   <div className="AnnualPremium">{cfg("QuotePlanAnnualPremium.label")}</div>

                                {dueAmountBeforeDiscount > totalDueAmount
                                    ? (<React.Fragment>
                                        <div className="Discount">
                                            {"VND"}<span className="Price">{dueAmountBeforeDiscount?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
                                        </div>
                                        <div className="PriceGroup">
                                            {"VND"}<span className="Price">{totalDueAmount?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
                                        </div>
                                    </React.Fragment>)
                                    :
                                    (<React.Fragment>
                                        <div className="PriceGroup">
                                            {"VND"}<span className="Price">{totalDueAmount?.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
                                        </div>
                                    </React.Fragment>)
                                }
                            </div>
                        </React.Fragment>
                );
            });
        } else {
            return null;
        }
    }

    const mapBr = (str)=>str.split('\n').map((item, i) => {
        return <span key={i}>{item}<br/></span>;
    });
    const mapLiBr = (str)=>str.split('\n').map((item, i) => {
        return <li  key={i} ><span key={i}>{item}<br/></span></li>;
    });


    const BenefitRow = (p) => {
        const benefitDesc = p.desc || cfg(p.descLabel);
        return (
            <div className={"PlanCover " + p.class}>
                <div className={"Title"}>
                    <div className="divList">
                       {mapBr(benefitDesc)}
                    </div>
                </div>
                <div className={"Value"+ (selectedPlanCode==="PS"?" Picked "+mobileAnimation:"")}>{p.planInsuredAmount}</div>
                <div className={"Arrow"}></div>
            </div>
        );
    }

    const HKSIBenefitRow = (p) => {
        const benefitDesc = p.desc || cfg(p.descLabel);
        return (
            <div className={"PlanCover HKG " + p.class}>
                <div className={"Title"}>
                    <div className="divList">
                        {mapBr(benefitDesc)}
                    </div>
                </div>
                <div className={"Value" + (selectedPlanCode === "PM"? " Picked " + mobileAnimation : "")}>{p.plan1InsuredAmount}</div>
                <div className={"Value" + (selectedPlanCode === "PS"? " Picked " + mobileAnimation : "")}>{p.plan2InsuredAmount}</div>
                <div className={"Arrow"}></div>
            </div>
        );
    }
    const getOccupancy = (type) => {
        switch (type) {
            case '0004':
                return 'occupier'
            case '0003':
                return 'landlord'
            default:
                return 'occupier'
        }
    }
    const getHousingType = (type) => {
        switch (type) {
            case 'PU':
                return 'apartment'
            case 'PR':
                return 'privateHouse'
            default:
                return 'apartment'
        }
    }
    const getPickedPlan = (plan) =>{
        switch (plan) {
            case 'SP':
                return 'silver'
            case 'GP':
                return 'gold'
            case 'PP':
                return 'platinum'
            default:
                return 'apartment'
        }
    }
    const ProductSubFeatureRow = ({productSubFeature,index,color})=>{
        let {label} = productSubFeature
        let sub = productSubFeature[getHousingType(housingType)];
        let productHighlightTitle = cfg(label);
        let silver = sub.silver.endsWith('.label')?cfg(sub.silver):sub.silver
        let gold = sub.gold.endsWith('.label')?cfg(sub.gold):sub.gold
        let platinum = sub.platinum.endsWith('.label')?cfg(sub.platinum):sub.platinum
        let mobileP = sub[getPickedPlan(selectedPlanCode)]
        mobileP = mobileP.endsWith('.label')?cfg(mobileP):mobileP

        const getRemarks = () => {
            console.log(productSubFeature.label);
            if(index === 3 && productSubFeature.label.startsWith("HOME_CONTENT__")){
                return <b>{cfg("OccupierAdditionalBenefits.label")}</b>;
            } else if(index === 5 && productSubFeature.label.startsWith("BUILDINGS__")){
                return <b>{cfg("LandlordAdditionalBenefits.label")}</b>;
            } else {
                return <li>{mapBr(productHighlightTitle)}</li>;
            }
        };

        return (
            <>
                <div key={index} className={"PlanCover HKG "+ color}>
                    <div className={"Title title-group"}>
                        <div className={"Content"}>
                            {getRemarks()}
                        </div>
                    </div>
                    <div className={"Value" + (selectedPlanCode === "SP"? " Picked " + mobileAnimation : "")}>{silver}</div>
                    <div className={"Value" + (selectedPlanCode === "GP"? " Picked " + mobileAnimation : "")}>{gold}</div>
                    <div className={"Value" + (selectedPlanCode === "PP"? " Picked " + mobileAnimation : "")}>{platinum}</div>
                    <div className={"Arrow"}></div>
                </div>
            </>
        )
    }

    const getBalloonTip =(label)=>{
        switch (label) {
            case 'HOME_CONTENT.label':
                return <QBEBalloonTips>{mapBr(cfg('LandingProductFeatureHomeContentsBalloonTip.label'))}</QBEBalloonTips>
            case 'BUILDINGS.label':
                return <QBEBalloonTips>{mapBr(cfg('LandingProductFeatureBuildingsBalloonTip.label'))}</QBEBalloonTips>
            case 'LIABILITY_TO_OTHERS.label':
                return <QBEBalloonTips>{cfg('LandingProductFeaturePersonalAccidentBalloonTip.label')}</QBEBalloonTips>
            default:
                return <></>
        }
    }

    const ProductFeatureRow = ({productFeature,index}) => {
        let {subItems,plan,icon,item,toggle} = productFeature
        let [toggleState, setToggleState] = useState(toggle)
        let pSilver = plan.silver.endsWith('.label')?cfg( plan.silver): plan.silver
        let pGold = plan.gold.endsWith('.label')?cfg( plan.gold): plan.gold
        let pPlatinum = plan.platinum.endsWith('.label')?cfg( plan.platinum): plan.platinum
        let color = (index%2===0?'Odd':'Even')
        return (
            <>
                <div className="PlanCoverGroup">
                    <div className={"PlanCover HKG "+ color}>
                        <div className="Title title-group">
                            {icon}
                            <div className={"Title Bold ToolTip"}>
                                {cfg(item.label)}
                                {getBalloonTip(productFeature.item.label)}
                            </div>
                        </div>
                        <div className={"Value" + (selectedPlanCode === "SP" ? " Picked" + mobileAnimation : "")}>{pSilver}</div>
                        <div className={"Value" + (selectedPlanCode === "GP" ? " Picked" + mobileAnimation : "")}>{pGold}</div>
                        <div className={"Value" + (selectedPlanCode === "PP" ? " Picked" + mobileAnimation : "")}>{pPlatinum}</div>
                        <div className={"Arrow"} onClick={()=> {
                            setToggleState(!toggleState)
                            productFeature.toggle = !toggleState
                        }}>
                            <img src={NavArrow} className={!toggleState ? "Up" : ""} alt=""/></div>
                    </div>
                    {
                        (!toggleState && subItems.map((subItem,ind) => <ProductSubFeatureRow  key={uuidv4()}  productSubFeature={subItem} index={ind} color={color}/>))
                    }
                </div>
            </>
        )
    }

    const formatPdfJSX = (word) => {
        const regExp = new RegExp(`(${cfg('landingInsuranceProductFeatureTitle.label')}|${cfg('productHighlightRemarkPolicyWording.label')})`);
        const parts = word.split(regExp);

        for (let i = 1; i < parts.length; i+=2) {
            parts[i] = getPdfJSX(parts[i]);
        }
        return parts;
    }

    const getPdfJSX = (item) => {
        return (<span className="link" onClick={()=>{
                    TagManager.dataLayer({
                        dataLayer: {
                            'event': item === cfg('landingInsuranceProductFeatureTitle.label') ? 'product-brochure' : 'policy-wording',
                        }
                    });
                    const type = item === cfg('landingInsuranceProductFeatureTitle.label') ? "Brochure" : "PolicyWording";
                    return getAssetTypeDocDownload(type, props.lang);
                }}>
            {item}
        </span>);
    }

    const AgentRemark = (p) => {
        return (
            <React.Fragment>
                <div className="RemarkBody">
                    <Toggle heading={cfg("quoteImportantNotesTitle.label")} isExpanded={true} onClick={()=>{
                        let eventNameNotes = 'expand-important-notes';
                        if (dispImportantNotes) {
                            eventNameNotes = 'collapse-important-notes';
                        }
                        TagManager.dataLayer({
                            dataLayer: {
                                'event': eventNameNotes,
                            }
                        });
                        if (dispImportantNotes) {
                            setDispImportantNotes(!dispImportantNotes);
                        } else {
                            setDispImportantNotes(dispImportantNotes);
                        }
                    }}>
                        <div className="QuoteRemarks">
                            <ul>
                                <li>
                                    {cfg("quoteImportantNotesWording1.label")}
                                    <span
                                        className="link"
                                        onClick={(event)=>{
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'Download-Product-brochure',
                                                }
                                            });
                                            event.stopPropagation();
                                            return getAssetTypeDocDownload("Brochure", props.lang);
                                        }}>
                                                {cfg('productHighlightRemarkProductBrochure2.label')}
                                            </span>
                                    {cfg("quoteImportantNotesWording2.label")}
                                    <span
                                        className="link"
                                        onClick={(event)=>{
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    'event': 'policywording',
                                                }
                                            });
                                            event.stopPropagation();
                                            getAssetTypeDocOpen("PolicyWording", props.lang);
                                        }}>
                                                {cfg('productHighlightRemarkPolicyWording.label')}
                                            </span>
                                    {cfg("quoteImportantNotesWording3.label")}
                                </li>
                                {cfg("quoteImportantNotesWording4.label").split('\n').map((word,index)=>{
                                    return (<li key={index}>
                                        {word}
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </Toggle>
                </div>
            </React.Fragment>
        );
    }

    const RiskStatementDeclaimer = (p) => {
        return (
            <>
                <div className="Item">
                    <div onClick={()=>{
                        setDeclarationTickError(checkboxSelected)
                        setCheckBoxSelected(!checkboxSelected)
                    }} className={`checkmark1 purple ${checkboxSelected ? 'checked': ''}`}></div>
                    <div  className="Wording">
                        <span>{cfg('quoteDeclaration.label')}</span>
                        {
                            declarationTickError &&
                            <span>
                                <span className={"Error ErrorFormat"}>
                                    {cfg('quoteDeclarationError.label')}
                                </span>
                            </span>
                        }
                    </div>
                </div>
            </>
        );
    }

    const Remark = (p) => {
        return (
            <>
                <div className="PaymentDeclarationGroups PaymentDeclartionGroupOverride">
                    <RiskStatementDeclaimer/>
                    <div className="Declarations">
                        <ul>
                            {
                                mapLiBr(cfg('QuoteRiskDeclaration.label'))
                            }
                        </ul>
                    </div>
                </div>
            </>
        );
    }

    const ActionPanel = (p) => {
        return (
            <>
                <div className="BottomActionAndControl">
                    <div>
                        <div className="ContactAgentRemark"/>
                    </div>
                    <div className={"QuoteApplyButton"}>
                        <div className="ProgressControl">
                            <QnectButton color={``} className={`mobileBack`} onClick={()=> {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'back',
                                    }
                                });
                                return backToPreviousPage();
                            }}>
                                {cfg('QuoteBackButton.label')}
                            </QnectButton>
                            <div className="space"></div>
                            <QnectButton disabled={false} className={``} onClick={() => {
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': 'apply-now',
                                    }
                                });
                                return goNextPage();
                            }}>
                                {cfg('QuoteApplyButton.label')}
                            </QnectButton>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Template title={"Personal Accident Protection Plus"} quote step={1} info={1} mobileBack={backToPreviousPage} mobileSpace>
            <div className="Quote QuoteTemplateBodyContainer QuoteTablePoint">
                <div className={"QuoteWrapper"}>
                    {
                        hasCampaignDiscount()?
                            <div className={"QuoteMobileDiscount"}>
                                <div className={"Title"}>
                                    Discount and Promotion Details
                                    <img src={WhiteArrow} className={mobileDiscountToggle?"Open":""} onClick={()=>setMobileDiscountToggle(!mobileDiscountToggle)} alt=""/>
                                </div>
                                {
                                    mobileDiscountToggle &&
                                    <div className={"Content"}>
                                        <MobileCampaignDiscount/>
                                    </div>
                                }
                            </div>
                            :
                            null
                    }
                </div>
                <div className={"TableHeaderGroup"}>
                    <div className="QuoteTableHeader">
                        <div className="TopLeft">
                            <CampaignDiscount/>
                        </div>

                        <PlanRows selectedPlanCode={selectedPlanCode} premiumItems={premiumItems}/>

                        <div className={"Arrow HKG"}></div>
                    </div>
                    <div className="TableHeading">
                        <div className="Item">{cfg("benefitsTitle.label")}</div>
                        <div className="Value">{cfg("benefitLimit.label")}</div>
                        <div className="Arrow THMobileHide">

                        </div>
                    </div>
                </div>
                <div className="QuoteWrapper">
                    <div className={"TableBody"} ref={TableBody}>

                        {
                            ProductFeatures[getHousingType(housingType)]
                                .map((x,i)=>{
                                    return (
                                        <ProductFeatureRow  key={i}  index={i} productFeature={x}/>
                                    )
                                })
                        }

                        <div className="BottomRemarkAndControl">
                            <div>
                                <div className="ContactAgentRemark">
                                    {cfg('QuoteContactAgentRemark1.label')}<span className="Link" onClick={()=>{
                                    return showContactAgentPopup();
                                }}>
                                    {cfg('QuoteContactAgentRemark2.label')}</span>{cfg('QuoteContactAgentRemark3.label')}</div>
                            </div>
                        </div>
                        { showPromotionCode &&
                        <div className="promoteCodeContainer">
                            <div className="label">
                                Do you have a promotion code?
                            </div>
                            <div className="pCodeInput">
                                <QnectTextField
                                    name="promotionCode"
                                    field="promotionCode"
                                    formId={formId}
                                    value={promotionCode}
                                    maxLength={10}
                                    errorMessage={promotionCodeErrorMessage}
                                    onBlur={(event=>handlePromotionCode(event.target.value))}
                                    placeholder={cfg('textPlaceholder.label')}/>
                            </div>
                        </div>}
                        <div className="RemarkBody">
                            <Toggle heading={cfg("quoteImportantNotesTitle.label")} isExpanded={true} onClick={()=>{
                                let eventNameNotes = 'expand-important-notes';
                                if (dispImportantNotes) {
                                    eventNameNotes = 'collapse-important-notes';
                                }
                                TagManager.dataLayer({
                                    dataLayer: {
                                        'event': eventNameNotes,
                                    }
                                });
                                setDispImportantNotes(!dispImportantNotes);
                            }}>
                                <div className="QuoteRemarks">
                                    <ul>
                                        <li>
                                            {cfg("quoteImportantNotesWording1.label")}
                                            <span
                                                className="link"
                                                onClick={(event)=>{
                                                    TagManager.dataLayer({
                                                        dataLayer: {
                                                            'event': 'Download-Product-brochure',
                                                        }
                                                    });
                                                    event.stopPropagation();
                                                    return getAssetTypeDocDownload("Brochure", props.lang);
                                                }}>
                                                {cfg('productHighlightRemarkProductBrochure2.label')}
                                            </span>
                                            {cfg("quoteImportantNotesWording2.label")}
                                            <span
                                                className="link"
                                                onClick={(event)=>{
                                                    TagManager.dataLayer({
                                                        dataLayer: {
                                                            'event': 'policywording',
                                                        }
                                                    });
                                                    event.stopPropagation();
                                                    getAssetTypeDocOpen("PolicyWording", props.lang);
                                                }}>
                                                {cfg('productHighlightRemarkPolicyWording.label')}
                                            </span>
                                            {cfg("quoteImportantNotesWording3.label")}
                                        </li>
                                        <li>
                                            {cfg("QuoteImportantNotesWording5.label")}
                                        </li>
                                    </ul>
                                </div>
                            </Toggle>
                        </div>
                        <Remark/>
                        <ActionPanel/>
                    </div>
                </div>
            </div>
        </Template>
    );
}

const mapStateToProps = (state) => {
    return {
        initialURL: _getInitialURL(state),
        lang: _getLang(state),
        agent: _getAgent(state),
        policy: _getPolicy(state),
        campaign: _getCampaign(state),
        country: _getCountry(state)
    }
}

const mapDispatchToProps = {
    showSpinner: _showSpinner,
    hideSpinner: _hideSpinner,
    storePolicy: _storePolicy,
    singleRatePolicy: _singleRatePolicy,
    ratePolicy: _ratePolicy,
    showError: _showError,
    showModal: _showModal,
    hideModal: _hideModal,
    validateCampaignPromo:_validateCampaignPromo,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quote));


